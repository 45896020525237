<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                <br>
            </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Profil</h4>
                        </div>
                        <div class="col-sm-6">
                                <ol class="breadcrumb float-right">
                                        <li class="breadcrumb-item">INFOCAD</li>
                                        <li class="breadcrumb-item active">Profil</li>
                                </ol>
                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="page-title"></h1>
                    </div>
                    <div class="col-md-6">



                    </div>
                </div>
                <div class="row">

                    <div class="col-md-6 mx-auto">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <form #updateForm="ngForm" (ngSubmit)="update(updateForm.value)">
                                    <div class="form-group">
                                        <label for="">Nom</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.surname" name="surname">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Prenom</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.forename" name="forename">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Email</label>
                                        <input type="email" name="email" [(ngModel)]="data.email" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Contact</label>
                                        <input type="number" name="telephone" [(ngModel)]="data.telephone" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <label for="">Organisation</label>
                                        <input type="text" readonly name="org" [(ngModel)]="org_name" class="form-control">
                                    </div>


                                    <div class="form-group">
                                        <label for="">Nouveau mot de passe</label>
                                        <input type="password" name="password" ngModel class="form-control" id="pswd"><span toggle="#pswd" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                                    </div>
                                    <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid"
                                        type="submit">Sauvegarder</button>

                                </form>

                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->

        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>


    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->