<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                <br>
            </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Les utilisateurs à valider</h4>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                                <ol class="breadcrumb float-right">
                                        <li class="breadcrumb-item">INFOCAD</li>
                                        <li class="breadcrumb-item active"><a routerLink="/my-users-manage">Les utilisateurs</a></li>
                                        <li class="breadcrumb-item active">Les utilisateurs à valider</li>
                                </ol>

                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="page-title"></h1>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                      <button (click) = "apiGetUserNotValidatedTreatmentRefresh()" class="btn btn-secondary mx-1"> Rafraichir données</button>
                      <a routerLink="/teams" class="btn btn-primary mx-1"> Les equipes </a>
                      <a routerLink="/my-users-manage" class="btn btn-primary mx-1"> Tout les utilisateurs </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                        <form>
                            <div class="form-group form-inline mt-2">
                                <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                                    [(ngModel)]="searchText" name="searchText" (keyup)="search()" />
                            </div>
                        </form>
                    </div>
                    <div class="col-md-12">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>
                                            <!--
                                                            <th><input type="checkbox" class="mx-1" [(ngModel)]="check_all" (change)="selectAll($event)" name="all" ></th>
                                                        -->
                                            <th>Nom & prenoms</th>
                                            <th>Equipe</th>
                                            <th>Profil</th>
                                            <th class="hidden-xs">Organisation</th>
                                            <th class="hidden-xs">Contact</th>
                                            <th class="hidden-xs">Email</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index" class="{{ el.new === -1 ? 'bg-success' : '' }}">
                                            <!--
                                                        <td>
                                                            <input type="checkbox" [(ngModel)]="el.check"  (change)="selectSingle($event,el)" [name]="el.id" >
                                                        </td>
                                                        -->
                                            <td>
                                                {{el.forename}} {{el.surname}}
                                            </td>

                                            <td>

                                                {{ el.team==Null ? '' : el.team}}

                                            </td>
                                            <td>

                                                {{ el.role}}
                                            </td>
                                            <td class="hidden-xs">
                                                {{ el.organisation==Null ? '' : el.organisation.designation}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{el.telephone}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{el.email}}
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button *ngIf="el.team == NULL" (click)="openShowModal(contentU,el)" ngbDropdownItem>Activer</button>
                                                        <button (click)="makeAnalyse(el.id)" ngbDropdownItem>Analyse</button>
                                                        <button *ngIf="el.team != NULL" (click)="openShowTeamModal(details,el.team)"
                                                            ngbDropdownItem>Configurer équipe</button>
                                                    </div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->
            <ng-template #contentU let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Validation de compte</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <i class="fa fa-remove font-size-h3"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <table class="table  table-striped ">
                        <thead>
                            <tr>
                                <th>Equipe</th>
                                <th>Membres</th>
                                <th>Localités</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Nouvelle equipe
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                    <button class="btn btn-outline-primary" (click)="validateWithNewTeam()">Associer à
                                        l'utilisateur</button>
                                </td>
                            </tr>
                            <tr *ngFor="let t of teams ; let i=index">
                                <td>
                                    {{t.cote}}
                                </td>
                                <td class="hidden-xs">
                                    <small *ngFor="let m of t.members; let ix=index">
                                        {{m.forename}} {{m.surname}}
                                        {{ ix==( t.members.length-1) ? '' :','}}

                                    </small>
                                </td>
                                <td class="hidden-xs">

                                    <small *ngFor="let m of t.localities; let ix=index">
                                        {{m.libelle}}
                                        {{ ix==(t.localities.length-1) ? '' :','}}

                                    </small>
                                </td>
                                <td>
                                    <button class="btn btn-outline-primary" (click)="validate(t)">Associer à
                                        l'utilisateur</button>
                                </td>

                            </tr>
                        </tbody>

                    </table>

                    <!--
                                <div class="form-group">
                                    <label for="">Equipe</label>
                                    <select name="team" ngModel id="" (change)="loadMembers($event)" required class="form-control">
                                        <option value="null">Nouvelle Equipe</option>
                                        <option [value]="t.id" *ngFor="let t of teams">

                                            {{t.cote}}

                                        </option>
                                    </select>
                                    <small class="mt-2" *ngIf="data_current_members.length!=0"> <strong>Membres</strong> :
                                        <small *ngFor="let m of data_current_members; let ix=index">
                                            {{m.forename}} {{m.surname}}
                                            {{ ix==(data_current_members.length-1) ? '' :','}}

                                        </small></small>
                                </div>
                        -->


                </div>
                <div class="modal-footer">
                </div>
            </ng-template>
            <ng-template #locality let-modal>
                <form #updateWithCheckForm="ngForm" (ngSubmit)="setLocalityWithCheck(updateWithCheckForm.value)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Assigner des localités</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <h3>X</h3>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row col-md-12 d-flex justify-content-end">
                            <button type="button" class="btn btn-outline-dark" type="submit">Sauvegarder</button>
                        </div><br><br>

                        <div class="row col-md-12 d-flex justify-content-between">
                            <p>Equipe n°: {{ data_team_current.cote }}
                                    (
                                        <small *ngFor="let m of data_team_current.members; let ix=index">
                                            {{m.forename}} {{m.surname}}
                                            {{ ix==(data_team_current.members.length-1) ? '' :','}}

                                        </small>
                                    )
                            </p>
                            <div class="form-group form-inline">
                                <input class="form-control form-control-sm " placeholder="Rechercher par localité" type="text"
                                    [(ngModel)]="searchText" name="searchText" (keyup)="search2()" />
                            </div>
                        </div>
                        <div class="row my-2">
                               <div class="col-md-3">
                                 <label for="">Département</label>
                                <select  (change)="loadCommunues($event)" name="departement" ngModel class="form-control" id="">
                                    <option [value]="el.id" *ngFor="let el of departements">{{el.libelle}}</option>
                                 </select>
                               </div>
                                <div class="col-md-3">
                                    <label for="">Commune</label>
                                    <select  (change)="loadArrondissement($event)" name="commune" ngModel class="form-control" id="">
                                        <option [value]="el.id" *ngFor="let el of communes">{{el.libelle}}</option>
                                    </select>
                                </div>
                                <div class="col-md-3">
                                    <label for="">Arrondissement</label>
                                    <select  (change)="loadLocality($event)" name="arrondissement" ngModel class="form-control" id="">
                                        <option [value]="el.id" *ngFor="let el of arrondissements">{{el.libelle}}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 d-flex justify-content-center">
                                        <button class="btn btn-sm btn-primary my-auto" (click)="reset()">Reinitialiser</button>
                                </div>
                        </div>
                        <p>

                            <span *ngIf="selected_locs.length!=Zero">Localités selectionner :
                                <small class="badge badge-dark mx-1" *ngFor="let l of selected_locs; let ix=index">
                                    {{l.libelle}}
                                </small></span>
                        </p>
                        <table class="table  table-striped ">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Localité</th>
                                    <th>Infos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let el of localities | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; let i=index">
                                    <td>
                                        <input type="checkbox" (change)="onCheck($event)" [(ngModel)]="el.check" [name]="el.id">
                                    </td>
                                    <td>
                                        {{el.libelle}}
                                    </td>
                                    <td class="hidden-xs">
                                        {{el.libelleUnique}}
                                    </td>


                                </tr>
                            </tbody>

                        </table>
                        <ngb-pagination class="" [collectionSize]="collectionSize2" [(page)]="page2" [maxSize]="5"
                            [pageSize]="pageSize2" [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
                        </ngb-pagination>
                    </div>
                    <div class="modal-footer">
                    </div>
                </form>
            </ng-template>
            <ng-template #localitOld let-modal>
                <form #updateForm="ngForm" (ngSubmit)="setLocality(updateForm.value)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Assigner des localités</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <h3>X</h3>
                        </button>
                    </div>
                    <div class="modal-body">

                        <p>Equipe n°: {{ data_team_current.cote }}</p>
                        <div class="form-group">
                            <label for="">Localités</label>
                            <ng-multiselect-dropdown [placeholder]="'Choisir localité'" [settings]="dropdownSettings"
                                [data]="localities" name="locality" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
                            </ng-multiselect-dropdown>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid" type="submit">Sauvegarder</button>
                    </div>
                </form>
            </ng-template>
            <ng-template #group let-modal>
                <form #groupForm="ngForm" (ngSubmit)="validateGroup(groupForm.value)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Validation de compte</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <h3>X</h3>
                        </button>
                    </div>
                    <div class="modal-body">


                        <div class="form-group">
                            <label for="">Equipe</label>
                            <select name="team" ngModel id="" required class="form-control">
                                <option value="null">Nouvelle Equipe</option>
                                <option [value]="t.id" *ngFor="let t of teams">
                                    {{t.cote}}

                                </option>
                            </select>
                            <span>Membres :
                                <small *ngFor="let m of members; let ix=index">
                                    {{m.forename}} {{m.surname}}
                                    {{ ix==(el.members.length-1) ? '' :','}}

                                </small></span>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" [disabled]="!groupForm.form.valid" type="submit">Sauvegarder</button>
                    </div>
                </form>
            </ng-template>
            <ng-template #details let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Membres</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <i class="fa fa-remove font-size-h3"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="d-flex justify-content-between">Equipe n°: {{ data_team_current.cote }}

                        <button class="btn btn-primary" (click)="openLocalityModal(locality,data_team_current)">Ajouter
                            localité</button>
                    </p>


                    <table class="table  table-striped ">
                        <thead>
                            <tr>
                                <th>Nom & prenoms</th>
                                <th>Profil</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let el of data_current_members ; let i=index">
                                <td>
                                    {{el.forename}} {{el.surname}}
                                </td>
                                <td class="hidden-xs">
                                    <span class="label label-success"> {{ el.role==null ? '' :
                                        roleService.getRole(el.role)}}</span>
                                </td>


                            </tr>
                        </tbody>

                    </table>

                </div>
                <div class="modal-footer">
                </div>
            </ng-template>
        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>

    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->
