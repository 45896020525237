import { Component, OnInit, Input } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';

import { Observable, identity } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { Config } from './../app.config';
import { AlertNotif } from './../alert';
import { User } from './../core/_models/user.model';
import { UserService } from './../core/_services/user.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from '../core/_models/organization.model';
import { OrganizationService } from '../core/_services/organization.service';
import { RoleService } from '../core/_services/role.service';
import { Roles } from '../core/_models/roles';
import { TeamService } from '../core/_services/team.service';
import { Team } from '../core/_models/team.model';
import { LocalService } from '../core/_services/browser-storages/local.service';

declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  page = 1;
  pageSize = 25;
  searchText = ""
  closeResult = '';
  error = ""
  data: User[] = [];
  _temp: User[] = [];
  data_current = new User()
  organisations: any[] = []
  teams: any[] = []
  roles: any[] = []
  selected = [
  ];
  userData : any;
  collectionSize = 0;
  org_role = "7ee19b67-5cac-4909-a867-9f46fd689d65"
  data_manager_role = "aa62d163-4805-4735-8911-eeefbf6e7de6"
  super_admin_role = "9b0d2927-b9e6-449f-bf99-ef0aadf753ba"
  superviseur_role = "e68f4e97-9d2a-40a8-baba-e0150c59f65c"
  andf_id = "7fc0102c-ecfd-44b5-825a-24049023e323"
  role: any
  Admin = "ADMIN"
  Responsable = "RESPONSABLE"
  INVITE = "INVITE"
  GESTIONNAIREDEDATA = "GESTIONNAIREDEDATA"
  ENQUETEURTOPO = "ENQUETEURTOPO"
  selected_role = ""

  search() {
    this.data = this._temp.filter((r:any) => {
      const term = this.searchText.toLowerCase();
      return r.forename.toLowerCase().includes(term) ||
        r.surname.toString().toLowerCase().includes(term) ||
        r.email.toString().toLowerCase().includes(term)
        ||
        r.role.toString().toLowerCase().includes(term)||

        r.telephone.toString().includes(term)||
        (r.organisation==null ? '' : r.organisation.designation).toString().toLowerCase().includes(term)

    })
    this.collectionSize = this.data.length
  }


  openUpdateModal(content, el) {
    this.data_current = el
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openAddModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  constructor(
    private userService: UserService,
    public roleService: RoleService,
    private localService: LocalService,
    private teamService: TeamService,
    private router: Router,
    private organisationService: OrganizationService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute) { }


  ngOnInit() {

    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')


    this.role = localStorage.getItem("userRole")
    this.userData = this.localService.getJsonValue("userData")
    this.data = []
    this._temp = []

    this.activatedRoute.snapshot.data.user_list.data.forEach((e) => {
      e.role= e.role==null ? '' :
      this.roleService.getRole(e.role)
      if (!this.roleAllFormat([0]).includes(e.role) && this.userData.id != e.id) {
        this.data.push(e)
      }
    })
    this._temp = this.data
    this.collectionSize = this.data.length

    this.organisations = []
    this.organisationService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        this.organisations.push(new Organization(e))
      })
    })
    this.teams = []
    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        this.teams.push(new Team(e))
      })
    })


  }

  init(){
    this.data = []
    this._temp = []
    this.userService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        e.role= e.role==null ? '' :
        this.roleService.getRole(e.role)
        if (!this.roleAllFormat([0]).includes(e.role) && this.userData.id != e.id) {
          this.data.push(e)
        }
      })
      this._temp = this.data
      this.collectionSize = this.data.length
    })

  }
  roleAllFormat(index){
    let rolesFormatOne = ["Administrateur", "Responsable", "Superviseur", "Invite", "Gestionnaire de donées", "Enqueteur socio", "Enqueteur topo"];
    let rolesFormatTwo = ["ADMIN", "RESPONSABLE", "SUPERVISEUR", "INIVITE", "GESTIONNAIREDEDATA", "ENQUETEURSOCIO", "ENQUETEURTOPO"];

    let outLocal = [];

    index.forEach(element => {
      outLocal.push(rolesFormatOne[element]);
      outLocal.push(rolesFormatTwo[element]);
    });

    return outLocal;
  }



  isDeletable(el){
    return this.roleAllFormat([1, 2, 3, 4]).includes(el.role);
  }

  hideOrShowPassword(){
    let eye = document.getElementById("pswd");
    if(eye.getAttribute("type") === "password"){
      eye.setAttribute("type", "text");
      document.getElementById(eye.getAttribute("icono")).classList.remove("fa-eye");
      document.getElementById(eye.getAttribute("icono")).classList.add("fa-eye-slash");
      document.getElementById(eye.getAttribute("icono")).classList.add("text-success");
    }else{
      eye.setAttribute("type", "password");
      document.getElementById(eye.getAttribute("icono")).classList.remove("fa-eye-slash");
      document.getElementById(eye.getAttribute("icono")).classList.remove("text-success");
      document.getElementById(eye.getAttribute("icono")).classList.add("fa-eye");
    }
  }


  create(value) {

    value.password = value.password=="" ||value.password==undefined ||value.password==null ? "default" :value.password

    this.userService.create(value).subscribe((res: any) => {

      if (res.code == 200) {
        value.userId = res.msg.split(':')[1]

        this.modalService.dismissAll()
          //  window.location.reload()
        this.init()
        AlertNotif.finishConfirmWithReload("Nouvel ajout", "Ajout éffectué avec succès", 'success')


      } else if(res.code==500 && res.msg=="ALREADY_EXIST_USER"){
        this.modalService.dismissAll()
        AlertNotif.finish("Nouvel ajout", "L'email exist déjà", 'error')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }

    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Nouvel ajour", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Nouvel ajout", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }



  update(value) {
    value.validate = this.data_current.validate
    value.enabled = this.data_current.enabled
    if (value.telephone == this.data_current.telephone) {
      delete value.telephone
    }
    if (value.password == "" || value.password == null) {
      delete value.password
    }
    // value.email=this.data_current.email
   // delete value.email
    this.userService.update(value, this.data_current.id).subscribe((res: any) => {
      //console.log('###########------------------------------##############');
      //console.log(value);
      if (res.code == 200) {
        this.modalService.dismissAll();
        //window.location.reload()
        this.init()
        AlertNotif.finishConfirmWithReload("Nouvelle modification", "Un element modifié avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Nouvelle modification", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }
  enable(id) {
    this.userService.update({ enabled: true }, id).subscribe((res: any) => {
      this.ngOnInit()

      AlertNotif.finish("Nouvelle modification", "Un eelement activer avec succès", 'success')
    }, (err) => {
      AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })

  }

  changeEnable(el) {
    if(el.enabled == true){
      el.enabled=false
      delete el.email
      delete el.telephone
      AlertNotif.finishConfirm("Deactivation de compte",
      "Voulez-vous continuer ?").then((result) => {
        if (result.value) {
          this.userService.update(el, el.id).subscribe((res) => {
            //window.location.reload()

             AlertNotif.finishConfirmWithReload("Deactivation de compte", "Un compte desactiver avec succès", 'success')
           }, (err) => {
             AlertNotif.finish("Deactivation de compte", "Une erreur est survenue, verifier votre connexion SVP", 'error')
           })
        }
      })
    }else{
      el.enabled=true
    delete el.email
    delete el.telephone
    AlertNotif.finishConfirm("Activation de compte",
    "Voulez-vous continuer ?").then((result) => {
      if (result.value) {
        this.userService.update(el, el.id).subscribe((res) => {
          //window.location.reload()

           AlertNotif.finishConfirmWithReload("Activation de compte", "Un compte activer avec succès", 'success')
         }, (err) => {
           AlertNotif.finish("Activation de compte", "Une erreur est survenue, verifier votre connexion SVP", 'error')
         })
      }
    })
    }


  }
  archive(id) {
    AlertNotif.finishConfirm("Suppression définitive",
      "Voulez-vous continuer ?").then((result) => {
        if (result.value) {
          this.userService.delete(id).subscribe((res: any) => {
            if(res.code==200){
              //window.location.reload()
              this.init()
              AlertNotif.finish("Suppression définitive", "Suppression effectué avec succès", 'success')
            }else{
              AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
            }

          }, (err) => {
            AlertNotif.finish("Suppression définitive", "Une erreur est survenue, verifier votre connexion SVP", 'error')
          })
        }
      })
  }
  archiveOnTeam(id) {
    AlertNotif.finishConfirm("Supprimer l'utilisateur de l'equipe",
      "Voulez-vous continuer ?").then((result) => {
        if (result.value) {
          this.userService.deleteOnTeam(id).subscribe((res: any) => {
         //  window.location.reload()
         if(res.code==200){
            this.init()
            AlertNotif.finishConfirmWithReload("Supprimer l'utilisateur de l'equipe", "Suppression effectué avec succès", 'success')
         }else{
          AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
        }
          }, (err) => {
            AlertNotif.finish("Supprimer l'utilisateur de l'equipe", "Une erreur est survenue, verifier votre connexion SVP", 'error')
          })
        }
      })
  }


}
