import Swal from 'sweetalert2'

export const AlertNotif: any = {

 
    finish(title,msg,type){
        return Swal.fire(
            title,
            msg,
            type
          )
      },
      finishConfirm(head,msg){
        return Swal.fire({
            title: head,
            text: msg,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non'
          })      
    },
    finishConfirmWithReload(head,msg,type){
        return Swal.fire({
            title: head,
            text: msg,
            icon: type,
            showCancelButton: true,
            confirmButtonText: 'Actualiser',
            cancelButtonText: 'Fermer sans actualiser'
          }).then((result) => {
            if (result.value) {
                location.reload()
            }
        })   
    }
}