import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthComponent } from './auth/auth.component';
import { UserComponent } from './user/user.component';
import { InterviewComponent } from './interview/interview.component';
import { DetailInterviewComponent } from './detail-interview/detail-interview.component';
import { OrganizationComponent } from './organization/organization.component';
import { AuthRoleGuard } from './core/_guards/auth-role.guard';
import { TeamComponent } from './team/team.component';
import { UserValidationComponent } from './user-validation/user-validation.component';
import { IsAuthGuard } from './core/_guards/is-auth.guard';
import { TeamMembersComponent } from './team-members/team-members.component';
import { UserMyComponent } from './user-my/user-my.component';
import { ProfilComponent } from './profil/profil.component';
import { UserGetAllResolve, UserGetAllMobileResolve, UserGetMeResolve } from './core/_resolvers/user.resolve';
import { OrganizationGetAllResolve } from './core/_resolvers/organization.resolve';
import { TeamGetAllResolve } from './core/_resolvers/team.resolve';
import { InterviewGetResolve, InterviewsGetPageOneResolve, ConsolidationDownloadListGetResolve  , ConsolidationFailedGetAllResolve, RecordUnconsolidateGetAllResolve, ConsolidationGetAllParcelleResolve, ConsolidationSuccessListGetResolve, InterviewsRejectGetPageOneResolve, InterviewsValidateGetPageOneResolve, InterviewsPostValidationGetPageOneResolve } from './core/_resolvers/interviews.resolve';
import { ConsolidationComponent } from './consolidation/consolidation.component';
import { RepertoireComponent } from './repertoire/repertoire.component';
import { ConsolidationFilesComponent } from './consolidation-files/consolidation-files.component';
import { ConsolidationTopoFailedComponent } from './consolidation-topo-failed/consolidation-topo-failed.component';
import { ConsolidationSocioFailedComponent } from './consolidation-socio-failed/consolidation-socio-failed.component';
import { PrintReportComponent } from './print-report/print-report.component';
import { SelectLocalityComponent } from './select-locality/select-locality.component';

import { ExtraitCadastralComponent } from './extrait-cadastral/extrait-cadastral.component';
import { ConsolidationSuccessComponent } from './consolidation-success/consolidation-success.component';
import { InterviewPostValidationComponent } from './interview-post-validation/interview-post-validation.component';
import { InterviewValidatedComponent } from './interview-validated/interview-validated.component';
import { InterviewRejectedComponent } from './interview-rejected/interview-rejected.component';
import { ErrorReportComponent } from './error-report/error-report.component';
import { BlocComponent } from './bloc/bloc.component';


const routes: Routes = [
  { path: 'login', component: AuthComponent,canActivate:[IsAuthGuard] },
  { path: 'dashboard', component: DashboardComponent,canActivate:[AuthRoleGuard]},//,resolve:{data_list:ConsolidationGetAllParcelleResolve}
  { path: 'consolidation', component: ConsolidationComponent,canActivate:[AuthRoleGuard],resolve:{data_list:ConsolidationDownloadListGetResolve,team_list:TeamGetAllResolve}},
  { path: 'consolidation-success', component: ConsolidationSuccessComponent,canActivate:[AuthRoleGuard],resolve:{data_list:ConsolidationSuccessListGetResolve}},
  //{ path: 'consolidation-files/:session', component: ConsolidationFilesComponent,canActivate:[AuthRoleGuard],resolve:{data_list:ConsolidationFilesListGetResolve}},
  { path: 'error-report/:session', component: ErrorReportComponent,canActivate:[AuthRoleGuard]},
  { path: 'consolidation-failed/:session', component: ConsolidationTopoFailedComponent,canActivate:[AuthRoleGuard],resolve:{data_list:ConsolidationFailedGetAllResolve}},
  { path: 'unconsolidation-record/:team/:session', component: ConsolidationSocioFailedComponent,canActivate:[AuthRoleGuard],resolve:{data_list:RecordUnconsolidateGetAllResolve}},
  { path: 'users', component: UserComponent,canActivate:[AuthRoleGuard],resolve:{user_list:UserGetAllResolve}},
  { path: 'my-users-manage', component: UserMyComponent,canActivate:[AuthRoleGuard],resolve:{user_list:UserGetAllResolve}},
  { path: 'interviews', component: InterviewComponent,canActivate:[AuthRoleGuard]}, //,resolve:{list:InterviewsGetPageOneResolve}
  //{ path: 'interviews-reject', component: InterviewRejectedComponent,canActivate:[AuthRoleGuard],resolve:{list:InterviewsRejectGetPageOneResolve}},
 // { path: 'interviews-validate', component: InterviewValidatedComponent,canActivate:[AuthRoleGuard],resolve:{list:InterviewsValidateGetPageOneResolve}},
  //{ path: 'interviews-post-validation', component: InterviewPostValidationComponent,canActivate:[AuthRoleGuard],resolve:{list:InterviewsPostValidationGetPageOneResolve}},
  { path: 'repertories', component: RepertoireComponent,canActivate:[AuthRoleGuard]},
  { path: 'blocs', component: BlocComponent,canActivate:[AuthRoleGuard]},
  { path: 'print/:localityId', component: PrintReportComponent,canActivate:[AuthRoleGuard]},
  { path: 'organizations', component: OrganizationComponent,canActivate:[AuthRoleGuard],resolve:{org_list:OrganizationGetAllResolve}},
  { path: 'teams', component: TeamComponent,canActivate:[AuthRoleGuard],resolve:{team_list:TeamGetAllResolve}},
  { path: 'validations', component: UserValidationComponent,canActivate:[AuthRoleGuard],resolve:{user_list:UserGetAllMobileResolve}},
  { path: 'team-members/:id', component: TeamMembersComponent,canActivate:[AuthRoleGuard]},
  { path: 'detail-interviews/:id', component: DetailInterviewComponent,canActivate:[AuthRoleGuard],resolve:{details:InterviewGetResolve}},
  { path: 'extrait-cadastral/:ref/:no', component: ExtraitCadastralComponent,canActivate:[AuthRoleGuard]},//,resolve:{details:InterviewGetResolve}
  { path: 'select-locality', component: SelectLocalityComponent,canActivate:[AuthRoleGuard]},
  { path: 'profil', component: ProfilComponent,canActivate:[AuthRoleGuard],resolve:{user_one:UserGetMeResolve}},
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: '**', redirectTo: 'dashboard', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
