<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <a href="index.html" class="logo">
                    <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                    <br>
                </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Les blocs</h4>
                        </div>
                        <div class="col-sm-6">
                                <ol class="breadcrumb float-right">
                                        <li class="breadcrumb-item">INFOCAD</li>
                                        <li class="breadcrumb-item active">Les blocs</li>
                                </ol>
                           
                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row my-2">
                    <div class="col-md-6 d-flex justify-content-sm-start">
                        <h1 class="page-title">
                                <p style="text-align: right;">
                                        <button (click)="openAddModal(content)" *ngIf="current_role==Responsable" class="btn btn-primary"><i class="fa fa-plus"></i>
                                            Ajouter un nouveau</button>
                                    </p>
                        </h1>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                          
                                <form>
                                        <div class="form-group form-inline">
                                            <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                                                [(ngModel)]="searchText" name="searchText" (keyup)="search()" />
                                        </div>
                                </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                      
                    </div>
                    <div class="col-md-12">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <div></div>
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>
                                            <th>Cote</th>
                                            <th>Description</th>
                                            <th *ngIf="current_role==Responsable">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let el of data ; let i=index">
                                            <td>
                                                {{el.cote}}
                                            </td>
                                            <td class="hidden-xs">
                                                {{el.description}}
                                            </td>
                                            <td *ngIf="current_role==Responsable">
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button (click)="openUpdateModal(contentAddLocation,el)" ngbDropdownItem>Localités</button>
                                                        <button (click)="archive(el.id,i)" ngbDropdownItem>Supprimer</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-end p-2">

                                    <div *ngIf="pager!=Null">
                                      <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                                        <li [ngClass]="{disabled:pager.current_page === 1}" class="page-item first-item">
                                          <a routerLink="/blocs" [queryParams]="{ page: 1 }" class="page-link">Début</a>
                                        </li>
                      
                                        <li  [ngClass]="{disabled:pager.current_page === 1}" class="page-item previous-item">
                                          <a routerLink="/blocs" [queryParams]="{ page: pager.current_page - 1 }"
                                            class="page-link">Précédent</a>
                                        </li>
                      
                                        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.current_page === page}" class="page-item number-item">
                                          <a routerLink="/blocs" [queryParams]="{ page: page }" class="page-link">{{page}}</a>
                                        </li>
                      
                                        <li [ngClass]="{disabled:pager.current_page === pager.last_page}" class="page-item next-item">
                                          <a routerLink="/blocs" [queryParams]="{ page: pager.current_page + 1 }"
                                            class="page-link">Suivant</a>
                                        </li>
                                        <li [ngClass]="{disabled:pager.current_page === pager.last_page}" class="page-item next-item">
                                          <a routerLink="/blocs" [queryParams]="{ page: pager.last_page }" class="page-link">Fin</a>
                                        </li>
                                        <li  class="page-item last-item disabled">
                                          <a  class="page-link">{{pager.last_page}} page(s)</a>
                                        </li>
                                      </ul>
                                    </div>
                      
                                  </div>
            
                               

                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->

        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>
    <ng-template #content let-modal>
        <form #newForm="ngForm" (ngSubmit)="create(newForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Nouvel ajout</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <h5>X</h5>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="form-group">
                    <label for="">Cote</label>
                    <input type="text" class="form-control" required ngModel name="cote">
                </div>
                <div class="form-group">
                    <label for="">Description</label>
                    <textarea class="form-control" ngModel name="description"></textarea>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!newForm.form.valid" type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <ng-template #contentU let-modal>
        <form #updateForm="ngForm" (ngSubmit)="update(updateForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Nouvel modification</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-remove font-size-h3"></i>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="form-group">
                    <label for="">Cote</label>
                    <input type="text" class="form-control" [(ngModel)]="data_current.cote" required ngModel name="cote">
                </div>
                <div class="form-group">
                    <label for="">Description</label>
                    <textarea class="form-control" ngModel [(ngModel)]="data_current.description" name="description"></textarea>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid" type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <ng-template #contentAddLocation let-modal>
        <form #addLocalityForm="ngForm" (ngSubmit)="add_locality(addLocalityForm.value)">
            <div class="modal-header">
                <h6 class="modal-title text-center" id="modal-basic-title">Gestion des localitées du bloc {{data_current.cote}}</h6>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-remove font-size-h3"></i>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="row d-flex justify-content-center">
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group f">
                            <label for="" class="col-md-12 ">​ Département : </label>
                            <select #departement="ngModel"  [(ngModel)]="selected_dep" (change)="getLocalitiesLev2($event)"
                                    name="departement" id="" class="form-control col-md-12">
                              <option selected disabled>Choisir un département</option>
                              <option [value]="el.id" *ngFor="let el of departements; let i=index">
                                {{el.libelle}} </option>
                            </select>
                          </div>
                          <div class="form-group f">
                              <label for="" class="col-md-12 ">​ Commune : </label>
                              <select #commune="ngModel"  [(ngModel)]="selected_commune" (change)="getLocalitiesLev3($event)" name="commune" id=""
                                      class="form-control col-md-12">
                                <option selected disabled>Choisir une commune</option>
                                <option [value]="el.id" *ngFor="let el of communes; let i=index">
                                  {{el.libelle}} </option>
                              </select>
                            </div>
        
                          <div class="form-group f">
                              <label for="" class="col-md-12 ">​ Arrondissement : </label>
                              <select #arrondissement="ngModel"  [(ngModel)]="selected_arr" (change)="getLocalitiesLev4($event)"
                                      name="arrondissement" id="" class="form-control col-md-12">
                                <option selected disabled>Choisir un arrondissement</option>
                                <option [value]="el.id" *ngFor="let el of arrondissements; let i=index">
                                  {{el.libelle}} </option>
                              </select>
                            </div>
                            <div class="form-group f">
                                <label for="" class="col-md-12 ">​ Ville/Quartier : </label>
                                <!--select #locality="ngModel"  (change)="filterLocalitiesLev4($event)" name="locality"
                                        [(ngModel)]="selected_locality" id="" class="form-control col-md-12">
                                  <option selected disabled>Choisir une ville/quartier</option>
                                  <option [value]="el.id" *ngFor="let el of localities; let i=index">
                                    {{el.libelle}} </option>
                                </select-->
                                <ng-multiselect-dropdown
                                    [placeholder]="'Choisir une ville/quartier'"
                                    [settings]="dropdownSettings"
                                    [data]="localities"
                                    name="locality"
                                    [(ngModel)]="selectedLocalityItems"
                                    (onSelect)="onItemSelect($event)"
                                    (onSelectAll)="onSelectAll($event)"
                                >
                                </ng-multiselect-dropdown>
                            </div>
                            <div class="col-md-12 text-center">
                                <button type="button" class="btn btn-outline-dark" [disabled]="!addLocalityForm.form.valid" type="submit">Ajouter</button>
                            </div>
                    </div>
                    <div class="col-md-6">
                            <table class="table table-striped table-bordered">
                                <tr>
                                    <th>Localité</th>
                                    <th>Supprimer</th>
                                </tr>
                                <ng-container *ngFor="let el of data_current.localities | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                    <tr  style="background-color: lightgray;">
                                        <td>{{el.libelle}}</td>
                                        <td><button class="btn btn-sm btn-danger" type="button" (click)="remove_locality(el.id)"> Supprimer</button></td> 
                                     </tr>
                                     <tr *ngFor="let elt of el.child">
                                        <td>{{elt.libelle}}</td>
                                        <td><button class="btn btn-sm btn-danger"  type="button" (click)="remove_locality(elt.id)"> Supprimer</button></td> 
                                     </tr>
                                </ng-container>
                            </table>
                            <div class="d-flex justify-content-between p-2">
                                <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                    [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                    [boundaryLinks]="true">
                                </ngb-pagination>

                               
                            </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
            </div>
        </form>
    </ng-template>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->