import { Component, OnInit } from '@angular/core';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { Roles } from '../core/_models/roles';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css']
})
export class AsideComponent implements OnInit {

  current_role:any
  Admin="ADMIN"
  Responsable="RESPONSABLE"
  Superviseur="SUPERVISEUR"
  INVITE="INVITE"
  GESTIONNAIREDEDATA="GESTIONNAIREDEDATA"
  ENQUETEURTOPO="ENQUETEURTOPO"
  LOCALITYID="4762" // Afficher pour une localité par défaut
  Null=null
  constructor(
    private localService:LocalService
  ) { }
  user:any

  ngOnInit(): void {
      if(localStorage.getItem('userRole')!=null){
        this.current_role=localStorage.getItem('userRole')
      }
      this.user = this.localService.getJsonValue("userData")
  }

  roleAllFormat(index){
    let rolesFormatOne = ["Administrateur", "Responsable", "Superviseur", "Invite", "Gestionnaire de donées", "Enqueteur socio", "Enqueteur topo"];
    let rolesFormatTwo = ["ADMIN", "RESPONSABLE", "SUPERVISEUR", "INIVITE", "GESTIONNAIREDEDATA", "ENQUETEURSOCIO", "ENQUETEURTOPO"];

    let outLocal = [];

    index.forEach(element => {
      outLocal.push(rolesFormatOne[element]);
      outLocal.push(rolesFormatTwo[element]);
    });

    return outLocal;
  }

  accessUsersListMenu(index, current_role){
    return this.roleAllFormat([index]).includes(current_role);
  }
}
