<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                <br>
            </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Liste parcelles</h4>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <ol class="breadcrumb float-right">
                                <li class="breadcrumb-item">INFOCAD</li>
                                <li class="breadcrumb-item "><a routerLink="/consolidation">Consolidations</a></li>
                                <li class="breadcrumb-item active">Liste parcelles</li>
                            </ol>


                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row my-2">
                    <div class="col-md-6 d-flex justify-content-start">
                        <h1 class="page-title">
                            <a routerLink="/consolidation" class="btn btn-outline-secondary"> <i class="fa  fa-arrow-left"></i>
                                Retour</a>
                          
                        </h1>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end ">
                         
                        <div class="form-group form-inline mx-2">
                                <label for="" class="mx-1">Filtre : </label>
                                <select class="form-control form-control-sm " 
                                    ngModel name="searchText" (change)="Filter($event)" >
                                    <option value="all">Tout</option>
                                    <option value="consolidated">Consolidés</option>
                                    <option value="unconsolidated">Non Consolidés</option>
                                </select>
                        </div>
                        <div class="form-group form-inline">
                            <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                                [(ngModel)]="searchText" name="searchText" (keyup)="search()" />
                        </div>


                    </div>
                </div>
                <div class="row">

                    <div class="col-md-12 mx-auto">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>

                                            <th>N° PARC</th>

                                            <th>Village</th>

                                            <th class="hidden-xs">Nom</th>
                                            <th class="hidden-xs">Prenom</th>
                                            <th class="hidden-xs">Nationnalite</th>
                                            <th class="hidden-xs">Date Enrg</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">

                                            <td>
                                                {{el.no}}
                                            </td>
                                            <td>
                                                {{el.villagenam}}
                                            </td>
                                            <td>
                                                {{el.familyname}}
                                            </td>

                                            <td>
                                                {{el.firstname1}}
                                            </td>
                                            <td>
                                                {{el.countyname}}
                                            </td>
                                            <td>
                                                {{el.date | date:'short'}}
                                            </td>
                                            <td>
                                                <button *ngIf="current_role==Superviseur" class="btn btn-outline-primary btn-sm mr-1" 
                                                    (click)="openUpdateModal(nupU,el)">Modifier
                                                </button>
                                                <button class="btn btn-outline-primary btn-sm mr-1" (click)="openDetailModal(infos,el)">Voir
                                                    details
                                                </button>
                                                <button class="btn btn-outline-primary btn-sm" *ngIf="current_role==Superviseur && el.consolidated!=One" (click)="echecSocio(socioContent,el.no,el.id_team)">Consolider</button>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <ng-template #socioContent let-modal>
                <form #consolidateForm="ngForm" (ngSubmit)="consolidationUnitaire(consolidateForm.value)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title"> Consolidation Echec Socio</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <i class="fa fa-remove font-size-h3"></i>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-8">
                                <p>
                                    Equipe : {{team_data.cote}}
                                    (
                                    <small *ngFor="let m of team_data.members | slice:0:4; let ix=index">
                                        {{m.forename}} {{m.surname}}
                                        {{ ix==(team_data.members.length-1) ? '' :','}}

                                    </small>
                                    )
                                </p>

                            </div>
                            <div class="col-md-4 d-flex justify-content-end">
                                <div class="form-group form-inline">
                                    <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                                        [(ngModel)]="searchText" name="searchText" (keyup)="search2()" />
                                </div>
                            </div>
                        </div>
                        <table class="table  table-striped ">
                            <thead>
                                <tr>
                                    <th>N° provisoire</th>
                                    <th>Village</th>
                                    <th class="hidden-xs">Date</th>
                                    <th class="hidden-xs">Proprietaire</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let el of socio_data | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; let i=index">

                                    <td>
                                        {{el.nup}}
                                    </td>
                                    <td>
                                        {{el.locality.libelle}}
                                    </td>
                                    <td>
                                        {{el.issueDate | date:'short'}}
                                    </td>

                                    <td>
                                        {{el.owner}}
                                    </td>

                                    <td>

                                        <button class="btn btn-outline-primary btn-sm" [disabled]="selected_data.consolidated!=One"
                                            (click)="consolidationUnitaire(el.localRef)">Consolider</button>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <div class="d-flex justify-content-between p-2">
                            <ngb-pagination class="" [collectionSize]="collectionSize2" [(page)]="page2" [maxSize]="5"
                                [pageSize]="pageSize2" [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
                            </ngb-pagination>

                            <select class="custom-select custom-select-sm float-right" style="width: auto" [(ngModel)]="pageSize2">
                                <option selected disabled>Fitre</option>
                                <option [ngValue]="3">3 éléments par page</option>
                                <option [ngValue]="5">5 éléments par page</option>
                                <option [ngValue]="10">10 éléments par page</option>
                            </select>
                        </div>



                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-dark align-self-center" [disabled]="!consolidateForm.form.valid"
                            type="submit">Sauvegarder
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="uploaded"></span>
                        </button>

                    </div>
                </form>
            </ng-template>
            <ng-template #infos let-modal id="infos">
                <form>

                    <div class="modal-header">
                        <h6 class="modal-title" id="modal-basic-title"> Informations sur la parcelle sélectionnée
                        </h6>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            X
                        </button>
                    </div>
                    <div class="modal-body">
                        <small *ngIf="current_parcel_data.consolidated!=One" class="text-center">
                            Cette parcelle n'est pas consolidée
                        </small>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="form-group">
                                    <label for="modal-bbox-nup">Parcelle</label>
                                    <input id="modal-bbox-nup" class="form-control" name="nup" [(ngModel)]="current_parcel_data.no"
                                        disabled>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-departement">Département</label>
                                    <input id="modal-bbox-departement" name="departement" [(ngModel)]="current_parcel_data.districtna"
                                        class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-commune">Commune</label>
                                    <input id="modal-bbox-commune" name="commune" class="form-control" [(ngModel)]="current_parcel_data.subcountyn"
                                        disabled>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-arrondissement">Arrondissement</label>
                                    <input id="modal-bbox-arrondissement" name="arrondissement" [(ngModel)]="current_parcel_data.parishname"
                                        class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-quartier">Quartier</label>
                                    <input id="modal-bbox-quartier" class="form-control" name="quartier" [(ngModel)]="current_parcel_data.villagenam"
                                        disabled>
                                </div>
                            </div>
                        </div>

                    </div>

                </form>

            </ng-template>
            <ng-template #nupU let-modal>
                <form #updateForm="ngForm" (ngSubmit)="saveNup(updateForm.value)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Modification info parcelle</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <i class="fa fa-remove font-size-h3"></i>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="form-group">
                            <label for="">Nom</label>
                            <input class="form-control input-sm" name="lastname" required [(ngModel)]="selected_data.familyname"
                                type="text">
                        </div>

                        <div class="form-group">
                            <label for="">Prénom 1</label>
                            <input class="form-control input-sm" name="firstname1" required [(ngModel)]="selected_data.firstname1"
                                type="text">
                        </div>

                        <div class="form-group">
                            <label for="">Prénom 2</label>
                            <input class="form-control input-sm" name="firstname2" required [(ngModel)]="selected_data.firstname2"
                                type="text">
                        </div>

                        <div class="form-group">
                            <label for="">Nouveau numéro parcelle</label>
                            <input class="form-control input-sm" name="nup" [disabled]="selected_data.consolidated==One" required [(ngModel)]="selected_data.no"
                                type="text">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid" type="submit">Sauvegarder</button>
                    </div>
                </form>
            </ng-template>
        </div>
        <!-- content -->

        <app-footer>
        </app-footer>

    </div>


    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->