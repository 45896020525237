import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Config } from '../../app.config';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  url="api/roles";
  constructor(private http:HttpClient) { }
 

  getAll(){
   
    return this.http.get<any[]>(`${this.url}/my`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  joinUserToRole(ressource){
    return this.http.post<any>(`api/roles/associate`, ressource,
     Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`added ressource ${ressource}`))
    );
  }

  getRole(key){
    if("ADMIN"==key){
      return "Administrateur"
    }
    if("SUPERVISEUR"==key){
      return "Superviseur"
    }
    if("ENQUETEURSOCIO"==key){
      return "Enqueteur socio"
    }
    if("ENQUETEURTOPO"==key){
      return "Enqueteur topo"
    }
    if("RESPONSABLE"==key){
      return "Responsable"
    }
    if("INVITE"==key){
      return "Invité"
    }
    if("GESTIONNAIREDEDATA"==key){
      return "Gestionnaire de donées"
    }
   
  }
}
