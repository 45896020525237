export class User {
    id: number;
    country: string;
    surname: string;
    forename: string;
    username: string;
    telephone: string;
    adress: string;
    avatar: string;
    email: string;
    is_active: number;
    token: string;
    sex: string;
    validate: boolean;
    created_at: string;
    role ?: string;
    enabled:boolean
    team:any
    organisation:any
    

    constructor(attrs: any = null) {
        if (attrs) {
          this.build(attrs);
        }
      }

      build(attrs: any): void {
        this.id = attrs.id;
        this.is_active = attrs.is_active;
        this.email = attrs.email;
        this.surname = attrs.surname;
        this.forename = attrs.forename;
        this.username = attrs.username;
        this.telephone = attrs.telephone;
        this.adress = attrs.adress;
        this.avatar = attrs.avatar;
        this.sex = attrs.sex;
        this.country = attrs.country;
        this.validate = attrs.validate;
        this.token = attrs.token;
        this.created_at = attrs.created_at;
        this.role = attrs.role;
        this.enabled = attrs.enabled;
      }
    toJson() {
        return {
            id: this.id,
            is_active: this.is_active,
            email: this.email,
            surname: this.surname,
            forename: this.forename,
            telephone: this.telephone,
            adress: this.adress,
            avatar: this.avatar,
            sex: this.sex,
            country: this.country,
            validate: this.validate,
            username:this.username,
            token: this.token,
            created_at: this.created_at,
            role: this.role,
            enabled:this.enabled

          };
    }

}
