import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Config } from '../../app.config';
import { catchError, tap, map, delay } from 'rxjs/operators';
import { Bloc } from '../_models/bloc.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class BlocService {

  url="api/backoffice/v2/blocs";
  constructor(private http:HttpClient) { }
 
  getAll(page, pageSize): Observable<Bloc[]>{
   
    return this.http.get<Bloc[]>(`${this.url}?limit=${pageSize}&page=${page}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));
  }
 /* getMyAll(){
   
    return this.http.get<any[]>(`${this.url}/my`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }*/

  get(id){
    return this.http.get<any[]>(`${this.url}/${id}/less`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  
  create(ressource){
    return this.http.post<any>(`${this.url}`, ressource,
     Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`added ressource ${ressource}`))
    );
  }
  add_locality(ressource){
    return this.http.post<any>(`${this.url}/localities/less`, ressource,
     Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`added ressource ${ressource}`))
    );
  }
  update(ressource,id){
    return this.http.put<any>(`${this.url}/${id}`, ressource,  Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }
  update_partial(ressource,id){
    return this.http.patch<any>(`${this.url}/${id}`, ressource,  Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }

  delete(id:number){
    return this.http.delete<any[]>(`${this.url}/${id}`,Config.httpHeader());
  }
}
