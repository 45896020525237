<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <a href="index.html" class="logo">
                    <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                    <br>
                </a>
        </div>
        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content" >
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <button class="btn btn-success mx-1" (click)="generatePDF()">Telecharger </button>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->

                <div class="card p-2"  id="extraitContent" #extraitContent>
                    <div class="row">
                        <div class="col-md-10 my-5  mx-auto">
                            <p class="c2 c4"><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 194.60px; height: 56.61px;"><img
                                        alt="" src="assets/img/official_logo_finance.png" style="width: 194.60px; height: 56.61px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                                        title=""></span><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                                        <span class="c16" style="margin-right: 6vw">
                                                EXTRAIT DE PLAN CADASTRAL &nbsp;</span><span class="c12">&nbsp; &nbsp; &nbsp; </span><span>&nbsp;
                                    &nbsp; &nbsp;
                                    &nbsp; &nbsp;</span>
                                    <span  style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 90.50px; height: 76.02px;"><img
                                        alt="" src="assets/img/andf-logo.png" style="width: 90.50px; height: 76.02px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                                        title=""></span></p>
                            <p class="c0"><span class="c9"></span></p><a id="t.d5e2828a0af108e50023669fcf1d5b34ebdb13cb"></a><a
                                id="t.0"></a>
                            <table class="c10 mt-3">
                                <tbody>
                                    <tr class="c14">
                                        <td class="c18" colspan="2" rowspan="1">
                                            <p class="c7">

                                            </p>
                                            <div id="chartdiv"  style="width: 800.00px; height: 460.05px;">

                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="c5">
                                        <td class="c8" style="padding: 7px" colspan="1" rowspan="2">
                                            <p class="c7"><span class="c11">NOM DU DEMANDEUR</span></p>
                                            <p class="c1"><span class="c6"></span></p>
                                            <p class="c7"><span class="c6">{{data.parcel.identification}}</span></p>
                                            <p class="c1"><span class="c6"></span></p>
                                            <p class="c17"><span class="c11">LOCALISATION</span></p>
                                            <p class="c2"><span>D&eacute;partement : &nbsp; &nbsp; &nbsp;</span><span
                                                    class="c6">{{data.parcel.locality==null ? '' : data.parcel.locality.localityMere.libelle}}</span></p>
                                            <p class="c2"><span>Commune : &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span
                                                    class="c6">{{data.parcel.locality==null ? '' : data.parcel.locality.libelleUnique | slice:8}}</span></p>
                                            <p class="c2"><span>Arrondissement : </span><span class="c6">{{data.parishname}}</span></p>
                                            <p class="c2"><span>Quartier/Village : &nbsp;</span><span class="c6">{{data.parcel.locality==null ? '' : data.parcel.locality.libelle}}</span></p>
                                            <p class="c1"><span class="c9"></span></p>
                                            <p class="c2"><span class="c11">INFORMATIONS DE LA PARCELLE</span></p>
                                            <p class="c2"><span>NUP Prov: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span
                                                    class="c6">{{data.parcel.iup }}</span></p>
                                            <!--
                                                <p class="c2"><span>Titre Foncier n&deg; : &nbsp;</span><span class="c6">1274</span></p>
                                                <p class="c2"><span>Ilot(s) : &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                        &nbsp; &nbsp; &nbsp;</span><span class="c6">{{data.parcelleno}}</span></p>
                                                <p class="c2"><span>Parcelle(s) : &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span><span
                                                        class="c6">e</span></p>
                                            --> 
                                           
                                            <p class="c2"><span>Surface estim&eacute;e : </span><span class="c6">{{data.superficie}} m2</span></p>
                                            <!--
                                                <p class="c2"><span>Source : &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                    &nbsp; &nbsp;</span><span class="c6">{{data.parcel.typeSource}}</span></p>
                                            -->
                                            <p class="c1"><span class="c9"></span></p>
                                        </td>
                                        <td class="c8" colspan="1" rowspan="1">
                                            <p class="c1"><span class="c9"></span></p>
                                        </td>
                                    </tr>
                                    <tr class="c15">
                                        <td class="c8" style="padding: 7px" colspan="1" rowspan="1">
                                            <p class="c7"><span class="c11">CONTACT</span></p>
                                            <p class="c1"><span class="c9"></span></p>
                                            <p class="c7"><span class="c13">AGENCE NATIONALE DU DOMAINE ET DU FONCIER
                                                    (ANDF)</span></p>
                                            <p class="c7"><span class="c9">Immeuble A&Iuml;SSI, Face &agrave;
                                                    l&#39;&eacute;glise Ste Rita</span></p>
                                            <p class="c7"><span class="c9">T&eacute;l: 97 43 42 93</span></p>
                                            <p class="c7"><span class="c9">mail: andf@finances.bj</span></p>
                                            <p class="c1"><span class="c9"></span></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="c0"><span class="c9"></span></p>
                        </div>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->

        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->