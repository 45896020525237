import { Component, OnInit } from '@angular/core';
import { InterviewService } from '../core/_services/interview.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { TeamService } from '../core/_services/team.service';
import { UserService } from '../core/_services/user.service';
import { AlertNotif } from '../alert';
import { Team } from '../core/_models/team.model';
declare var $: any;

@Component({
  selector: 'app-consolidation-socio-failed',
  templateUrl: './consolidation-socio-failed.component.html',
  styleUrls: ['./consolidation-socio-failed.component.css']
})
export class ConsolidationSocioFailedComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private inverviewService: InterviewService,
    private modalService: NgbModal, private teamService: TeamService,
    private localService: LocalService, ) { }
  files: File
  session = ""
  page = 1
  One=1
  pageSize = 25
  page2 = 1
  pageSize2 = 7
  collectionSize2 = 0
  Admin = "ADMIN"
  Responsable = "RESPONSABLE"
  Superviseur = "SUPERVISEUR"
  INVITE = "INVITE"
  GESTIONNAIREDEDATA = "GESTIONNAIREDEDATA"
  ENQUETEURTOPO = "ENQUETEURTOPO"
  ENQUETEURSOCIO = "ENQUETEURSOCIO"
  closeResult = ""
  current_role = ""
  data_current: any
  user: any
  data: any[] = []
  users: any[] = []
  teams: any[] = []
  _temp: any[] = []
  _temp2: any[] = []
  collectionSize = 0
  team: any
  id_socio = ""
  topo_data = []
  team_data=new Team()

  searchText=""
  selected_topo:any

  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.locality.libelle.toLowerCase().includes(term) || 
      r.nup.toString().toLowerCase().includes(term) ||
      r.owner.toString().toLowerCase().includes(term)
    })
    this.collectionSize = this.data.length
  }
  search2() {
    this.topo_data = this._temp2.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.villagenam.toLowerCase().includes(term) || 
      r.no.toString().toLowerCase().includes(term) ||
      r.firstname1.toString().toLowerCase().includes(term) ||
      r.familyname.toString().toLowerCase().includes(term) ||
      r.countyname.toString().toLowerCase().includes(term)
      
    })
    this.collectionSize2 = this.topo_data.length
  }

  openUpdateModal(content, el) {
    this.selected_topo = el
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  selected_data:any
  current_parcel_data:any
  openDetailModal(content, el) {
    this.selected_data=el
    this.inverviewService.storageSessionData(this.session,+el.no).subscribe((res: any) => {
      this.current_parcel_data = res.data
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
   
  }

  ngOnInit(): void {

    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')


    if (localStorage.getItem('userRole') != null) {
      this.current_role = localStorage.getItem('userRole')
    }
    this.user = this.localService.getJsonValue("userData")
    this.data = []
    this._temp = []

    this.session = this.activatedRoute.snapshot.paramMap.get('session');
    this.team = this.activatedRoute.snapshot.paramMap.get('team');

    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => { if (e.id == this.team) { this.team_data = e } })
      //this.users=res.data
    })



    /*  this.activatedRoute.snapshot.data.data_list.data.forEach((e)=>{
       // if(e.consolidated == -1){
          this.data.push(e)
        //}
      })*/
    this.inverviewService.echecsocio(this.team).subscribe((res: any) => {
      this.data = res.data
      this._temp = this.data
      this.collectionSize = this.data.length
    })

  }
  saveNup(value){
    this.inverviewService.setParcelData(this.session,this.selected_data.no,
      value.nup==undefined || value.nup==null ? this.selected_data.no : value.nup
      ,value.lastname,
      value.firstname1,value.firstname2).subscribe((res: any) => {
        if(res.code==200){
          this.modalService.dismissAll()
                //window.location.reload()
          AlertNotif.finishConfirmWithReload("Mise à Jour Infos parcelle", "Terminer", 'success')

        }else{
          AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
        }

      
    })
  }

  consolidationUnitaire(parcel) {
    this.inverviewService.consolidationUnitaire(this.session, parcel, this.id_socio).subscribe((res: any) => {
      this.modalService.dismissAll()
      if (res.code == 200) {
       // window.location.reload()
        AlertNotif.finishConfirmWithReload("Consolidation unitaire", "Terminer", 'success')

      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }

  echecTopo(content, id_socio) {
    this.id_socio = id_socio
    this.inverviewService.shapefilesDetails(this.session,"unconsolidated").subscribe((res: any) => {
      this.topo_data = res.data.parcels
      this._temp2 = this.topo_data
      this.collectionSize2 = this.topo_data.length
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
