import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './token-interceptor.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { AsideComponent } from './aside/aside.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InterviewComponent } from './interview/interview.component';
import { DetailInterviewComponent } from './detail-interview/detail-interview.component';
import { AuthComponent } from './auth/auth.component';
import { UserComponent } from './user/user.component';
import { AddUserComponent } from './add-user/add-user.component';
import {NgbPaginationModule, NgbAlertModule,NgbModalModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import { OrganizationComponent } from './organization/organization.component';
import { TeamComponent } from './team/team.component';
import { RoleComponent } from './role/role.component';
import { LocalityComponent } from './locality/locality.component';
import { TeamMembersComponent } from './team-members/team-members.component';
import { UserValidationComponent } from './user-validation/user-validation.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserMyComponent } from './user-my/user-my.component';
import { ProfilComponent } from './profil/profil.component';

import { JwtModule } from "@auth0/angular-jwt";
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { UserGetAllMobileResolve, UserGetAllOrgResolve, UserGetAllResolve, UserGetMeResolve } from './core/_resolvers/user.resolve';
import { TeamGetAllResolve } from './core/_resolvers/team.resolve';
import { OrganizationGetAllResolve } from './core/_resolvers/organization.resolve';
import { InterviewGetResolve, InterviewsGetPageOneResolve, ConsolidationDownloadListGetResolve, ConsolidationGetAllParcelleResolve, RecordUnconsolidateGetAllResolve, ConsolidationFailedGetAllResolve, ConsolidationSuccessListGetResolve, InterviewsValidateGetPageOneResolve, InterviewsRejectGetPageOneResolve, InterviewsPostValidationGetPageOneResolve } from './core/_resolvers/interviews.resolve';
import { ConsolidationComponent } from './consolidation/consolidation.component';
import { RepertoireComponent } from './repertoire/repertoire.component';
import { ConsolidationFilesComponent } from './consolidation-files/consolidation-files.component';
import { ConsolidationTopoFailedComponent } from './consolidation-topo-failed/consolidation-topo-failed.component';
import { ConsolidationSocioFailedComponent } from './consolidation-socio-failed/consolidation-socio-failed.component';
import { PrintReportComponent } from './print-report/print-report.component';
import { SelectLocalityComponent } from './select-locality/select-locality.component';
import { ExtraitCadastralComponent } from './extrait-cadastral/extrait-cadastral.component';

import { ExportAsModule } from 'ngx-export-as';
import { FooterComponent } from './footer/footer.component';
import { ConsolidationSuccessComponent } from './consolidation-success/consolidation-success.component';
import { InterviewValidatedComponent } from './interview-validated/interview-validated.component';
import { InterviewRejectedComponent } from './interview-rejected/interview-rejected.component';
import { InterviewPostValidationComponent } from './interview-post-validation/interview-post-validation.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { ErrorReportComponent } from './error-report/error-report.component';

import {ConnectionServiceModule} from 'ng-connection-service';
import { BlocComponent } from './bloc/bloc.component';  
 
export function tokenGetter() {
  return localStorage.getItem("userToken");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AsideComponent,
    DashboardComponent,
    InterviewComponent,
    DetailInterviewComponent,
    AuthComponent,
    UserComponent,
    AddUserComponent,
    OrganizationComponent,
    TeamComponent,
    RoleComponent,
    LocalityComponent,
    TeamMembersComponent,
    UserValidationComponent,
    UserMyComponent,
    ProfilComponent,
    ConsolidationComponent,
    RepertoireComponent,
    ConsolidationFilesComponent,
    ConsolidationTopoFailedComponent,
    ConsolidationSocioFailedComponent,
    PrintReportComponent,
    SelectLocalityComponent,
    ExtraitCadastralComponent,
    FooterComponent,
    ConsolidationSuccessComponent,
    InterviewValidatedComponent,
    InterviewRejectedComponent,
    InterviewPostValidationComponent,
    ErrorReportComponent,
    BlocComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    NgxSpinnerModule,
    NgbModalModule,
    SlimLoadingBarModule,
    NgbDropdownModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgbPaginationModule, NgbAlertModule,
    ExportAsModule,
    ConnectionServiceModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
       // allowedDomains: ["example.com"],
      }
    }),
    ReactiveFormsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  },UserGetAllMobileResolve,UserGetAllOrgResolve,UserGetAllResolve,TeamGetAllResolve,OrganizationGetAllResolve,
  InterviewsValidateGetPageOneResolve,InterviewsRejectGetPageOneResolve,InterviewsPostValidationGetPageOneResolve,
    UserGetMeResolve,InterviewGetResolve,InterviewsGetPageOneResolve,ConsolidationGetAllParcelleResolve,ConsolidationSuccessListGetResolve,
    RecordUnconsolidateGetAllResolve,ConsolidationFailedGetAllResolve,ConsolidationDownloadListGetResolve],
  bootstrap: [AppComponent]
})
export class AppModule { }
