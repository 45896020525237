<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <a href="index.html" class="logo">
                    <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                    <br>
                </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Les prestataires</h4>
                        </div>
                        <div class="col-sm-6">
                                <ol class="breadcrumb float-right">
                                        <li class="breadcrumb-item">INFOCAD</li>
                                        <li class="breadcrumb-item active">Les prestataires</li>
                                </ol>
                           
                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row my-2">
                    <div class="col-md-6 d-flex justify-content-sm-start">
                        <h1 class="page-title">
                                <p style="text-align: right;">
                                        <button (click)="openAddModal(content)" class="btn btn-primary"><i class="fa fa-plus"></i>
                                            Ajouter un nouveau</button>
                                    </p>
                        </h1>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">
                          
                                <form>
                                        <div class="form-group form-inline">
                                            <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                                                [(ngModel)]="searchText" name="searchText" (keyup)="search()" />
                                        </div>
                                </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">
                      
                    </div>
                    <div class="col-md-12">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <div></div>
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>
                                            <th>Désignation</th>
                                            <th>Adresse</th>
                                            <th class="hidden-xs">Email</th>
                                            <th class="hidden-xs">Contact</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
                                            <td>
                                                {{el.designation}}
                                            </td>
                                            <td class="hidden-xs">
                                                <span class="label label-success"> {{el.address}}</span>
                                            </td>
                                            <td class="hidden-xs">
                                                {{el.email}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{el.phone}}
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button (click)="openUpdateModal(contentU,el)" ngbDropdownItem>Modifier</button>
                                                        <button (click)="archive(el.id,i)" ngbDropdownItem>Supprimer</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>

                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->

        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>
    <ng-template #content let-modal>
        <form #newForm="ngForm" (ngSubmit)="create(newForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Nouvel ajout</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <h5>X</h5>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="form-group">
                    <label for="">Désignation</label>
                    <input type="text" class="form-control" ngModel name="designation">
                </div>
                <div class="form-group">
                    <label for="">Email</label>
                    <input type="email" name="email" #email="ngModel" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" ngModel class="form-control">
                    <span *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                        <small *ngIf="email.errors?.pattern">
                            email invalid
                        </small>
                    </span>
                </div>
                <div class="form-group">
                    <label for="">Contact</label>
                    <input type="number" class="form-control" ngModel name="phone">
                </div>
                <div class="form-group">
                    <label for="">Adresse</label>
                    <input type="text" class="form-control" ngModel name="address">
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!newForm.form.valid" type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <ng-template #contentU let-modal>
        <form #updateForm="ngForm" (ngSubmit)="update(updateForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Nouvel modification</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-remove font-size-h3"></i>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="form-group">
                    <label for="">Désignation</label>
                    <input type="text" class="form-control" [(ngModel)]="data_current.designation" name="designation">
                </div>

                <div class="form-group">
                    <label for="">Email</label>
                    <input type="email" name="email"  #email="ngModel"  pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" [(ngModel)]="data_current.email" class="form-control">
                    <span *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                        <small *ngIf="email.errors?.pattern">
                            email invalid
                        </small>
                    </span>
                </div>
                <div class="form-group">
                    <label for="">Contact</label>
                    <input type="number" class="form-control" [(ngModel)]="data_current.phone" name="phone">
                </div>
                <div class="form-group">
                    <label for="">Adresse</label>
                    <input type="text" class="form-control" [(ngModel)]="data_current.address" name="address">
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid" type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->