<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
                <a href="index.html" class="logo">
                    <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                    <br>
                </a>
        </div>

        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Les equipes</h4>
                        </div>
                        <div class="col-sm-6 ">
                                <ol class="breadcrumb float-right">
                                        <li class="breadcrumb-item">INFOCAD</li>
                                        <li class="breadcrumb-item active"><a routerLink="/my-users-manage">Les utilisateurs</a></li>
                                        <li class="breadcrumb-item active">Les equipes</li>
                                </ol>
                         
                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="page-title"></h1>
                    </div>
                    <div class="col-md-6 d-flex justify-content-between">
                            <a routerLink="/validations" class="btn btn-primary mx-1"> Comptes à valider </a>
                            <a routerLink="/my-users-manage" class="btn btn-primary mx-1"> Tous les utilisateurs </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>

                                            <th>Cote</th>
                                            <th>Membres</th>
                                            <th>Localités</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">

                                            <td class="hidden-xs">
                                                <span class="label label-success"> {{el.cote}}</span>
                                            </td>
                                            <td class="hidden-xs">

                                                <small *ngFor="let m of el.members; let ix=index">
                                                    {{m.forename}} {{m.surname}}
                                                    {{ ix==(el.members.length-1) ? '' :','}}

                                                </small>
                                            </td>
                                            <td class="hidden-xs">

                                                <small *ngFor="let m of el.localities; let ix=index">
                                                    {{m.libelle}}
                                                    {{ ix==(el.localities.length-1) ? '' :','}}

                                                </small>
                                            </td>


                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button (click)="openUpdateModal(contentUL,el)" ngbDropdownItem>Assigner
                                                            localités</button>
                                                        <button (click)="openUpdateModalLg(locality,el)"
                                                            ngbDropdownItem>Voir les localités</button>
                                                        <button (click)="openUpdateModal(details,el)" ngbDropdownItem>Voir
                                                            membres</button>

                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>

                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->

        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>
    <ng-template #contentUL let-modal>
        <form #updateWithCheckForm="ngForm" (ngSubmit)="setLocalityWithCheck(updateWithCheckForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Assigner des localités</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <h3>X</h3>
                </button>
            </div>
            <div class="modal-body">

                
                <div class="row col-md-12 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-dark" type="submit">Sauvegarder</button>
                </div>
                <div class="row col-md-12 d-flex justify-content-between">
                    <p>Equipe n°: {{ data_current.cote }}</p>
                    <div class="form-group form-inline">
                        <input class="form-control form-control-sm " placeholder="Rechercher" type="text" [(ngModel)]="searchText"
                            name="searchText" (keyup)="search2()" />
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-md-3">
                        <select  (change)="loadCommunues($event)" name="departement" ngModel class="form-control" id="">
                            <option [value]="el.id" *ngFor="let el of departements">{{el.libelle}}</option>
                         </select>
                       </div>
                        <div class="col-md-3">
                            <select  (change)="loadArrondissement($event)" name="commune" ngModel class="form-control" id="">
                                <option [value]="el.id" *ngFor="let el of communes">{{el.libelle}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <select  (change)="loadLocality($event)" name="arrondissement" ngModel class="form-control" id="">
                                <option [value]="el.id" *ngFor="let el of arrondissements">{{el.libelle}}</option>
                            </select>
                        </div>
                        <div class="col-md-3">
                                <button class="btn btn-sm btn-primary" (click)="reset()">Reinitialiser</button>
                        </div>
                </div>
                <p>
                        
                    <span *ngIf="selected_locs.length!=Zero">Localités selectionner :
                        <small class="badge badge-dark mx-1" *ngFor="let l of selected_locs; let ix=index">
                            {{l.libelle}}
                        </small></span>
                </p>
                <table class="table  table-striped ">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Localité</th>
                            <th>Infos</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let el of localities | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; let i=index">
                            <td>
                                <input type="checkbox"  (change)="onCheck($event)" [(ngModel)]="el.check" [name]="el.id">
                            </td>
                            <td>
                                {{el.libelle}}
                            </td>
                            <td class="hidden-xs">
                                {{el.libelleUnique}}
                            </td>


                        </tr>
                    </tbody>

                </table>
                <ngb-pagination class="" [collectionSize]="collectionSize2" [(page)]="page2" [maxSize]="5" [pageSize]="pageSize2"
                    [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
                </ngb-pagination>
            </div>
            <div class="modal-footer">
            </div>
        </form>
    </ng-template>
    <ng-template #contentU let-modal>
        <form #updateForm="ngForm" (ngSubmit)="setLocality(updateForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Assigner des localités</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-remove font-size-h3"></i>
                </button>
            </div>
            <div class="modal-body">


                <div class="form-group">
                    <label for="">Localités</label>
                    <ng-multiselect-dropdown [placeholder]="'Choisir localité'" [settings]="dropdownSettings" [data]="localities"
                        name="locality" [(ngModel)]="selectedItems" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)">
                    </ng-multiselect-dropdown>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid" type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <ng-template #details let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Membres</h4><br>

            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-remove font-size-h3"></i>
            </button>
        </div>
        <div class="modal-body">
            <p style="text-align: center;">Equipe n°: {{ data_current.cote }}</p>

            <table class="table  table-striped ">
                <thead>
                    <tr>
                        <th>Nom & prenoms</th>
                        <th>Profil</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let el of data_current.members ; let i=index">
                        <td>
                            {{el.forename}} {{el.surname}}
                        </td>
                        <td class="hidden-xs">
                            <span class="label label-success"> {{ el.role==null ? '' : roleService.getRole(el.role)}}</span>
                        </td>
                        <button class="btn btn-outline-danger btn-sm" (click)="archive(el.id,i)">Retirer</button>


                    </tr>
                </tbody>

            </table>

        </div>
        <div class="modal-footer">
        </div>
    </ng-template>
    <ng-template #locality let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Localités</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-remove font-size-h3"></i>
            </button>
        </div>
        <div class="modal-body">


            <table class="table  table-striped ">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Infos</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let el of data_current.localities ; let i=index">
                        <td>
                            {{el.libelle}}
                        </td>
                        <td>{{el.libelleUnique}}</td>
                    </tr>
                </tbody>

            </table>

        </div>
        <div class="modal-footer">
        </div>
    </ng-template>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->