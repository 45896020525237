import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Config } from '../../app.config';
import { catchError, tap, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LocalityService {

  url="api/localities";
  constructor(private http:HttpClient) { }
 

  getAll(){
   
    return this.http.get<any[]>(`${this.url}/cities-or-neighborhoods`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  getDepartement(){
   
    return this.http.get<any[]>(`api/localities/departments`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }
  getLocalitiesIn(num){
   
    return this.http.get<any[]>(`api/localities/in/${num}`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  

  getcommune(){
   
    return this.http.get<any[]>(`api/localities/communes`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  joinTeamAndLocality(localities,team_id){
    return this.http.post<any>(`api/teams/${team_id}/localities/body`, {ids:localities},
     Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`added ressource ${ressource}`))
    );
  }

  getTeamLocalities(team_id){
    return this.http.get<any>(`api/teams/${team_id}/localities`,
    Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
     tap((ressource: any) => console.log(`added ressource ${ressource}`))
   );
    
  }
  getTeamLocalitiesHierachy(team_id){
    return this.http.get<any>(`api/teams/${team_id}/localities/hierarchy`,
    Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
     tap((ressource: any) => console.log(`added ressource ${ressource}`))
   );
    
  }

  get(id){
    return this.http.get<any[]>(`${this.url}/${id}`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  create(ressource){
    return this.http.post<any>(`${this.url}`, ressource,
     Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`added ressource ${ressource}`))
    );
  }
  update(ressource,id){
    return this.http.put<any>(`${this.url}/${id}`, ressource,  Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }
  update_partial(ressource,id){
    return this.http.patch<any>(`${this.url}/${id}`, ressource,  Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }

  delete(id:number){
    return this.http.delete<any[]>(`${this.url}/${id}`,Config.httpHeader());
  }
}
