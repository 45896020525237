
import { Injectable } from "@angular/core";  
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";  
import { Observable } from "rxjs";  
import { Team } from "./../_models/team.model";  
import { TeamService } from "./../_services/team.service";  

@Injectable()

export class TeamGetAllResolve implements Resolve<Team[]>{

    constructor(private dataService: TeamService) {}  

    resolve(route: ActivatedRouteSnapshot): Observable<Team[]> {  
        return this.dataService.getAll();  
      }      
}
