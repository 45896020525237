import { Component, OnInit, Input } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { Config } from './../app.config';
import { AlertNotif } from './../alert';
import { Organization } from './../core/_models/organization.model';
import { OrganizationService } from './../core/_services/organization.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {

  page = 1;
  pageSize = 25;
  searchText = ""
  closeResult = '';
  error = ""
  data: Organization[] = [];
  _temp: Organization[] = [];
  data_current = new Organization()

  selected = [
  ];
  collectionSize = 0;


  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.designation.toLowerCase().includes(term) ||
      r.address.toLowerCase().includes(term) ||
      r.email.toLowerCase().includes(term) ||
      r.phone.toString().toLowerCase().includes(term)

    })
    this.collectionSize = this.data.length
  }


  openUpdateModal(content, el) {
    this.data_current = el
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openAddModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  constructor(
    private organizationService: OrganizationService,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute) { }


  ngOnInit() {
    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')

    this.data = []
    this._temp = []

    this.activatedRoute.snapshot.data.org_list.data
      .forEach((e) => { this.data.push(new Organization(e)) })
    this._temp = this.data
    this.collectionSize = this.data.length

  }

  init(){
    this.data = []
    this._temp = []
    this.organizationService.getAll().subscribe((res: any) => {
      res.data.orEach((e) => { this.data.push(new Organization(e)) })
      this._temp = this.data
      this.collectionSize = this.data.length
    })

  }
  create(value) {

    this.organizationService.create(value).subscribe((res: any) => {
      if (res.code == 201) {
        this.modalService.dismissAll()
       // window.location.reload()
       this.init()
        AlertNotif.finishConfirmWithReload("Nouvel ajout", "Ajout éffectué avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }

    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Nouvel ajour", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Nouvel ajout", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }


  update(value) {

    delete value.email
    this.organizationService.update(value, this.data_current.id).subscribe((res: any) => {
      if (res.code == 200) {
        this.init()

        this.modalService.dismissAll()
        //window.location.reload()

        AlertNotif.finishConfirmWithReload("Nouvelle modification", "Un element modifié avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Nouvelle modification", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }
  enable(id, index) {
    this.organizationService.update_partial(id, { status: true }).subscribe((res: any) => {
      AlertNotif.finish("Nouvelle modification", "Un eelement activer avec succès", 'success')
      this.ngOnInit()
    }, (err) => {
      AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })

  }
  disable(id, index) {
    this.organizationService.update_partial(id, { status: false }).subscribe((res) => {
      AlertNotif.finish("Nouvelle modification", "Un element desactiver avec succès", 'success')
      this.ngOnInit()
    }, (err) => {
      AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })
  }
  archive(id, index) {
    AlertNotif.finishConfirm("Nouvelle suppression",
      "Voulez-vous continuer ?").then((result) => {
        if (result.value) {
          this.organizationService.delete(id).subscribe((res: any) => {
            if (res.code == 200) {
              this.data.splice(index, 1)
              AlertNotif.finish("Nouvelle suppression", "Suppression effectué avec succès", 'success')
            }else{
              AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
            }
          }, (err) => {
            AlertNotif.finish("Nouvelle suppression", "Une erreur est survenue, verifier votre connexion SVP", 'error')
          })
        }
      })
  }



}
