<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                <br>
            </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Consolidation reussies</h4>
                        </div>
                        <div class="col-sm-6 ">
                            <ol class="breadcrumb float-right">
                                <li class="breadcrumb-item">INFOCAD</li>
                                <li class="breadcrumb-item "><a routerLink="/consolidation">Consolidations</a></li>
                                <li class="breadcrumb-item active">Consolidation reussies</li>
                            </ol>


                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row my-2">
                    <div class="col-md-6 d-flex justify-content-start">
                        <h1 class="page-title">
                            <a routerLink="/consolidation" class="btn btn-outline-secondary"> <i class="fa  fa-arrow-left"></i>
                                Retour</a>
                        </h1>
                    </div>

                </div>
                <div class="row ">
                    <div class="col-md-6 card card-body mx-auto">
                        <div class="form-group form-inline" *ngIf="user.organisation==Null">
                            <label class="col-md-3  d-flex justify-content-start">Organisation</label>
                            <select #org="ngModel" name="org" required ngModel id="" class="form-control col-md-9">
                                <option [value]="i" *ngFor="let el of orgs; let i=index">
                                    {{el.designation}} </option>
                            </select>
                            <span *ngIf="org.invalid && (org.dirty || org.touched)" class="text-danger">
                                <small *ngIf="org.errors?.required">
                                    Organisation sont requis
                                </small>
                            </span>
                        </div>
                        <div class="form-group form-inline">
                            <label for="" class="col-md-4 d-flex justify-content-start">Equipe : </label>
                            <select #team="ngModel" name="team" (change)="getLocalitiesLev1($event)" [(ngModel)]="selected_team" id="" class="form-control col-md-8">
                                <option selected disabled>Choisir une équipe</option>
                                <option [value]="el.id" *ngFor="let el of teams; let i=index">{{el.cote}}
                                    (
                                    <small *ngFor="let m of el.members; let ix=index">
                                        {{m.forename}} {{m.surname}}
                                        {{ ix==(el.members.length-1) ? '' :','}}
            
                                    </small>
                                    )
                                </option>
                            </select>
                        </div>
            
                        <div class="form-group form-inline">
                            <label for="" class="col-md-4 d-flex justify-content-start">​ Département : </label>
                            <select #departement="ngModel" ngModel (change)="getLocalitiesLev2($event)" name="departement" id="" class="form-control col-md-8">
                                <option selected disabled>Choisir une localité</option>
                                <option [value]="i" *ngFor="let el of h_departements; let i=index">
                                    {{el.libelle}} </option>
                            </select>
                        </div>
                        <div class="form-group form-inline">
                            <label for="" class="col-md-4 d-flex justify-content-start">​ Commune : </label>
                            <select #commune="ngModel" ngModel (change)="getLocalitiesLev3($event)" name="commune" id="" class="form-control col-md-8">
                                <option selected disabled>Choisir une localité</option>
                                <option [value]="i" *ngFor="let el of h_communes; let i=index">
                                    {{el.libelle}} </option>
                            </select>
                        </div>
                        <div class="form-group form-inline">
                            <label for="" class="col-md-4 d-flex justify-content-start">​ Arrondissement : </label>
                            <select #arrondissement="ngModel" ngModel (change)="getLocalitiesLev4($event)" name="arrondissement" id=""
                                class="form-control col-md-8">
                                <option selected disabled>Choisir une localité</option>
                                <option [value]="i" *ngFor="let el of h_arrondissements; let i=index">
                                    {{el.libelle}} </option>
                            </select>
                        </div>
                        <div class="form-group form-inline">
                            <label for="" class="col-md-4 d-flex justify-content-start">​ Ville/Quartier : </label>
                            <select #locality="ngModel" (change)="filterLocalitiesLev4($event)" name="locality" [(ngModel)]="selected_locality"
                                id="" class="form-control col-md-8">
                                <option selected disabled>Choisir une localité</option>
                                <option [value]="el.id" *ngFor="let el of h_localities; let i=index">
                                    {{el.libelle}} </option>
                            </select>
                        </div>
            
                        <div class="form-group">
                            <button *ngIf="show_reset" (click)="resetFilter()" class=" float-right mx-1 btn btn-secondary">Réinitialiser
                                filtres</button>
            
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-md-12 mx-auto">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>

                                            <th>Village</th>
                                            <th>Equipe</th>
                                            <!--
                                            <th class="hidden-xs">Etat consolidation</th>
                                            -->
                                            <th class="hidden-xs">Creer le</th>
                                            <th class="hidden-xs">Nombre parcelle</th>
                                            <th class="hidden-xs">Nombre parcelle echec</th>
                                            <th>Etat shapefile</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">

                                            <td>
                                                {{el.localityWs.libelle}}
                                            </td>
                                            <td>
                                                {{el.teamOutWs.cote}}
                                                (
                                                <small *ngFor="let m of el.teamOutWs.members; let ix=index">
                                                    {{m.forename}} {{m.surname}}
                                                    {{ ix==(el.teamOutWs.members.length-1) ? '' :','}}

                                                </small>
                                                )
                                            </td>

                                            <td class="hidden-xs text-semi-muted">
                                                {{el.orthers.createdAt| date:'short'}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{el.orthers.parcelTotal}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{el.orthers.parcelConsolidatedFailed}}
                                            </td>
                                            <td class="hidden-xs">

                                                <span *ngIf="el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==Zero"
                                                    class="badge badge-primary">
                                                    Persisté</span>
                                                <span *ngIf="el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne"
                                                    class="badge badge-danger">
                                                    Echec de consolidation</span>
                                                <span *ngIf="el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==One"
                                                    class="badge badge-success">
                                                    Consolidation</span>
                                                <span *ngIf="el.orthers.uploaded!=One" class="badge badge-dark">
                                                    Attente de upload</span>
                                            </td>

                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button *ngIf="el.orthers.persisted==One" (click)="download(el.orthers.session)"
                                                            ngbDropdownItem>Download</button>

                                                        <!--
                                                        <button (click)="openshowInfosModal(infos,el.session)" ngbDropdownItem>Voir details</button>

                                                            <a [routerLink]="['/consolidation-files',el.session]" ngbDropdownItem>Voir fichiers</a>
                                                        -->
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->
            <ng-template #upload let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Charger fichiers</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <i class="fa fa-remove font-size-h3"></i>
                    </button>
                </div>
                <div class="modal-body">



                </div>
                <div class="modal-footer">
                </div>
            </ng-template>



        </div>
        <!-- content -->

        <app-footer>
        </app-footer>

    </div>


    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->