<!-- Begin page -->
<div id="wrapper">

    <div class="row justify-content-center mt-4">
        <div class="col-sm-12 col-md-4 col-lg-4"></div>
        <div class="col-sm-12 col-md-4 col-lg-4 mt-5">
            <p style="text-align: center; margin-bottom: 15px">
                <img src="./assets/img/official_logo_finance.png" width="200" alt="">
                <img src="./assets/img/andf-logo.png" height="90" alt="">
            </p>
            <h5 class="widget-login-logo d-flex justify-content-center animated fadeInUp">
                <i class="fa fa-circle text-gray" style="color: green"></i>
                INFOCAD
                <i class="fa fa-circle text-warning" style="color: yellow"></i>
            </h5>
            <section class="card widget-login animated fadeInUp">

                <div class="card-body">
                    <header class="text-center mt-3 mb-5">
                        <h6><b>Connectez-vous à votre espace</b></h6>
                    </header>
                    <form class="login-form mt-lg" #loginForm="ngForm" (ngSubmit)="submit(loginForm.value)">
                        <div *ngIf="error" class="alert alert-danger">
                            {{error}}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span
                                    aria-hidden="true"> ×</span> </button>
                        </div>
                        <div class="form-group">
                            <input ngModel name="email" #email="ngModel" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                required type="text" class="form-control" id="exampleInputEmail1" placeholder="Email">
                            <span *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger">
                                <small *ngIf="email.errors?.required">
                                    email est requis
                                </small>
                                <small *ngIf="email.errors?.pattern">
                                    email invalid
                                </small>
                            </span>
                        </div>
                        <div class="form-group">
                            <input ngModel name="password" #password="ngModel" required class="form-control" id="pswd"
                                type="password" placeholder="Mot de passe" required><span toggle="#pswd" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                            <span *ngIf="password.invalid && (password.dirty || password.touched)" class="text-danger">
                                <small *ngIf="password.errors?.required">
                                    Mot de passe requis
                                </small>
                            </span>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-md-push-6">

                            </div>

                            <div class="col-md-6 col-md-pull-6">
                                <a class="mr-n-lg float-right" href="javascript: void(0)" (click)="notifyUser()">Mot de
                                    passe oublié?</a>
                            </div>
                        </div>
                        <div class="clearfix form-group mt-4 mb-2">
                            <div class="btn-toolbar d-flex justify-content-center">
                                <button [disabled]="loginForm.invalid" class="btn btn-success btn" type="submit"><b>
                                        Se connecter
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                            *ngIf="loading"></span>
                                    </b></button>
                            </div>
                        </div>
                        <br>

                    </form>
                </div>
            </section>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4"></div>
    </div>

    <footer class=" footer footer-auth ">
        2020 &copy; ANDF-PMAF Powered by <a href="https://www.hesystems-group.com/" target="_blank">HE SYSTEM</a>.
   </footer>
</div>