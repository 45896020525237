import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Config } from '../../app.config';
import { catchError, tap, map, delay } from 'rxjs/operators';
import { Team } from '../_models/team.model';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TeamService {

  url="api/teams";
  constructor(private http:HttpClient) { }


  getAll(): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.url}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));
  }

  getAllByOrganization(orgId): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.url}` + "?organisationId=" + orgId, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));
  }

  /*getAllTeamMembres(team_id){

    return this.http.get<any[]>(`${this.url}/${team_id}/members`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }*/

  addTeamMember(members,team_id,org_id){
    if(team_id=="null" || team_id==null){
      return this.http.post<any>(`${this.url}/body`,{
        ids:members
      },
       Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
        tap((ressource: any) => console.log(`added ressource ${ressource}`))
      );
    }else{
      return this.http.post<any>(`${this.url}/body?team=${team_id}`,{
        ids:members
      },
       Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
        tap((ressource: any) => console.log(`added ressource ${ressource}`))
      );
    }

  }

  deleteTeamMember(user_id){

   return this.http.delete<any[]>(`api/users/${user_id}/delete/on/team`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  get(id){

    return this.http.get<any[]>(`${this.url}/${id}`, Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  create(ressource){
    return this.http.post<any>(`${this.url}`, ressource,
     Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`added ressource ${ressource}`))
    );
  }
  update(ressource,id){
    return this.http.put<any>(`${this.url}/${id}`, ressource,  Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }
  update_partial(ressource,id){
    return this.http.patch<any>(`${this.url}/${id}`, ressource,  Config.httpHeader(localStorage.getItem("userToken"),true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }

  delete(id:number){
    return this.http.delete<any[]>(`${this.url}/${id}`,Config.httpHeader());
  }
}
