import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Config } from '../../app.config';
import { catchError, tap, map, delay } from 'rxjs/operators';
import { Record } from '../_models/interview.model';

@Injectable({
  providedIn: 'root'
})
export class RepertoriesService {

  url = "api/records/report";
  constructor(private http:HttpClient) { }

  getReport(localityId): Observable<any[]> {

    return this.http.get<any[]>(this.url+`/${localityId}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));

  }

  filter(localityId,defaultFilter,orgId){
    return this.http.get<any[]>(`api/records/report/${localityId}/filtered-by/${defaultFilter}/about-org/${orgId}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));
  }

  filterReport(ressource){
    return this.http.post<any[]>(`api/records/report/filtered`, ressource, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    })
  }
  filtrer(orgId,statOnly,body){
    return this.http.post<any[]>(`api/records/report/filtered/about-org/${orgId}/with-start-only/${statOnly}`, body, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));
  }

  download(extension): any{

    return this.http.get(`api/shapefiles/download/report?extension=${extension}`,{//?session=${session}
      responseType: 'blob' as 'json', 
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));

  }
}

