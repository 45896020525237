import { AuthService } from '../core/_services/auth/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Component, Inject, NgZone, PLATFORM_ID, OnInit, ViewChild, ElementRef, Directive, OnDestroy, Output, HostListener } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import {
  EventEmitter,
} from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime, buffer, filter, map } from "rxjs/operators";

import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import Circle from 'ol/geom/Circle';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import Point from 'ol/geom/Point';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Text,
} from 'ol/style';
import Select from 'ol/interaction/Select';
import { altKeyOnly, click, pointerMove } from 'ol/events/condition';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { InterviewService } from '../core/_services/interview.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalityService } from '../core/_services/locality.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { Team } from '../core/_models/team.model';
import { TeamService } from '../core/_services/team.service';
import { OrganizationService } from '../core/_services/organization.service';
import { RoleService } from '../core/_services/role.service';
import { Organization } from '../core/_models/organization.model';
import { AlertNotif } from './../alert';


declare var $: any;


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  map: Map<any>;
  closeResult = ""
  current_feature_data: any
  mapVillageLoading = false
  selected_village = ""
  Vide = ""
  Null = null
  Zero=0
  nodata=false
  False=false
  True=true
  current_bool=false
  constructor(private modalService: NgbModal, private localityService: LocalityService,
    private organisationService:OrganizationService,public roleService:RoleService,
    private router:Router,private localService:LocalService,private teamService:TeamService,
    private activatedRoute: ActivatedRoute, private interviewService: InterviewService) { }

  openAddModal(content, status) {
    this.current_bool=status
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  dropdownList = [];
  villages = []
  shapefiles = []
  show_filter=false
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  localitiessSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  Subject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])


  initMap(geoJson, current_lat, current_long) {

    var polygonStyleFunction = new Style({
      stroke: new Stroke({
        color: 'blue',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(122, 122, 122, 0.1)',
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'middle',
        font: '11px Verdana',
        // text: feature.get('parcelleno'),
      }),
    })

    var format = new GeoJSON({
      featureProjection:"EPSG:32631"
      });

    var vectorSource = new VectorSource({
      // features: [marker],
      format: new GeoJSON(),
      loader: function (extent, resolution, projection) {
        vectorSource.addFeatures(format.readFeatures(geoJson));
      },
    });
    // vectorSource.removeLoadedExtent(extent);


    var vectorLayer = new VectorLayer({
      source: vectorSource,
      style: function (feature) {
        polygonStyleFunction.getText().setText(feature.get('parcelleno'));
        return polygonStyleFunction;
      },
    });

    // View and map
    var view = new View({
      center: [current_lat, current_long],
      zoom: 15.5

    });

    /*new XYZ({
      url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    })*/
    this.map = new Map({
      target: 'chartdiv',
      layers: [
        new TileLayer({
          source: null
        }), vectorLayer],
      view: view
    })
  }
  stat:any={consolidated:0,total:0,socioTotal:0,socioConsolidate:0}
  teams=[]
  orgs=[]
  current_localities=[]
  departements=[]
  geojsonObject: any
  user:any

  ngOnInit(): void {
    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')
    this.user = this.localService.getJsonValue("userData")
    // var geojsonObject=this.activatedRoute.snapshot.data.data_list.data

    var geojsonObject = [];

    this.interviewService.getAllParcelle().subscribe((result: any) => {
      console.log("In interview test")
      this.geojsonObject = result.data
      this.show_filter=true
      this.nodata=true
      //this.initMap(this.geojsonObject, 270988.2203185922, 709107.9883876308)

    })

    /*  setTimeout(function(){
      this.show_filter=true
    },30000)
      if(this.localService.getJsonValue('userData').organisation!=undefined && this.localService.getJsonValue('userData').organisation!=null){
      this.interviewService.getStat(this.localService.getJsonValue('userData').organisation.id,
      true
      ).subscribe((result: any) => {
        this.stat = result.data
      })
    }

    */

   this.interviewService.getAllFilterStatistic(null,null,
      this.user.organisation==null ? null :  this.user.organisation.id).subscribe((result: any) => {
    this.stat = result.data.statistic;
    this.geojsonObject  = [];
  //  this.geojsonObject = result.data.list
//    this.mapVillageLoading = false
   // this.initMap(this.geojsonObject, this.geojsonObject.features[0].geometry.coordinates[0][0][0], this.geojsonObject.features[0].geometry.coordinates[0][0][1])

  })

    this.shapefiles = []
    this.interviewService.downloadList().subscribe((result: any) => {
      result.data.forEach((e) => {
        if (e.consolidated != -1) {
          this.shapefiles.push(e)
        }
      })
    })
    this.interviewService.getAllParcelleFilterKey("villagenam").subscribe((res: any) => {
      this.villages = res.data
    })

    this.teams=[]
    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => { this.teams.push(new Team(e)) })
      //this.users=res.data
    })
    this.orgs=[]
    this.organisationService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => { this.orgs.push(new Organization(e)) })
      //this.users=res.data
    })

    this.departements = []
    this.localityService.getDepartement().subscribe((res: any) => {
      this.departements = res.data
    })
  }

  reinitOrg(event){
    this.init(event.target.value)
  }

  init(org){
    this.teams = []
    this.teamService.getAllByOrganization(org).subscribe((res: any) => {
      res.data.forEach((e) => {
        this.teams.push(new Team(e))
      })
    })
  }




  selected_team=""
  selected_locality:any
 loadLocalities(event) {
     this.selected_team=this.teams[event.target.value].id
     this.current_localities = []
     this.current_localities = this.teams[event.target.value].localities
  }

  loadShapeFiles(session) {
    this.interviewService.shapefilesDetails(session,"all").subscribe((res: any) => {
      console.log(res)
    });

  }

  ShapeFilesFilter(event) {

    let shape = this.shapefiles[event.target.value]
    /*this.interviewService.getAllParcelleFilterKeyValue("session", shape.session).subscribe((res: any) => {

      this.initMap(res.data, 0, 0)
    });*/

  }

  reset(){

  }
  villageSubmit(value) {
    console.log("In village ubmit")
    this.mapVillageLoading = true
    this.selected_locality=this.current_localities[+value.locality]
    this.nodata=false
    $('#chartdiv').html('')
    if(this.current_bool){
      this.interviewService.getAllFilterStatistic(this.selected_team, this.selected_locality!=undefined && this.selected_locality!=null ? this.selected_locality.id : null,
        this.user.organisation==null ? value.org :  this.user.organisation.id).subscribe((result: any) => {
        this.stat = result.data.statistic
        this.geojsonObject = result.data.list
        if(result.data.list==null  || result.data.list.length==0){
          this.nodata=true
        }
        this   .mapVillageLoading = false
        this.modalService.dismissAll()
        this.initMap(this.geojsonObject, this.geojsonObject.features[0].geometry.coordinates[0][0][0], this.geojsonObject.features[0].geometry.coordinates[0][0][1])
      })
    }else{
      this.interviewService.getAllFilterStatistic(this.selected_team, this.selected_locality!=undefined && this.selected_locality!=null ? this.selected_locality.id : null,
        this.user.organisation==null ? null :  this.user.organisation.id).subscribe((result: any) => {
        this.stat = result.data.statistic

        console.log("--------------------------------------")
        console.log(result.data.list)
        this.geojsonObject = result.data.list
        if(result.data.list==null || result.data.list.length==0){
          this.nodata=true
        }
        this.mapVillageLoading = false
        this.modalService.dismissAll()
        if((typeof this.geojsonObject === 'undefined') || this.geojsonObject == null || (typeof this.geojsonObject.features === 'undefined') || this.geojsonObject.features == null){
          AlertNotif.finish("Statistique", "Pas de parcelles validées et consolidées pour ce filtre", 'warning');
        }else{
          this.initMap(this.geojsonObject, this.geojsonObject.features[0].geometry.coordinates[0][0][0], this.geojsonObject.features[0].geometry.coordinates[0][0][1])
        }
      })

    }

    this.mapVillageLoading = false
  }

  isConsolidatedParcel(id_socio){
    console.log("------------------------- id_socio = " + id_socio)
    return id_socio != null && id_socio != 0;
  }

  getCoord(event: any, infos) {
    var feature_data = null
    var coordinate = this.map.getEventCoordinate(event);
    console.log(this.map.getEventPixel(event))
    this.map.forEachFeatureAtPixel(this.map.getEventPixel(event), function (feature, layer) {
      /*  var objeto = feature.getProperties(),propiedades;
        console.log(objeto)
       for (propiedades in objeto) {
      console.log( propiedades,  objeto[propiedades])
      }*/
      feature_data = feature.getProperties()
      console.log(feature_data)
    });
    console.log(coordinate, this.map.getEventPixel(event))
    if (feature_data != null) {
      this.current_feature_data = feature_data
      this.modalService.dismissAll()
      this.openAddModal(infos,true)

      /*this.interviewService.getParcelleInfoByParcelleNumber(feature_data.parcelleno).subscribe((res: any) => {
        this.current_feature_data=res.data
        //this.current_feature_data = feature_data

      });*/
    }

    console.log(feature_data)

  }

  viewRecord(id){
    this.modalService.dismissAll()
    this.router.navigate(['/detail-interviews',id])

  }
  viewExtrait(no, ref){
    this.modalService.dismissAll()
    this.router.navigateByUrl('/extrait-cadastral/'+ref+"/"+no)

  }

}


