import { Component, OnInit, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { InterviewService } from '../core/_services/interview.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { TeamService } from '../core/_services/team.service';
import { UserService } from '../core/_services/user.service';
import { AlertNotif } from '../alert';
import { Team } from '../core/_models/team.model';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as fileSaver from 'file-saver';
import { LocalityService } from '../core/_services/locality.service';
import { async } from 'q';
import { OrganizationService } from '../core/_services/organization.service';
import { Organization } from '../core/_models/organization.model';
import {NgbdSortableHeader, SortEvent} from '../core/_directives/sortable.directive';

declare var $: any;


@Component({
  selector: 'app-consolidation',
  templateUrl: './consolidation.component.html',
  styleUrls: ['./consolidation.component.css']
})
export class ConsolidationComponent implements OnInit {

  @ViewChild('errReport') errReport:ElementRef;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(private inverviewService: InterviewService,
    private http: HttpClient, private localityService: LocalityService,
    private userService: UserService, private router: Router,private organisationService:OrganizationService,
    private teamService: TeamService, private activatedRoute: ActivatedRoute,
    private localService: LocalService, private modalService: NgbModal, ) { }
  files: any[] = []
  session = ""
  page = 1
  pageSize = 25
  page2 = 1
  pageSize2 = 25
  collectionSize2 = 0
  page3 = 1
  pageSize3 = 25
  collectionSize3 = 0
  Admin = "ADMIN"
  Responsable = "RESPONSABLE"
  Superviseur = "SUPERVISEUR"
  INVITE = "INVITE"
  GESTIONNAIREDEDATA = "GESTIONNAIREDEDATA"
  ENQUETEURTOPO = "ENQUETEURTOPO"
  ENQUETEURSOCIO = "ENQUETEURSOCIO"
  closeResult = ""
  current_role = ""
  data_current: any
  data_infos: any = { total: 0, failed: 0, status: "", code: 0 }
  underOne = -1
  One = 1
  Zero = 0
  uploaded = false;
  socio_data: any
  topo_data: any
  searchText = ""
  Null=null
  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.localityWs.libelle.toLowerCase().includes(term) ||
      r.teamOutWs.cote.toString().toLowerCase().includes(term) ||
      r.orthers.parcelTotal.toString().toLowerCase().includes(term) ||
      r.orthers.parcelConsolidatedFailed.toString().toLowerCase().includes(term)
    })
    this.collectionSize = this.data.length
  }

  openAddModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openshowInfosModal(content, session) {

    this.inverviewService.shapefilesData(session).subscribe((res: any) => {
      this.data_infos = res.data
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
  }
  openReUploadModal(content, session) {
    this.session = session
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openUpdateModal(content, el) {
    this.data_current = el
    //  let team=this.teams.find((t) =>(t.cote==this.data_current.team))
    var value = { team_cote: this.data_current.team, email: this.data_current.email }
    this.inverviewService.consolidationInit(value).subscribe((res: any) => {
      console.log(res)
      this.session = res.data
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })

  }
  list_files: any[] = []
  openFilesModal(content, el) {
    this.list_files = []
    this.inverviewService.downloadList().subscribe((res: any) => {
      this.list_files = res.data
      console.log(res)
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  user: any
  data: any[] = []
  users: any[] = []
  teams: any[] = []
  current_localities: any[] = []
  _temp: any[] = []
  collectionSize = 0
  localities=[]
  departements = []
  orgs=[]
  current_teams = []
  filter_teams = []
  err_report:any
  filter_localities: any[] = []

  ngOnInit() {
    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')


    if (localStorage.getItem('userRole') != null) {
      this.current_role = localStorage.getItem('userRole')
    }
    this.user = this.localService.getJsonValue("userData")
    this.data = []
    this._temp = []

    let stack=[]
    this.localities=[]
    this.teams=[]
    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        if(e.localities!=null){
          e.localities.forEach((l) => {
            if(!stack.includes(l.id)){
              stack.push(l.id)
              this.localities.push(l)
              this.filter_localities.push(l)
            }
           })
        }
        this.teams.push(new Team(e))
      })

      //this.users=res.data
    })



    this.data = this.activatedRoute.snapshot.data.data_list.data


    this._temp = this.data
    this.collectionSize = this.data.length

    this.orgs=[]
    this.organisationService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => { this.orgs.push(new Organization(e)) })
      //this.users=res.data
    })

    this.users = []
    this.userService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        //&& e.organisation.id==this.user.organisation.id
        if (e.validate && e.role == this.ENQUETEURTOPO) {
          this.users.push(e)
        }
      })
      //this.users=res.data
    })
    this.departements = []
    this.localityService.getDepartement().subscribe((res: any) => {
      this.departements = res.data
    })


  }

  selected_locality=""
  selected_team=""
  show_reset=false
  resetFilter(){
    this.data=[]
    this.data=this._temp
    this.collectionSize = this.data.length
    this.current_teams=[]
    this.h_arrondissements=[]
    this.h_communes=[]
    this.h_departements=[]
    this.h_localities=[]
    this.selected_locality=""
    this.selected_team=""
    this.show_reset=false
  }



  h_departements=[]
  getLocalitiesLev1(event){
    this.h_departements=[]
    this.h_arrondissements=[]
    this.h_communes=[]
    this.h_localities=[]
    this.localityService.getTeamLocalitiesHierachy(event.target.value).subscribe((res: any) => {
      if(res.code==200){
        this.h_departements=res.data
        if(this.h_departements.length!=0){
          this.h_communes=this.h_departements[0].child
        }
        if(this.h_communes.length!=0){
        this.h_arrondissements=this.h_communes[0].child
        }
        if(this.h_arrondissements.length!=0){
        this.h_localities=this.h_arrondissements[0].child
        }
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }

    })


    this.show_reset=true
    let res=[]
    res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.teamOutWs==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length
  }

  h_communes=[]
  getLocalitiesLev2(event){
    this.h_communes=[]
    this.h_communes=this.h_departements[+event.target.value].child
    this.h_arrondissements=[]
    this.h_localities=[]
  }

  h_arrondissements=[]
  getLocalitiesLev3(event){
    this.h_arrondissements=[]
    this.h_arrondissements=this.h_communes[+event.target.value].child
    this.h_localities=[]
  }

  h_localities=[]
  getLocalitiesLev4(event){
    this.h_localities=[]
    this.h_localities=this.h_arrondissements[+event.target.value].child
  }
  filterLocalitiesLev4(event){
    this.show_reset=true
    let res=[]
    res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.localityWs.id==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length

  }


  loadTeam(event) {
    this.filter_teams=[]
    this.teams.forEach((e) => {
      if(e.localities!=null){
        e.localities.forEach((l) => {
          if (l.id == +event.target.value) {
            this.filter_teams.push(e)
          }
        })
      }
    })

    this.show_reset=true
    let res=[]
    res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.localityWs.id==event.target.value) {
        this.data.push(d)
      }
    })
    console.log(this._temp)
    console.log(this.data)
    this.collectionSize = this.data.length
    //  this.current_localities = []
    //this.current_localities = this.teams[event.target.value].localities
  }



  filterByTeam(event){
    let res=[]
    res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.teamOutWs.id==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length
  }

  NewConsolidationloadTeam(event) {
    this.current_teams=[]
    this.teams.forEach((e) => {
      if(e.localities!=null){
        e.localities.forEach((l) => {
          if (l.id == +event.target.value) {
            this.current_teams.push(e)
          }
        })
      }
    })

  /*  this.show_reset=true
    let res=[]
    res=this.data
    this.data=[]
    res.forEach((d) => {
      if (d.localityWs.id==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length*/
    //  this.current_localities = []
    //this.current_localities = this.teams[event.target.value].localities
  }
  /* loadLocalities(event) {
     this.current_l ocalities = []
     this.current_localities = this.teams[event.target.value].localities
   }
  loadTeam(event) {

    this.teams.forEach((e) => {
      e.localities.forEach((l) => {
        if (l.id == +event.target.value) {
          this.current_teams.push(e)
        }
      })
    })
    //  this.current_localities = []
    //this.current_localities = this.teams[event.target.value].localities
  }

  communes = []
  loadCommunues(event) {
    this.communes = []
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.communes = res.data
    })
  }

  arrondissements = []

  loadArrondissement(event) {
    this.arrondissements = []
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.arrondissements = res.data
    })
  }
  localities = []
  loadLocality(event) {

    this.localities = []
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.localities = res.data
    })
  }
  */
  download(session) {
    this.inverviewService.download(session).subscribe(response => {
      let blob: any = new Blob([response], { type: 'application/zip; charset=utf-8' });
      const url = URL.createObjectURL(blob);
      //window.open(url);
      //window.location.href = response.url;
      fileSaver.saveAs(blob, 'shapefiles.zip');
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
    //'application/zip'
  }


  consolidationManuel(session) {
    this.inverviewService.consolidationManuel(session).subscribe((res: any) => {
      if (res.code == 200) {
        AlertNotif.finishConfirmWithReload("Consolidation manuelle", "Terminer", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }

  echecTopo(content, session) {
    this.session = session
    this.inverviewService.shapefilesDetails(session, "all").subscribe((res: any) => {
      this.topo_data = res.data
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
  }
  echecSocio(content, team) {
    this.inverviewService.echecsocio(team).subscribe((res: any) => {
      this.socio_data = res.data
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: "lg" }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
  }
  initOnly(ressource) {
    console.log(+ressource.user)
    let user = this.users[+ressource.user]
    var value = { team_cote: user.team, email: user.email }
    this.inverviewService.consolidationInit(value).subscribe((res: any) => {
      this.session = res.data.session
      if (res.code == 200) {
        // if(this.files.size == 0){
        //   AlertNotif.finish("Erreur", "Veuillez charger les fichiers", 'error')
        // }
        this.modalService.dismissAll()

        this.router.navigate(['/consolidation-files', this.session])
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }
  init(ressource) {
    console.log(+ressource.user)
    this.uploaded = true
    // let user=this.users[+ressource.user]

    var value = { team_id:ressource.team, locality_id: ressource.locality, organization_id: this.user.organisation==null ? value.org :  this.user.organisation.id }
    this.inverviewService.consolidationInit(value).subscribe((res: any) => {
    
      if (res.code == 200) {
        this.session = res.data.session
        // if(this.files.size == 0){
        //   AlertNotif.finish("Erreur", "Veuillez charger les fichiers", 'error')
        // }
        var formData = new FormData()
        this.files.forEach((e) => {
          formData.append('files', e)
        })
        if (this.files.length > 0) {
          var forced=ressource.forced=="" || ressource.forced==null || ressource.forced==undefined ? false : true
          this.inverviewService.consolidationUpload(this.session, formData,forced).subscribe((reslt:any) => {
            console.log(reslt)
            this.uploaded = false
            this.modalService.dismissAll()

        //    location.reload()
          if(reslt.code==500 && reslt.status=="failed-report"){
            let out=reslt.data.treatment.out
            this.err_report={
              poly:( !(out.exist_poly=={}  || out.exist_poly.pass) ? 'Oui' : 'Non'),
              creux: !out.poly_creux.pass ? 'Oui' : 'Non',
              superpose: !out.poly_superpose.pass ? 'Oui' : 'Non'
            }
            this.openAddModal(this.errReport)
           /* AlertNotif.finishConfirmWithReload("Rapport d'erreur", `Parcelles supperposées = ${out.poly_superpose.pass ? 'Oui' : 'Non'},
            Parcelles creux = ${out.poly_creux.pass ? 'Oui' : 'Non'},
            Même numéro parcelle  = ${ out.exist_poly=={}  || out.exist_poly.pass}`, 'success')*/
            }

        if(reslt.data.treatment == null){
          AlertNotif.finishConfirmWithReload("Fichier uploader", "Oups... Veuillez réessayer dans un instant", 'error');
        }

          if(reslt.data.treatment.msg=="CONSOLIDATE_REPORT"){
            AlertNotif.finishConfirmWithReload("Rapport d'erreur", `Nombre de parcels ayant de paire = ${reslt.data.treatment.out.pairedParcel},
            Nombre de données ayant de paire = ${reslt.data.treatment.out.pairedSocioRecord},
            Nombre total de parcels contenu dans le shapefiles = ${reslt.data.treatment.out.totalParcel},
            Nombre parcels pour cette equipe au niveau des enregistrements socio = ${reslt.data.treatment.out.totalSocioRecord}`, 'success')
          }else{
            AlertNotif.finishConfirmWithReload("Fichier uploader", "Les fichiers ont été envoyés", 'success')
          }
          AlertNotif.finishConfirmWithReload("Fichier uploader", "Les fichiers ont été envoyés", 'success')
          })
        } else {
          this.uploaded = false
        }

      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }

  replace(ressource){
    this.uploaded = true

    var formData = new FormData()
    this.files.forEach((e) => {
      formData.append('files', e)
    })
    if (this.files.length > 0) {
      var forced=ressource.forced=="" || ressource.forced==null || ressource.forced==undefined ? false : true
      this.inverviewService.replaceConsolidationUpload(this.session, formData,forced).subscribe((reslt:any) => {
        console.log(reslt)
        this.uploaded = false
        this.modalService.dismissAll()
        if(reslt.code==500 && reslt.status=="failed-report"){
          let out=reslt.data.treatment.out
          this.err_report={
            poly:( !(out.exist_poly=={}  || out.exist_poly.pass) ? 'Oui' : 'Non'),
              creux: !out.poly_creux.pass ? 'Oui' : 'Non',
              superpose: !out.poly_superpose.pass ? 'Oui' : 'Non'
          }
          this.openAddModal(this.errReport)
         /* AlertNotif.finishConfirmWithReload("Rapport d'erreur", `Parcelles supperposées = ${out.poly_superpose.pass ? 'Oui' : 'Non'},
          Parcelles creux = ${out.poly_creux.pass ? 'Oui' : 'Non'},
          Même numéro parcelle  = ${ out.exist_poly=={}  || out.exist_poly.pass}`, 'success')*/
        }
        if(reslt.data.treatment == null){
          AlertNotif.finishConfirmWithReload("Fichier uploader", "Oups... Veuillez réessayer dans un instant", 'error');
        }
      if(reslt.data.treatment.msg=="CONSOLIDATE_REPORT"){
        AlertNotif.finishConfirmWithReload("Rapport d'erreur", `Nombre de parcels ayant de paire = ${reslt.data.treatment.out.pairedParcel},
        Nombre de données ayant de paire = ${reslt.data.treatment.out.pairedSocioRecord},
        Nombre total de parcels contenu dans le shapefiles = ${reslt.data.treatment.out.totalParcel},
        Nombre parcels pour cette equipe au niveau des enregistrements socio = ${reslt.data.treatment.out.totalSocioRecord}`, 'success')
      }else{
        AlertNotif.finishConfirmWithReload("Fichier uploader", "Les fichiers ont été envoyés", 'success')
      }
      AlertNotif.finishConfirmWithReload("Fichier uploader", "Les fichiers ont été envoyés", 'success')
      }
      )
    } else {
      this.uploaded = false
    }
  }

  rapportErreur(session){
    this.inverviewService.errorReport(session,null).subscribe((reslt:any) => {
      if(reslt.code===200){
        if(reslt.detail=="data not found"){
          AlertNotif.finishConfirmWithReload("Rapport d'erreur", `Oups, aucune donnée trouver`, 'success')
        }else{
          this.router.navigateByUrl('/error-report/'+session)
        }
      }
      if(reslt.code===100){
        AlertNotif.finishConfirmWithReload("Rapport d'erreur", `Pas de rapport`, 'success')

      }
      if(reslt.code==500 && reslt.status=="failed-report"){
        AlertNotif.finishConfirmWithReload("Rapport d'erreur", `Parcelles supperposées = ${reslt.data.treatment.out.poly_superpose.pass ? 'Oui' : 'Non'},
       Parcelles creux = ${reslt.data.treatment.out.poly_creux.pass ? 'Oui' : 'Non'},
       Même numéro parcelle  = ${ reslt.data.treatment.out.exist_poly=={}  || reslt.data.treatment.out.exist_poly.pass}`, 'success')
      }
    })
  }


  onFileChange(event) {
    this.files = [];
    if (event.target.files.length > 0) {
      let temp = [...Object.entries(event.target.files)].reverse()
      //this.files=event.target.files
      temp.forEach((e) => {
        this.files.splice(0, 0, e[1])
      })
        console.log(this.files)
    }
  }

  refresh_page() {
    window.location.reload();
  }

  loadFile(value) {

   /* this.inverviewService.consolidationUpload(this.session, this.files).subscribe((res) => {
      console.log(res)
    })*/
  }

}
