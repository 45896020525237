import { Component, OnInit } from '@angular/core';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { LocalityService } from '../core/_services/locality.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-select-locality',
  templateUrl: './select-locality.component.html',
  styleUrls: ['./select-locality.component.css']
})
export class SelectLocalityComponent implements OnInit {
  page2 = 1;
  pageSize2 = 25;
  localitiessSubject:BehaviorSubject <any[]> = new BehaviorSubject<any[]> ([])
  localities: any
  collectionSize2 = 0;
  _temp2: any[]=[];
  loading:any
  searchText=""
  selectedValue: any;

  constructor(
    private localityService: LocalityService,
    private router:Router) { }
  ngOnInit(): void {
    this.loading = true
    console.log(this.loading)
    this.localityService.getAll().subscribe((res:any)=>{ 
      let temp=[]
       res.data.forEach((e)=>{
         e.check=false
         temp.push(e)
        })    
       this.localitiessSubject.next(temp)
      console.log(res.data)
      this.loading = false
      console.log(this.loading)
     })
     
     this.localitiessSubject.asObservable().subscribe((data) => {
       this.localities=[]
       
        this.localities=data
        this._temp2=this.localities
        this.collectionSize2=this.localities.length
        //this.loading = false
        console.log(this.localities)
        console.log(this.loading)
      });
  }
  search2(){ 
    this.localities=this._temp2.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.libelle.toLowerCase().includes(term)
    })
    this.collectionSize2=this.localities.length
  }
  selectLocality(){    
    console.log(this.selectedValue)
    // Appeler l'url de rapport avec la localité sélectionnée
    this.router.navigate(["/repertories/"+this.selectedValue ]);
  }

}
