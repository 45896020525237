<div class="content-page">
    <!-- Start content -->
    <div class="content" style="text-align: center;margin: auto;" style="@page {size:A3 landscape;}">
        <div class="mb-4 mt-2">
            <img src="./assets/img/official_logo_finance.png" width="200"alt="" class="float-left">
            <img src="./assets/img/andf-logo.png" width="90" alt="" class="float-right">
        </div>
        <br><br>
        <div class="container-fluid" id="content" #content>
            <div class="row" style="text-align: center;">
                <div class="col-12">
                    <h5>REPERTOIRE NUMERIQUE DES AYANTS DROIT DU VILLAGE/QUARTIER <span style="font-weight: bold;">{{locality!=null?locality.libelle:"-"}}</span></h5>
                </div>
                <br>
            </div>
            <div class="row" style="text-align: center;">
                <div class="col-12">
                    <h5 style="text-align: center;"><span style="font-weight: bold;">{{locality!=null?locality.libelleUnique:"-"}}</span></h5>
                </div>
            </div><br>
            <!-- <input type="button" value="Imprimer" class="btn btn-success float-left mb-2" (click)="downloadPdf()" > -->
            <table class="table center" style="margin-left: auto;margin-right: auto;" border="1">
                <thead>
                  <tr>
                    <th scope="col">N°Parc.</th>
                    <th scope="col">Code Parc.</th>
                    <th scope="col">Sup.(m²)</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Prénoms</th>
                    <th scope="col">Nationalité</th>
                    <th scope="col">Age</th>
                    <th scope="col">Sexe</th>
                    <th scope="col">Domicile</th>
                    <th scope="col">Telephone</th>
                    <th scope="col">Mode d'acquisition</th>
                    <th scope="col">Type</th>
                    <th scope="col">Date Enr.</th>
                    <th scope="col">Photo</th>
                    <th scope="col">Obs.</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let rep of data">
                    <td col-1>
                        {{rep.parcelNumber}}
                    </td>
                    <td width="5px">
                        {{rep.parcelCode}}
                    </td>
                    <td>
                        {{rep.surface}}
                    </td>
                    <td>
                        {{rep.firstname}}
                    </td>
                    <td>
                        {{rep.name}}
                    </td>
                    <td>
                        {{rep.nationality}}
                    </td>
                    <td>
                        {{rep.age}}
                    </td>
                    <td>
                        <span *ngIf="rep.sex === 0">-</span>
                        <span *ngIf="rep.sex === 1" style="font-weight: bold;">Masculin</span>
                        <span *ngIf="rep.sex === 2" style="font-weight: bold;">Féminin</span>
                        <span *ngIf="rep.sex === 9" style="font-weight: bold;">Indefini</span>
                    </td>
                    <td>
                        {{rep.address}}
                    </td>
                    <td>
                        {{rep.phone}}
                    </td>
                    <td>
                        {{rep.acquisitionMethod}}
                    </td>
                    <td>
                        {{rep.parcelType}}
                    </td>
                    <td>
                        {{rep.syncDate}}
                    </td>
                    <td>
                        <img width="100px" height="100px" src="https://infocad.andf.bj/api/resources/{{rep.photoUri}}" alt="">
                    </td>
                    <td>
                        {{rep.observations}}
                    </td>
                </tr>
                </tbody>
              </table>
        </div>
    </div>
</div>
