import { Component, OnInit, Input } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { Config } from './../app.config';
import { AlertNotif } from './../alert';
import { Team } from './../core/_models/team.model';
import { TeamService } from './../core/_services/team.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LocalityService } from '../core/_services/locality.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { RoleService } from '../core/_services/role.service';

declare var $: any;


@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  page = 1;
  pageSize = 25;
  page2 = 1;
  pageSize2 = 5;
  searchText = ""
  closeResult = '';
  error = ""
  data: Team[] = [];
  localities: any[] = [];
  _temp: Team[] = [];
  _temp2: any[] = [];
  data_current = new Team()

  selected = [
  ];
  collectionSize = 0;
  collectionSize2 = 0;
  departements=[]
  communes=[]
  all_communes=[]
  arrondissements=[]
  all_localities=[]

  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.designation.toLowerCase().includes(term) ||
        r.cote.toString().includes(term)
    })
    this.collectionSize = this.data.length
  }

  search2() {
    this.localities = this._temp2.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.libelle.toLowerCase().includes(term)
    })
    this.collectionSize2 = this.localities.length
  }

  openUpdateModalLg(content, el) {
    this.data_current = new Team(el)
    this.localityService.getTeamLocalities(this.data_current.id).subscribe((res: any) => {
      this.data_current.localities = res.data
    })
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: "lg" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openUpdateModal(content, el) {
    let temp = []
    this.selected_locs=[]
    this.selected_locs_id=[]
    this.data_current = new Team(el)
    this.localities.forEach((e) => {
      e.check = false
      this.data_current.localities.forEach((el) => {
        if (e.id == el.id) {
          e.check = true
        }
      })
      temp.push(e)

    })
    this.localitiessSubject.next(temp)
    console.log(this.localities)
    /*this.localityService.getTeamLocalities(this.data_current.id).subscribe((res:any)=>{
      this.data_current.localities=res.data
     })*/
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openAddModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  localitiessSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])

  constructor(
    private TeamService: TeamService,
    private localityService: LocalityService,
    public roleService: RoleService,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute) { }


  ngOnInit() {
    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')

    this.data = []
    this._temp = []

    this.activatedRoute.snapshot.data.team_list.data
      .forEach((e) => { this.data.push(new Team(e)) })
    this._temp = this.data
    this.collectionSize = this.data.length


    this.localityService.getAll().subscribe((res: any) => {
      let temp = []
      res.data.forEach((e) => {
        e.check = false
        temp.push(e)
      })
      this.localitiessSubject.next(temp)
      console.log(res.data)
    })
    this.localitiessSubject.asObservable().subscribe((data) => {
      this.localities = []

      this.localities = data
      this._temp2 = this.localities
      this.collectionSize2 = this.localities.length

      console.log(this.localities)
    });

    this.dropdownSettings = {
      idField: 'id',
      singleSelection: false,
      textField: 'libelle',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: false
    };

    this.departements=[]
    this.localityService.getDepartement().subscribe((res: any) => {
      this.departements=res.data
    })
    this.all_communes=[]
    this.localityService.getcommune().subscribe((res: any) => {
      this.all_communes=res.data
    })
  }

  loadCommunues(event){
    this.communes=[]
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.communes=res.data
    })
}

loadArrondissement(event){
  this.arrondissements=[]
  this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
    this.arrondissements=res.data
  })
}

loadLocality(event){

  this.localities=[]
  this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
    res.data.forEach((l) => {
      if(this.selected_locs_id.includes(l.id)){
        l.check=true
      }
      this.localities.push(l)
    })
    this._temp2 = this.localities
    this.collectionSize2 = this.localities.length
  })
}

reset(){
  this.localities=[]
  this._temp2=[]
  this.localities=this.all_localities
  this._temp2 = this.localities
  this.collectionSize2 = this.localities.length

  this.departements=[]
  this.communes=[]
    this.arrondissements=[]
  this.localityService.getDepartement().subscribe((res: any) => {
    this.departements=res.data
  })
}

selected_locs=[]
selected_locs_id=[]
onCheck(event){
  this.localities.forEach((l) => {
    if (l.check == true) {
      if(!this.selected_locs_id.includes(l.id)){
        this.selected_locs.push(l)
        this.selected_locs_id.push(l.id)
      }
    } else{
      if(this.selected_locs_id.includes(l.id)){
        let inx=this.selected_locs_id.indexOf(l.id)
        this.selected_locs.splice(inx,1)
        this.selected_locs_id.splice(inx,1)
      }

    }
 })
}

  setLocality(value) {

    let locs = []
    this.selectedItems.forEach((l) => { locs.push(l.id) })
    this.localityService.joinTeamAndLocality(locs, this.data_current.id).subscribe((res: any) => {
      this.ngOnInit()
      this.modalService.dismissAll()

    })
  }
  setLocalityWithCheck(value) {

    let locs = []
    this.localities.forEach((l) => { if (l.check == true) { locs.push(l.id) } })
    this.localityService.joinTeamAndLocality(locs, this.data_current.id).subscribe((res: any) => {
      if (res.code == 200) {
        this.ngOnInit()
        this.modalService.dismissAll()
       // window.location.reload()
        AlertNotif.finishConfirmWithReload("Localités ajoutées", "Localités ajoutées à la nouvelle team", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }
  create(value) {


    this.TeamService.create(value).subscribe((res: any) => {
      if(res.code==200 || res.code==201){
        this.modalService.dismissAll()
              this.ngOnInit()

              AlertNotif.finish("Nouvel ajout", "Ajout éffectué avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
     
    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Nouvel ajour", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Nouvel ajout", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }


  update(value) {

    this.TeamService.update(value, this.data_current.id).subscribe((res: any) => {
      if(res.code==200){
        this.modalService.dismissAll()
        this.ngOnInit()
        AlertNotif.finish("Nouvelle modification", "Un element modifié avec succès", 'success')
  
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
      
    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Nouvelle modification", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }
  enable(id, index) {
    this.TeamService.update_partial(id, { status: true }).subscribe((res: any) => {

      this.ngOnInit()
      AlertNotif.finish("Nouvelle modification", "Un eelement activer avec succès", 'success')

    }, (err) => {
      AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })

  }

  disable(id, index) {
    this.TeamService.update_partial(id, { status: false }).subscribe((res) => {
      AlertNotif.finish("Nouvelle modification", "Un element desactiver avec succès", 'success')
      this.ngOnInit()
    }, (err) => {
      AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })
  }
  archive(id, index) {
    AlertNotif.finishConfirm("Nouvelle suppression",
      "Voulez-vous continuer ?").then((result) => {
        if (result.value) {
          this.TeamService.deleteTeamMember(id).subscribe((res: any) => {
            if(res.code==200){
              this.data_current.members.splice(index, 1)
              AlertNotif.finish("Nouvelle suppression", "Suppression effectué avec succès", 'success')
              this.ngOnInit()
            }else{
              AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
            }
            
          }, (err) => {
            AlertNotif.finish("Nouvelle suppression", "Une erreur est survenue, verifier votre connexion SVP", 'error')
          })
        }
      })
  }

  onItemSelect(item: any) {

  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
