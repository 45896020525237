<nav class="navbar-custom">
    <!--
        d-block d-sm-block d-md-none d-lg-none d-xl-none
    --> 
        <ul class="list-inline menu-left mb-0">
            <li class="float-left">
                <button class="button-menu-mobile open-left waves-effect  ">
                    <i class="mdi mdi-menu"></i>
                </button>
            </li>
  
        </ul>
        <ul class="navbar-right list-inline float-right mb-0">
             <!--
            <li class="list-inline-item">
                <div *ngIf="noInternetConnection" style="text-align: center;color: red;font-weight: bold;font-size: 13px">    
                    <span>Vous n'etes pas connecter à internet</span>    
                </div>   
            </li>
             --> 

            <li class="dropdown notification-list list-inline-item">
                <div ngbDropdown class="dropdown notification-list nav-pro-img">
                    <a ngbDropdownToggle style="cursor: pointer;" class="dropdown-toggle nav-link arrow-none nav-user btn border-0 bg-white d-flex justify-content-center align-items-center" data-toggle="dropdown" role="button" aria-haspopup="false" aria-expanded="false">
                        <i class="mdi mdi-account-circle mdi-48px my-auto"></i>
                        <span> {{user.forename}}  {{user.surname}} | <span class="text-primary">{{ getOrganization(user.organisation, user.role) }}</span> | {{roleService.getRole(user.role)}}</span> 
                    </a>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right profile-dropdown ">
                        <!-- item-->
                        <span  class="dropdown-item"
                         ngbDropdownItem> {{ getOrganization(user.organisation, user.role) }}</span>
                     
                        <a ngbDropdownItem class="dropdown-item" *ngIf="current_role==Responsable || current_role==GESTIONNAIREDEDATA" routerLink="/my-users-manage"><i class="fa fa-users"></i>  Les utilisateurs</a>
                        <a ngbDropdownItem class="dropdown-item" *ngIf="current_role==Admin" routerLink="/users"><i class="fa fa-users"></i>  Les utilisateurs</a>
                        <a ngbDropdownItem class="dropdown-item" routerLink="/profil"><i class="mdi mdi-account-circle"></i> Profil</a>
                        <div class="dropdown-divider"></div>
                        <button ngbDropdownItem class="dropdown-item text-danger" (click)="logout()"><i class="mdi mdi-power text-danger"></i> Deconnexion</button>
                    </div>
                </div>
            </li>

        </ul>

        <!-- <ul class="list-inline menu-left mb-0">
            <li class="float-left d-block d-sm-block d-md-none d-lg-none d-xl-none">
                <button class="button-menu-mobile open-left waves-effect  ">
                    <i class="mdi mdi-menu"></i>
                </button>
            </li>
            <li class="d-none d-md-inline-block">
                <form role="search" class="app-search ml-2">
                    <div class="form-group mb-0">
                        <input type="text" class="form-control" placeholder="Search..">
                        <button type="submit"><i class="fa fa-search"></i></button>
                    </div>
                </form>
            </li>
        </ul> -->

    </nav>