<!-- Begin page -->
<div id="wrapper">

  <!-- Top Bar Start -->
  <div class="topbar">

    <!-- LOGO -->
    <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <a href="index.html" class="logo">
        <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
        <br>
      </a>
    </div>


    <app-header></app-header>


  </div>
  <!-- Top Bar End -->

  <!-- ========== Left Sidebar Start ========== -->

  <app-aside></app-aside>
  <!-- Left Sidebar End -->

  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="content-page">
    <!-- Start content -->
    <div class="content">
      <div class="container-fluid">
        <div class="page-title-box">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <h4 class="page-title">Les utilisateurs</h4>
            </div>
            <div class="col-sm-6 ">

              <ol class="breadcrumb float-right">
                <li class="breadcrumb-item">INFOCAD</li>
                <li class="breadcrumb-item active">Les utilisateurs</li>
              </ol>
            </div>
          </div>
          <!-- end row -->
        </div>
        <!-- end page-title -->
        <div class="row">
          <div class="col-md-6">
            <h1 class="page-title"></h1>
          </div>
          <div class="col-md-6 d-flex justify-content-between">

            <a routerLink="/teams" class="btn btn-primary"> Les equipes </a>
            <a routerLink="/validations" class="btn btn-primary"> Comptes à valider </a>
            <button (click)="openAddModal(content)" class="btn btn-primary"><i class="fa fa-plus"></i>
              Nouvel utilisateur
            </button>

          </div>
        </div>
        <div class="row my-2">
          <div class="col-md-12 d-flex justify-content-end">
            <form>
              <div class="form-group form-inline">
                <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                       [(ngModel)]="searchText" name="searchText" (keyup)="search()"/>
              </div>
            </form>
          </div>
          <div class="col-md-12">
            <section class="card shadow-none bg-white">

              <div class="card-body">
                <table class="table  table-striped ">
                  <thead>
                  <tr>
                    <th>Nom & prenoms</th>
                    <th>Profil</th>
                    <th class="hidden-xs">Contact</th>
                    <th class="hidden-xs">Email</th>

                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
                    <td>
                      {{el.forename}} {{el.surname}}
                    </td>
                    <td class="hidden-xs">
                      <span class="label label-success"> {{ el.role}}</span>
                    </td>

                    <td class="hidden-xs text-semi-muted">
                      {{el.telephone}}
                    </td>
                    <td class="hidden-xs text-semi-muted">
                      {{el.email}}
                    </td>
                    <td>
                      <div ngbDropdown container="body">
                        <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                        <div ngbDropdownMenu>
                          <button (click)="openUpdateModal(contentU,el)" ngbDropdownItem>Modifier</button>
                          <button (click)="openShowTeamModal(details,el.team)" *ngIf="el.team!=NULL" ngbDropdownItem>
                            Voir equipe
                          </button>
                          <button (click)="openUpdateModal(infos,el)" ngbDropdownItem>Voir
                            détails
                          </button>
                          <button (click)="disable(el)" ngbDropdownItem> {{ el.enabled ? "Désactiver" : "Activer"}}
                          </button>
                          <button (click)="archiveOnTeam(user.id)" *ngIf="isDeletable(el)" ngbDropdownItem>Supprimer
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>

                </table>
                <div class="d-flex justify-content-between p-2">
                  <ngb-pagination [(page)]="page" [boundaryLinks]="true" [collectionSize]="collectionSize" [ellipses]="true"
                                  [maxSize]="5" [pageSize]="pageSize" [rotate]="true" class="">
                  </ngb-pagination>

                  <select [(ngModel)]="pageSize" class="custom-select custom-select-sm float-right" style="width: auto">
                    <option selected disabled>Fitre</option>
                    <option [ngValue]="3">3 éléments par page</option>
                    <option [ngValue]="5">5 éléments par page</option>
                    <option [ngValue]="10">10 éléments par page</option>
                  </select>
                </div>
              </div>
            </section>
          </div>
        </div>

      </div>
      <!-- container-fluid -->

    </div>
    <!-- content -->

    <app-footer>
    </app-footer>

  </div>
  <ng-template #content let-modal>
    <form #newForm="ngForm" (ngSubmit)="create(newForm.value)">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Nouvel utilisateur</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <h5>X</h5>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
          {{error}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="form-group">
          <label for="">Nom</label>
          <input type="text" class="form-control" required ngModel name="surname">
        </div>
        <div class="form-group">
          <label for="">Prenom</label>
          <input type="text" class="form-control" required ngModel name="forename">
        </div>
        <div class="form-group">
          <label for="">Email</label>
          <input type="email" name="email" required ngModel class="form-control">
        </div>
        <div class="form-group">
          <label for="">Contact</label>
          <input type="number" name="telephone" required ngModel class="form-control">
        </div>
        <div class="form-group">
          <label for="">Profil</label>
          <select name="role" required [(ngModel)]="selected_role" id="" class="form-control">

            <option value="RESPONSABLE" *ngIf="GESTIONNAIREDEDATA==role">Responsable</option>
            <option value="INVITE" *ngIf="GESTIONNAIREDEDATA==role">Invité</option>
            <option value="ENQUETEURTOPO" *ngIf="Responsable==role">Enqueteur topo</option>
            <option value="SUPERVISEUR" *ngIf="Responsable==role">Superviseur</option>
            <option value="ENQUETEURSOCIO" *ngIf="Responsable==role">Enqueteur socio</option>
          </select>
        </div>

        <!--
                <div class="form-group" *ngIf="ENQUETEURTOPO==selected_role">
                    <label for="">Team</label>
                    <select name="team" ngModel id="" class="form-control">
                        <option [value]="t.id" *ngFor="let t of teams">{{t.designation}}</option>
                    </select>
                </div>
                -->
        <div class="form-group">
          <label for="">Mot de passe <small>(facultatif)</small></label>
          <input class="form-control" icono="icone-eye-hide" id="pswd" name="password" ngModel type="password"><span
          (click)="hideOrShowPassword()" class="fa fa-fw fa-eye field-icon toggle-password" id="icone-eye-hide"
          toggle="#pswd"></span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" [disabled]="!newForm.form.valid" type="submit">Sauvegarder
        </button>
      </div>
    </form>
  </ng-template>
  <ng-template #details let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Membres</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <i class="fa fa-remove font-size-h3"></i>
      </button>
    </div>
    <div class="modal-body">


      <table class="table  table-striped ">
        <thead>
        <tr>
          <th>Nom & prenoms</th>
          <th>Profil</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let el of data_current_members ; let i=index">
          <td>
            {{el.forename}} {{el.surname}}
          </td>
          <td class="hidden-xs">
            <span class="label label-success"> {{ el.role==null ? '' : roleService.getRole(el.role)}}</span>
          </td>


        </tr>
        </tbody>

      </table>

    </div>
    <div class="modal-footer">
    </div>
  </ng-template>
  <ng-template #contentU let-modal>
    <form #updateForm="ngForm" (ngSubmit)="update(updateForm.value)">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Modification utilisateur</h4>
        <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
          <i class="fa fa-remove font-size-h3"></i>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
          {{error}}
          <button aria-label="Close" class="close" data-dismiss="alert" type="button"><span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="form-group">
          <label for="">Nom</label>
          <input [(ngModel)]="data_current.surname" class="form-control" name="surname" required type="text">
        </div>
        <div class="form-group">
          <label for="">Prenom</label>
          <input [(ngModel)]="data_current.forename" class="form-control" name="forename" required type="text">
        </div>
        <div class="form-group">
          <label for="">Email</label>
          <input [(ngModel)]="data_current.email" class="form-control" name="email" required type="email">
        </div>
        <div class="form-group">
          <label for="">Contact</label>
          <input [(ngModel)]="data_current.telephone" class="form-control" name="telephone" required type="number">
        </div>
        <!--
                <div class="form-group">
                <label for="">Profil</label>
                <select name="role" required [(ngModel)]="data_current.role" id="" class="form-control">
                        <option value="RESPONSABLE" *ngIf="GESTIONNAIREDEDATA==role">Responsable</option>
                        <option value="INVITE" *ngIf="GESTIONNAIREDEDATA==role">Invité</option>
                        <option value="ENQUETEURTOPO" *ngIf="Responsable==role">Enqueteur topo</option>
                        <option value="SUPERVISEUR" *ngIf="Responsable==role">Superviseur</option>
                </select>
            </div>
            -->

        <div class="form-group">
          <label for="">Nouveau Mot de passe <small>(facultatif)</small></label>
          <input class="form-control" name="password" ngModel type="password">
        </div>
        <!--

            <div class="form-group" *ngIf="ENQUETEURTOPO==data_current.role">
                <label for="">Team</label>
                <select name="team" [(ngModel)]="data_current.team.id" id="" class="form-control">
                    <option [value]="t.id" *ngFor="let t of teams">{{t.designation}}</option>
                </select>
            </div>
            -->

      </div>
      <div class="modal-footer">
        <button [disabled]="!updateForm.form.valid" class="btn btn-outline-dark" type="button" type="submit">
          Sauvegarder
        </button>
      </div>
    </form>
  </ng-template>
  <ng-template #infos let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Informations</h4>
      <button (click)="modal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
        <i class="fa fa-remove font-size-h3"></i>
      </button>
    </div>
    <div class="modal-body">


      <table class="table  table-striped ">
        <tbody>
        <tr>
          <td><strong>Nom</strong></td>
          <td>{{data_current.surname}}</td>
        </tr>
        <tr>
          <td><strong>Prénom</strong></td>
          <td>{{data_current.forename}}</td>
        </tr>
        <tr>
          <td><strong>Email</strong></td>
          <td>{{data_current.email}}</td>
        </tr>
        <tr>
          <td><strong>Contact</strong></td>
          <td>{{data_current.telephone}}</td>
        </tr>
        <tr>
          <td><strong>Profil</strong></td>
          <td>{{ data_current.role==null ? '' : roleService.getRole(data_current.role)}}</td>
        </tr>
        <tr>
          <td><strong>Equipe</strong></td>
          <td> {{ data_current.team==null ? '' : data_current.team}}</td>
        </tr>
        <tr>
          <td><strong>Organisation</strong></td>
          <td>{{ data_current.organisation==null ? '' : data_current.organisation.designation}}</td>
        </tr>
        </tbody>
      </table>

    </div>
    <div class="modal-footer">
    </div>
  </ng-template>
  <!-- ============================================================== -->
  <!-- End Right content here -->
  <!-- ============================================================== -->

</div>
<!-- END wrapper -->
