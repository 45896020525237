import { Component, OnInit } from '@angular/core';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { Roles } from '../core/_models/roles';
import { RoleService } from '../core/_services/role.service';
import { ConnectionService } from 'ng-connection-service';  
  

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  user:any
  current_role:any
  Admin="ADMIN"
  Responsable="RESPONSABLE"
  Superviseur="SUPERVISEUR"
  INVITE="INVITE"
  GESTIONNAIREDEDATA="GESTIONNAIREDEDATA"
  ENQUETEURTOPO="ENQUETEURTOPO"

  isConnected = true;  
  noInternetConnection: boolean;  
  
  constructor(private connectionService: ConnectionService,private localService:LocalService,
    public roleService:RoleService) {  
    this.connectionService.monitor().subscribe(isConnected => {  
      this.isConnected = isConnected;  
      if (this.isConnected) {  
        this.noInternetConnection=false;  
      }  
      else {  
        this.noInternetConnection=true;  
      }  
    })  
  }  

  ngOnInit(): void {
      if(localStorage.getItem('userData')!=null){
        this.user=this.localService.getJsonValue('userData')
      }
      if(localStorage.getItem('userRole')!=null){
        this.current_role=localStorage.getItem('userRole')
      }
  }

  getOrganization(organization, role){

    if(typeof organization != "undefined" && organization !== null && typeof organization.designation != "undefined" && organization.designation !== null) {
      return "Org : " + organization.designation;
    }

    return role === "ADMIN" ? "Org : Systeme" : "Org : ANDF";
  }

  logout(){
    localStorage.clear()
    window.location.reload()
  }
}
