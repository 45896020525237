import { Component, OnInit } from '@angular/core';
import { Bloc } from '../core/_models/bloc.model';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlocService } from '../core/_services/bloc.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertNotif } from '../alert';
import { LocalityService } from '../core/_services/locality.service';
import { Subject } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

declare var $: any;

@Component({
  selector: 'app-bloc',
  templateUrl: './bloc.component.html',
  styleUrls: ['./bloc.component.css']
})
export class BlocComponent implements OnInit {

  page = 1;
  pageSize = 25;
  searchText = ""
  closeResult = '';
  error = ""
  data: Bloc[] = [];
  _temp: Bloc[] = [];
  data_current:any

  selected = [
  ];
  Null=null
  collectionSize = 0;

  dropdownList = [];
  selectedLocalityItems = [];
  dropdownSettings: IDropdownSettings = {};

  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.cote.toLowerCase().includes(term) 

    })
///    this.collectionSize = this.data.length
  }


  openUpdateModal(content, el) {
    this.blocService.get(el.id).subscribe((res: any) => {
      this.data_current=res.data
      this.collectionSize=res.data.localities.length
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',size:'lg' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
   
  }
  openAddModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  Responsable = "RESPONSABLE"


  constructor(
    private blocService: BlocService,
    private localityService: LocalityService,
    private router: Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute) { }


    pager: any = {
      current_page: 0,
      data: [],
      last_page: 0,
      per_page: 0,
      to: 0,
      total: 0
    }
    subject = new Subject<any>();
    subjectForLocality= new Subject<any>();
    current_role=""

  ngOnInit() {
    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')

    //:IDropdownSettings
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'libelle',
      selectAllText: 'Tout selectionner',
      unSelectAllText: 'Tout déselectionner',
      itemsShowLimit: 5,
      enableCheckAll: true,
      allowSearchFilter: true
    };

    this.subjectForLocality.subscribe((val) => {
      this.localities=[]
      this.localities=val
    })

    if (localStorage.getItem('userRole') != null) {
      this.current_role = localStorage.getItem('userRole')
      console.log(this.current_role,this.Responsable)
    }
    this.data = []
    this._temp = []
    this.activatedRoute.queryParams.subscribe(x => this.init(x.page-1 || 0));

    
  
    this.subject.subscribe((val) => {
      //this.pager = val
      this.pager={
        current_page: val.number+1,
        data: val.content,
        last_page: val.totalPages,
        per_page: val.size,
        to: 0,
        total: val.totalElements
      }
      this.page = this.pager.current_page

      let pages = []
      if (this.pager.last_page <= 5) {
        for (let index = 1; index <= this.pager.last_page; index++) {
          pages.push(index)
        }
      } else {
        let start = (this.page > 3 ? this.page - 2 : 1)
        let end = (this.page + 2 < this.pager.last_page ? this.page + 2 : this.pager.last_page)
        for (let index = start; index <= end; index++) {
          pages.push(index)
        }
      }

      this.pager.pages = pages
    });
   
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  init(page){
    this.data = []
    this.blocService.getAll(page,25).subscribe((res: any) => {
      this.subject.next(res.data);
      this.data=res.data.content
    })

    this.departements=[]
    this.localityService.getDepartement().subscribe((res: any) => {
      this.departements=res.data
    })
    this.communes=[]
    this.localityService.getcommune().subscribe((res: any) => {
      this.communes=res.data
    })

  }
  create(value) {

   

    this.blocService.create(value).subscribe((res: any) => {
      if (res.code == 201   || res.code == 200) {
        this.modalService.dismissAll()
       // window.location.reload()
       this.init(0)
        AlertNotif.finishConfirmWithReload("Nouvel ajout", "Ajout éffectué avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }

    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Nouvel ajour", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Nouvel ajout", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }


  update(value) {

    delete value.email
    this.blocService.update(value, this.data_current.id).subscribe((res: any) => {
      if (res.code == 200) {
        this.init(0)

        this.modalService.dismissAll()
        //window.location.reload()

        AlertNotif.finishConfirmWithReload("Nouvelle modification", "Un element modifié avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Nouvelle modification", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }
 
  archive(id, index) {
    AlertNotif.finishConfirm("Nouvelle suppression",
      "Voulez-vous continuer ?").then((result) => {
        if (result.value) {
          this.blocService.delete(id).subscribe((res: any) => {
            if (res.code == 200) {
              this.data.splice(index, 1)
              AlertNotif.finish("Nouvelle suppression", "Suppression effectué avec succès", 'success')
            }else{
              AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
            }
          }, (err) => {
            AlertNotif.finish("Nouvelle suppression", "Une erreur est survenue, verifier votre connexion SVP", 'error')
          })
        }
      })
  }

  communes= []
  departements=[]

  getLocalitiesLev2(event){
    this.communes=[]
    this.arrondissements=[]
    this.localities=[]
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.communes=res.data
    })
  }
  arrondissements=[]
  getLocalitiesLev3(event){
    this.arrondissements=[]
    this.localities=[]
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.arrondissements=res.data
    })
    /*this.data.forEach((l) => { l.check = false });
    this.show_btns = false;*/
  }

  localities=[]
  getLocalitiesLev4(event){
    this.localities=[]
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
     this.localities=res.data
     this.subjectForLocality.next(res.data)
    })
  }

  add_locality(value) {

    if(this.selectedLocalityItems.length==0){
      AlertNotif.finish("Erreur", "Veuillez choisir les localités", 'error');
      return
    }
    var items=[]
    this.selectedLocalityItems.forEach((e) => {
      items.push({
        locality:e.id,
        adding: true
      })
    })
    console.log(this.selectedLocalityItems)
    var param={
      items:items,
      blocId:this.data_current.id
    }
    this.blocService.add_locality(param).subscribe((res: any) => {
      if (res.code == 201   || res.code == 200) {
        this.data_current=res.data
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }

    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Erreur", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Erreur", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }
  remove_locality(id) {

    var param={
      items:[{
        locality:id,
        adding: false
      }],
      blocId:this.data_current.id
    }
    this.blocService.add_locality(param).subscribe((res: any) => {
      if (res.code == 201   || res.code == 200) {
          this.data_current=res.data
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }

    }, (err) => {

      if (err.error.detail != null) {
        AlertNotif.finish("Erreur", err.error.detail, 'error')
      } else {
        AlertNotif.finish("Erreur", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }
}
