import { Component, OnInit } from '@angular/core';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-consolidation-files',
  templateUrl: './consolidation-files.component.html',
  styleUrls: ['./consolidation-files.component.css']
})
export class ConsolidationFilesComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute, 
    private localService:LocalService,) { }
  files:File
  session=""
  page=1
  pageSize=25
  Admin="ADMIN"
  Responsable="RESPONSABLE"
  Superviseur="SUPERVISEUR"
  INVITE="INVITE"
  GESTIONNAIREDEDATA="GESTIONNAIREDEDATA"
  ENQUETEURTOPO="ENQUETEURTOPO"
  ENQUETEURSOCIO="ENQUETEURSOCIO"
  closeResult=""
  current_role=""
  data_current:any
  user:any
  data:any []=[]
  users:any []=[]
  teams:any []=[]
  _temp:any[]=[]
  collectionSize=0


  ngOnInit(): void {
    if(localStorage.getItem('userRole')!=null){
      this.current_role=localStorage.getItem('userRole')
    }
    this.user=this.localService.getJsonValue("userData")
    this.data=[]
    this._temp=[]

   

    
    this.activatedRoute.snapshot.data.data_list.data.forEach((e)=>{
     // if(e.consolidated != -1){
        this.data.push(e)
      //}
    })
    this._temp=this.data
    this.collectionSize=this.data.length

  }

}
