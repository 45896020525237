import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams, } from '@angular/common/http';
import { Config } from '../../../app.config';
import { JwtHelperService } from "@auth0/angular-jwt";


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
};
@Injectable({
  providedIn: 'root'
})

export class AuthService {

  url_auth = 'http://10.250.2.191:7000/api/user/sign-in';
  url_get_tok = 'auth/accessToken?client_id=';
  url_rol = 'auth/v1/openapi/userInfo?access_token=';
  url_get_user = "auth/user/"
  
  constructor(private http: HttpClient) { }

  signIn(email, password): Observable<any> {;
    return this.http.post("api/users/sign-in", {
      email, password
    }, httpOptions);
  }

  get_token(id, secret, type, code, uri): Observable<any> {
    let url = this.url_get_tok + id + '&client_secret=' + secret + '&grant_type=' + type + '&code=' + code + '&redirect_uri=' + uri;
    return this.http.post(url, httpOptions);
  }

  role_serv(token): Observable<any> {
    let url = this.url_rol + token;
    return this.http.post(url, httpOptions);
  }

  get_user(id): Observable<any> {
    let url = this.url_get_user + id
    return this.http.get(url)
  }

 /* JWTTokenIsExpired(){
    const helper = new JwtHelperService();
    const isExpired = helper.isTokenExpired(localStorage.getItem("userToken"));
    return isExpired
  }*/

}
