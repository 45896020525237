import { Component, OnInit, Input } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Config } from './../app.config';
import { AlertNotif } from './../alert';
import { Organization } from './../core/_models/organization.model';
import { InterviewService } from './../core/_services/interview.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { TeamService } from '../core/_services/team.service';
import { Team } from '../core/_models/team.model';
import { NgxSpinnerService } from "ngx-spinner";
import { OrganizationService } from '../core/_services/organization.service';
import { LocalityService } from '../core/_services/locality.service';
declare var $: any;

@Component({
  selector: 'app-interview-rejected',
  templateUrl: './interview-rejected.component.html',
  styleUrls: ['./interview-rejected.component.css']
})
export class InterviewRejectedComponent implements OnInit {

  page = 0;
  pageSize = 10;
  searchText = ""
  closeResult = '';
  error = ""
  data: any[] = [];
  _temp: any[] = [];
  Null=null
  selected = [
  ];
  collectionSize = 0;
  Responsable="RESPONSABLE"
  Superviseur = "SUPERVISEUR"

  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.owner.toLowerCase().includes(term) ||
      (r.locality==null ? '' : r.locality.libelle).toString().toLowerCase().includes(term)  ||
      r.investigator[1].toLowerCase().includes(term)  ||
      r.investigator[0].toLowerCase().includes(term) ||
      r.acquisitionMethod.toLowerCase().includes(term) ||
      (r.parcelType==null ? '' : r.parcelType ).toLowerCase().includes(term) ||
      r.parcelNumber.toString().toLowerCase().includes(term) ||
      r.nationality.toString().toLowerCase().includes(term)
      ||
      r.age.toString().toLowerCase().includes(term)  ||

      r.nup.toString().includes(term)
    })
    this.collectionSize = this.data.length
  }

  ref=""
  openUpdateModal(content, ref) {
    this.ref=ref
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openAddModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  user: any

  checkStatus(status) {
    if (status == "pending") {
      return "En attente"
    }
    if (status == "finalize") {
      return "Finalisé"
    }
    if (status == "validated") {
      return "Validé"
    }
    if (status == "rejected") {
      return "Rejeté"
    }
  }

  methodAcquisition = [
    { id: "Achat", libelle: "Achat" },
    { id: "Don", libelle: "Don" },
    { id: "Heritage", libelle: "Héritage" },
    { id: "Legs", libelle: "Legs" },
    { id: "Testament", libelle: "Testament" },
    { id: "Libre_installation", libelle: "Libre installation" },
    { id: "Heritage_non_partager", libelle: "Héritage non partagé" },
    { id: "Attribution_coutumiere", libelle: "Attribution coutumière" },
    { id: "Echange", libelle: "Echange" },
    { id: "accession", libelle: "Accession" },
    { id: "incorporation", libelle: "Incorporation" },
    { id: "prescription", libelle: "Prescription" },
    { id: "other", libelle: "Autres" },
  ]

  showModeAcquisition(key){
    if(key=="" || key==null || key=="-") {
      return key
    }
    return this.methodAcquisition.find((e) => (e.id==key)).libelle
  }

  current_teams=[]
  teams=[]
  localities=[]

  constructor(
    private localService: LocalService,
    private interviewService: InterviewService,
    private router: Router,private organisationService:OrganizationService,
    private teamService:TeamService,private localityService:LocalityService,
    private modalService: NgbModal,private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute) { }

  orgs=[]
  role=""
  ngOnInit() {

    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')
    this.user = this.localService.getJsonValue("userData")
    this.data = []
    this._temp = []
    if (localStorage.getItem('userRole') != null) {
      this.role = localStorage.getItem('userRole')
    }
   /* this.activatedRoute.snapshot.data.list.data
    this._temp = this.data
    this.collectionSize = this.data.length
   */
    this.teams=[]
    let stack=[]
    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        if(e.localities!=null){
          e.localities.forEach((l) => {
            if(!stack.includes(l.id)){
              stack.push(l.id)
              this.localities.push(l)
            }
           })
        }
        this.teams.push(new Team(e))
      })

      //this.users=res.data
    })

    if(localStorage.getItem('filterParams')!=null){
      let filterparams=JSON.parse(localStorage.getItem('filterParams'))
      this.selected_org=filterparams.org
      this.selected_arr=filterparams.arrondissement
      this.selected_commune=filterparams.commune
      this.selected_locality=filterparams.locality
      this.selected_team=filterparams.team
      this.selected_dep=filterparams.departement

      this.h_departements=[]
      this.h_communes=[]
      this.h_arrondissements=[]
      this.h_localities=[]
      if(this.selected_team!=""){
        this.localityService.getTeamLocalitiesHierachy(this.selected_team).subscribe((res: any) => {
          if(res.code==200){
            this.h_departements=res.data
            this.h_communes=this.h_departements[+this.selected_dep].child
            this.h_arrondissements=this.h_communes[+this.selected_commune].child
            this.h_localities=this.h_arrondissements[+this.selected_arr].child

            this.activatedRoute.queryParams.subscribe(x => this.filter(filterparams,x.page-1 || 0,'0'));
          }
        })
      }else{
        this.interviewData();
      }

    }else{
      this.interviewData();
    }

    this.subject.subscribe((val) => {
      //this.pager = val
      this.pager={
        current_page: val.number+1,
        data: val.content,
        last_page: val.totalPages,
        per_page: val.size,
        to: 0,
        total: val.totalElements
      }
      this.page = this.pager.current_page

      let pages = []
      if (this.pager.last_page <= 5) {
        for (let index = 1; index <= this.pager.last_page; index++) {
          pages.push(index)
        }
      } else {
        let start = (this.page > 3 ? this.page - 2 : 1)
        let end = (this.page + 2 < this.pager.last_page ? this.page + 2 : this.pager.last_page)
        for (let index = start; index <= end; index++) {
          pages.push(index)
        }
      }

      this.pager.pages = pages
    });

  }



  reinitOrg(event) {
    this.activatedRoute.queryParams.subscribe(x => this.init(event.target.value,x.page-1 || 0));
    this.resetFilter()
  }

  interviewData() {
    if (this.user.organisation == null) {
      this.orgs = []
      this.organisationService.getAll().subscribe((res: any) => {
        res.data.forEach((e) => { this.orgs.push(new Organization(e)) })
        //this.users=res.data
        if (this.orgs.length > 0) {

          this.activatedRoute.queryParams.subscribe(x => this.init(this.orgs[0].id,x.page-1 || 0));
        }
      })
    } else {
      this.activatedRoute.queryParams.subscribe(x => this.init(this.user.organisation.id,x.page-1 || 0));
      this.resetFilter();
    }
  }
  refreshInterviewData(){
     this.interviewData();
  }




  selected_org=""


  pager: any = {
    current_page: 0,
    data: [],
    last_page: 0,
    per_page: 0,
    to: 0,
    total: 0
  }
  subject = new Subject<any>();



  init(org,page) {

    let oldInterviewIdSave = [];

    if(this.data == null) {
      oldInterviewIdSave = [];
    }else{
      this.data.forEach( i => {
        console.log("Add ref = " + i.ref + " in oldInterviewIdSave")
        oldInterviewIdSave.push(i.ref);
      });
    }
    this.selected_org=org
    console.log("---------------------------------");
    console.log(oldInterviewIdSave);
    console.log("---------------------------------");

    //this.spinner.show();
    if(localStorage.getItem("interviewsDataStorageRej")!==null){
      this.traitement(JSON.parse(localStorage.getItem("interviewsDataStorageRej")),oldInterviewIdSave)
    }
    this.interviewService.getAllReject(page, 10, org).subscribe((res: any) => {

     //this.full_data=res
     localStorage.setItem('interviewsDataStorageRej',JSON.stringify(res))
     // localStorage.setItem("interviewsDataStorage",JSON.stringify(res))
     this.traitement(res,oldInterviewIdSave)
    })

  }

  traitement(res,oldInterviewIdSave){

    this.data = []
      this._temp = []
      this.subject.next(res.data);

      res.data.content.forEach(element => {
              if(oldInterviewIdSave == null || oldInterviewIdSave.length === 0){
                element.new = 1;
              }else{
                element.new =  oldInterviewIdSave.includes(element.ref) ? 1 : -1;
              }
              element.check=false
              this.data.push(element)
      });
      this._temp = this.data
      this.collectionSize = this.data.length
      this.spinner.hide();
  }

  selected_locality=""
  selected_team=""
  selected_commune=""
  selected_arr=""
  selected_dep=""
  show_reset=false
  resetFilter(){
    console.log("In resetFilter");
    this.data=[]
    this.data=this._temp
    this.collectionSize = this.data.length
    this.current_teams=[]
    this.h_arrondissements=[]
    this.h_communes=[]
    this.h_departements=[]
    this.h_localities=[]
    this.selected_locality=""
    this.selected_team=""
    this.selected_arr=""
    this.selected_commune=""
    this.selected_dep=""
    this.show_reset=false
    window.localStorage.removeItem('filterParams')
    console.log("Out of resetFilter");
    this.router.navigate(
      ['.'],
      { relativeTo: this.activatedRoute, queryParams: {} }
    );
  }

  refresh_page() {
    window.location.reload();
  }


  h_departements=[]
  getLocalitiesLev1(event){
    this.h_departements=[]
    this.h_arrondissements=[]
    this.h_communes=[]
    this.h_localities=[]
    this.localityService.getTeamLocalitiesHierachy(event.target.value).subscribe((res: any) => {
      if(res.code==200){
        this.h_departements=res.data
        if(this.h_departements.length!=0){
          this.h_communes=this.h_departements[0].child
        }
        if(this.h_communes.length!=0){
        this.h_arrondissements=this.h_communes[0].child
        }
        if(this.h_arrondissements.length!=0){
        this.h_localities=this.h_arrondissements[0].child
        }
      }

    })


    this.show_reset=true
    let res=[]
   /* res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.teamId==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length

    this.data.forEach((l) => { l.check = false });
    this.show_btns = false;*/
  }

  h_communes=[]
  getLocalitiesLev2(event){
    this.h_arrondissements=[]
    this.h_communes=[]
    this.h_localities=[]
    this.h_communes=this.h_departements[+event.target.value].child
    this.h_arrondissements=[]
    this.h_localities=[]
   /* this.data.forEach((l) => { l.check = false });
    this.show_btns = false;*/
  }

  h_arrondissements=[]
  getLocalitiesLev3(event){
    this.h_arrondissements=[]
    this.h_localities=[]
    this.h_arrondissements=this.h_communes[+event.target.value].child
    this.h_localities=[]
    /*this.data.forEach((l) => { l.check = false });
    this.show_btns = false;*/
  }

  h_localities=[]
  getLocalitiesLev4(event){
    this.h_localities=[]
    this.h_localities=this.h_arrondissements[+event.target.value].child
    /*this.data.forEach((l) => { l.check = false });
    this.show_btns = false;*/
  }
  filterLocalitiesLev4(event){
    this.show_reset=true
    let res=[]
    /*res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.locality.id==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length
    this.data.forEach((l) => { l.check = false });*/
    this.show_btns = false;

  }




  loadTeam(event) {
    this.current_teams=[]
    this.teams.forEach((e) => {
      if(e.localities!=null){
        e.localities.forEach((l) => {
          if (l.id == +event.target.value) {
            this.current_teams.push(e)
          }
        })
      }
    })


    this.show_reset=true
    let res=[]
    /*res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.locality.id==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length*/
    //  this.current_localities = []
    //this.current_localities = this.teams[event.target.value].localities
  }

  filterByTeam(event){
    let res=[]
    res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.teamId==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length
  }


  validate(ref){
    AlertNotif.finishConfirm("Validation d'enregistrement",
    "Voulez-vous continuer ?").then((result) => {
      if (result.value) {
        this.interviewService.validatePostValidation(ref).subscribe((res: any) => {
          console.log("#---------------------------#")
          console.log(res);
          console.log("#---------------------------#")
          if (res.code == 200) {
           //window.location.reload()
           //this.data.splice(i,1);
            AlertNotif.finish("Validation d'enregistrement", "Validation effectué avec succès", 'success')
          } else {
            AlertNotif.finish("Validation d'enregistrement", "Une erreur est survenue", 'error')
          }

        }, (err : any) => {
          console.log("#---------------------------#")
          console.log(err);
          console.log("#---------------------------#")
          AlertNotif.finish("Validation d'enregistrement", "Une erreur est survenue, verifier votre connexion SVP", 'error')
        })
      }
    })


  }
  show_btns=false
  onCheck(event){
    let elts = []
    this.data.forEach((l) => { if (l.check == true) { elts.push(l.ref) } })
    if(elts.length <= 0){
      this.show_btns=false
    }else{
      this.show_btns=true
    }
  }
  ValidateWithCheck() {
    let elts = []
    console.log(this.data)

    this.data.forEach((l) => { if (l.check == true) { elts.push(l.ref) } })
    let recordsRefData = elts.join("&recordingRefs=");

    if(elts.length==0){
      AlertNotif.finish("Validation enquêtes", "Aucune donnée n'a été sélectionner pour validation", 'error')
    }else{
      this.interviewService.validateGroupPostValidation(recordsRefData).subscribe((res: any) => {
        if (res.code == 200) {
          this.interviewData();
          this.modalService.dismissAll()
          //window.location.reload()
          AlertNotif.finish("Validation enquêtes", "Validation effectués avec succès", 'success')
        }else{
          AlertNotif.finish("Rejet enquêtes", "Oups... Erreur Interterne. veuillez réessayer dans un instant", 'warning')
        }
      },
      (error : any) => {
        AlertNotif.finish("Rejet enquêtes", "Oups... Erreur Interterne. veuillez réessayer dans un instant", 'error')
      }
      )
    }
  }
  page_loading=false
  filter(value,page,init='0'){
    if(page==0){
      page=1
      const queryParams: Params = { page: 1 };

      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
  value.org=this.selected_org
  if(init=='1'){
    localStorage.setItem('filterParams',JSON.stringify(value));
  }

    let oldInterviewIdSave = [];

    if(this.data == null) {
      oldInterviewIdSave = [];
    }else{
      this.data.forEach( i => {
        oldInterviewIdSave.push(i.ref);
      });
    }
    let com=""
    if(value.commune=="" ){
      com=null
    }else{
      if(this.h_communes[+value.commune].length!=0){
        com=this.h_communes[+value.commune].id
      }
    }

    let arr=""
    if(value.arrondissement=="" ){
      arr=null
    }else{
      if(this.h_arrondissements[+value.arrondissement].length!=0){
        arr=this.h_arrondissements[+value.arrondissement].id
      }
    }
    /*if(localStorage.getItem("interviewsDataStorageRej")!==null){
      this.traitement(JSON.parse(localStorage.getItem("interviewsDataStorageRej")),oldInterviewIdSave)
    }*/

    this.page_loading=true
    this.interviewService.getAllFilterReject(page-1, 10, this.selected_org,value.locality=="" ? null :value.locality,
    value.team=="" ? null : value.team,com,arr).subscribe((res: any) => {
      this.page_loading=false
      //this.full_data=res
     localStorage.setItem('interviewsDataStorageRej',JSON.stringify(res))
     // localStorage.setItem("interviewsDataStorage",JSON.stringify(res))
     this.traitement(res,oldInterviewIdSave)
    })
    if(init=='1'){
      location.reload()
     }
  }

}
