import { Component, OnInit } from '@angular/core';
import { InterviewService } from '../core/_services/interview.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { TeamService } from '../core/_services/team.service';
import { UserService } from '../core/_services/user.service';
import { AlertNotif } from '../alert';
import { Team } from '../core/_models/team.model';
import { window, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import * as fileSaver from 'file-saver';
import { LocalityService } from '../core/_services/locality.service';
import { async } from 'q';
import { OrganizationService } from '../core/_services/organization.service';
import { Organization } from '../core/_models/organization.model';
declare var $: any;


@Component({
  selector: 'app-consolidation-success',
  templateUrl: './consolidation-success.component.html',
  styleUrls: ['./consolidation-success.component.css']
})
export class ConsolidationSuccessComponent implements OnInit {

  constructor(private inverviewService: InterviewService,
    private http: HttpClient, private localityService: LocalityService,
    private userService: UserService, private router: Router,private organisationService:OrganizationService,
    private teamService: TeamService, private activatedRoute: ActivatedRoute,
    private localService: LocalService, private modalService: NgbModal, ) { }
  session = ""
  page = 1
  pageSize = 25
  Admin = "ADMIN"
  Responsable = "RESPONSABLE"
  Superviseur = "SUPERVISEUR"
  INVITE = "INVITE"
  GESTIONNAIREDEDATA = "GESTIONNAIREDEDATA"
  ENQUETEURTOPO = "ENQUETEURTOPO"
  ENQUETEURSOCIO = "ENQUETEURSOCIO"
  closeResult = ""
  current_role = ""
  data_current: any
  data_infos: any = { total: 0, failed: 0, status: "", code: 0 }
  underOne = -1
  One = 1
  Zero = 0
  uploaded = false;
  searchText = ""
  Null=null
  orgs:any[]=[]
  user: any
  data: any[] = []
  users: any[] = []
  teams: any[] = []
  current_localities: any[] = []
  _temp: any[] = []
  collectionSize = 0
  localities=[]
  departements = []
  current_teams = []

  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.localityWs.libelle.toLowerCase().includes(term) ||
      r.teamOutWs.cote.toString().toLowerCase().includes(term) ||
      r.orthers.parcelTotal.toString().toLowerCase().includes(term) ||
      r.orthers.parcelConsolidatedFailed.toString().toLowerCase().includes(term) 
    })
    this.collectionSize = this.data.length
  }

  ngOnInit() {
    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')

    if (localStorage.getItem('userRole') != null) {
      this.current_role = localStorage.getItem('userRole')
    }
    this.user = this.localService.getJsonValue("userData")

    this.teams=[]
    let stack=[]
    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => { 
        if(e.localities!=null){
          e.localities.forEach((l) => { 
            if(!stack.includes(l.id)){
              stack.push(l.id)
              this.localities.push(l)
            }
           })
        }
        this.teams.push(new Team(e))
      })

      //this.users=res.data
    })

    this.data = []
    this._temp = []


    this.activatedRoute.snapshot.data.data_list.data.forEach((c) => {
      if (c.orthers.uploaded==1 && c.orthers.persisted==1 && c.orthers.consolidated==1 
        && c.orthers.parcelConsolidatedFailed==0) {
        this.data.push(c)
      }
    })
    this._temp = this.data
    this.collectionSize = this.data.length


    this.orgs=[]
    this.organisationService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => { this.orgs.push(new Organization(e)) })
      //this.users=res.data
    })
  }
  selected_locality=""
  selected_team=""
  show_reset=false
  resetFilter(){
    this.data=[]  
    this.data=this._temp
    this.collectionSize = this.data.length
    this.current_teams=[]
    this.h_arrondissements=[]
    this.h_communes=[]
    this.h_departements=[]
    this.h_localities=[]
    this.selected_locality=""
    this.selected_team=""
    this.show_reset=false
  }



  h_departements=[]
  getLocalitiesLev1(event){
    this.h_departements=[]
    this.h_arrondissements=[]
    this.h_communes=[]
    this.h_localities=[]
    this.localityService.getTeamLocalitiesHierachy(event.target.value).subscribe((res: any) => {
      if(res.code==200){
        this.h_departements=res.data
        if(this.h_departements.length!=0){
          this.h_communes=this.h_departements[0].child
        }
        if(this.h_communes.length!=0){
        this.h_arrondissements=this.h_communes[0].child
        }
        if(this.h_arrondissements.length!=0){
        this.h_localities=this.h_arrondissements[0].child
        }
      }
      
    })
   
 
    this.show_reset=true
    let res=[]
    res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.teamOutWs==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length
  }

  h_communes=[]
  getLocalitiesLev2(event){
    this.h_communes=[]
    this.h_communes=this.h_departements[+event.target.value].child
    this.h_arrondissements=[]
    this.h_localities=[]
  }

  h_arrondissements=[]
  getLocalitiesLev3(event){
    this.h_arrondissements=[]
    this.h_arrondissements=this.h_communes[+event.target.value].child
    this.h_localities=[]
  }

  h_localities=[]
  getLocalitiesLev4(event){
    this.h_localities=[]
    this.h_localities=this.h_arrondissements[+event.target.value].child
  }
  filterLocalitiesLev4(event){
    this.show_reset=true
    let res=[]
    res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.localityWs.id==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length

  }

  filterByTeam(event){
    let res=[]
    res=this.data
    this.data=[]
    res.forEach((d) => {
      if (d.teamOutWs.id==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length
  }
  loadTeam(event) {
    this.current_teams=[]
    this.teams.forEach((e) => {
      if(e.localities!=null){
        e.localities.forEach((l) => {
          if (l.id == +event.target.value) {
            this.current_teams.push(e)
          }
        })
      }
    })

 this.show_reset=true
    let res=[]
    res=this._temp
    this.data=[]
    res.forEach((d) => {
      if (d.localityWs.id==event.target.value) {
        this.data.push(d)
      }
    })
    this.collectionSize = this.data.length
    //  this.current_localities = []
    //this.current_localities = this.teams[event.target.value].localities
  }

  download(session) {
    this.inverviewService.download(session).subscribe(response => {
      let blob: any = new Blob([response], { type: 'application/zip; charset=utf-8' });
      const url = URL.createObjectURL(blob);
      //window.open(url);
      //window.location.href = response.url;
      fileSaver.saveAs(blob, 'shapefiles.zip');
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
    //'application/zip'
  }

}
