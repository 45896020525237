
import { Injectable } from "@angular/core";  
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";  
import { Observable } from "rxjs";  
import { User } from "./../_models/user.model";  
import { UserService } from "./../_services/user.service";  

@Injectable()

export class UserGetAllResolve implements Resolve<User[]>{

    constructor(private dataService: UserService) {}  

    resolve(route: ActivatedRouteSnapshot): Observable<User[]> {  
        return this.dataService.getAll();  
      }      
}

@Injectable()
export class UserGetAllMobileResolve implements Resolve<User[]>{

    constructor(private dataService: UserService) {}  

    resolve(route: ActivatedRouteSnapshot): Observable<User[]> {  
        return this.dataService.getAllMobileUser(null);  
      }    
}



@Injectable()
export class UserGetAllOrgResolve implements Resolve<User[]>{

    constructor(private dataService: UserService) {}  

    resolve(route: ActivatedRouteSnapshot): Observable<User[]> {  
        return this.dataService.getOrgUsers(route.params.id);  
      }    
}

@Injectable()
export class UserGetMeResolve implements Resolve<User>{

    constructor(private dataService: UserService) {}  

    resolve(route: ActivatedRouteSnapshot): Observable<User> {  
        return this.dataService.getMe();  
      }    
}




/*
@Injectable()
export class UserGetResolve implements Resolve<User>{

    constructor(private dataService: UserService) {}  

    resolve(route: ActivatedRouteSnapshot): Observable<User> {  
        return this.dataService.get(route.params.id);  
      }    
}
*/


