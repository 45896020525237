export class Organization {

    id: number;
    address: string;
    designation: string;
    email: string;
    phone: string;
   
    

    constructor(attrs: any = null) {
        if (attrs) {
          this.build(attrs);
        }
      }

      build(attrs: any): void {
        this.id = attrs.id;
        this.email = attrs.email;
        this.designation = attrs.designation;
        this.email = attrs.email;
        this.phone = attrs.phone;
        this.address = attrs.address;
      }
    toJson() {
        return {
            id: this.id,
            email: this.email,
            designation: this.designation,
            phone: this.phone,
            address: this.address,

          };
    }

}
