import { Component, OnInit, Input } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { Config } from './../app.config';
import { AlertNotif } from './../alert';
import { User } from './../core/_models/user.model';
import { UserService } from './../core/_services/user.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from '../core/_models/organization.model';
import { OrganizationService } from '../core/_services/organization.service';
import { RoleService } from '../core/_services/role.service';
import { Roles } from '../core/_models/roles';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { TeamService } from '../core/_services/team.service';
import { Team } from '../core/_models/team.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LocalityService } from '../core/_services/locality.service';

declare var $: any;

@Component({
  selector: 'app-user-validation',
  templateUrl: './user-validation.component.html',
  styleUrls: ['./user-validation.component.css']
})
export class UserValidationComponent implements OnInit {

  page = 1;
  pageSize = 25;
  page2 = 1;
  pageSize2 = 5;
  searchText = ""
  closeResult = '';
  error = ""
  data: User[] = [];
  _temp: User[] = [];
  _temp2: any[] = [];
  data_current = new User()
  organisations: any[] = []
  teams: any[] = []
  roles: any[] = []
  selected = [
  ];
  Null = null
  collectionSize = 0;
  collectionSize2 = 0;
  topo_investigator_role = "dfe57c7f-668d-447c-b474-abb306bf3ecf"
  social_investigator_role = "0547015e-ac82-4d78-818a-7141cc3d405b"
  super_admin_role = "9b0d2927-b9e6-449f-bf99-ef0aadf753ba"
  role: any
  user: any
  members: any[] = []
  show_group_validate = false
  check_all = false
  data_current_members = []
  currentTeamCote: any
  NULL = null
  localities: any[] = []
  departements=[]
  communes=[]
  arrondissements=[]
  all_communes=[]
  Zero=0
  all_localities=[]

  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.forename.toLowerCase().includes(term) ||
      r.role.toString().includes(term)||
        r.surname.toString().includes(term)
    })
    this.collectionSize = this.data.length
  }

  search2() {
    this.localities = this._temp2.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.libelle.toLowerCase().includes(term)
    })
    this.collectionSize2 = this.localities.length
  }

  data_team_current = new Team()

  openLocalityModal(content, el) {
    this.modalService.dismissAll()
    this.data_team_current = new Team(el)
    this.selected_locs=[]
    this.selected_locs_id=[]
    this.localityService.getTeamLocalities(this.data_team_current.id).subscribe((res: any) => {
      this.data_team_current.localities = res.data
    })
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', size: "lg"
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openShowTeamModal(content, cote) {

  //FIXED reset communes data, arrondissements data and Localities
    this.communes = [];
    this.arrondissements = [];
    this.localities = [];
    this.data_current_members = [];
    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        if (e.cote == cote)
          this.data_team_current = e
        this.data_current_members = e.members
      })
    })
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openUpdateModal(content, el) {
    this.data_current = el
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openShowModal(content, el) {
    this.data_current = el
    this.data_current_members = []
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: "lg" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  loadMembers(event) {
    this.data_current_members = []

    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        if (e.id == event.target.value)
          this.data_current_members = e.members
      })
    })
  }

  openAddModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  constructor(
    private userService: UserService,
    private localService: LocalService,
    private teamService: TeamService,
    private router: Router,
    private localityService: LocalityService,
    public roleService: RoleService,
    private organisationService: OrganizationService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute) { }


  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  localitiessSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])


  ngOnInit() {
    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')
    this.role = localStorage.getItem("userRole")
    this.user = this.localService.getJsonValue("userData")

    this.apiGetUserNotValidatedTreatment();

    this.teams = []
    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => {
        this.teams.push(new Team(e))
      })
    })

    this.localityService.getAll().subscribe((res: any) => {

      res.data.forEach((e) => {
        e.check = false
        this.localities.push(e)
      })
      this.all_localities = this.localities
      this._temp2 = this.localities
      this.collectionSize2 = this.localities.length
      // this.localitiessSubject.next(temp)
      console.log(res.data)
    })
    /*this.localitiessSubject.asObservable().subscribe((data) => {
      this.localities=[]

       this.localities=data
       console.log(this.localities)
     });
    */
    this.dropdownSettings = {
      idField: 'id',
      singleSelection: false,
      textField: 'libelle',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: false
    };

    this.departements=[]
    this.localityService.getDepartement().subscribe((res: any) => {
      this.departements=res.data
    })
    this.all_communes=[]
    this.localityService.getcommune().subscribe((res: any) => {
      this.all_communes=res.data
    })
  }



  loadCommunues(event){
      this.communes=[]
      this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
        this.communes=res.data
      })
  }

  loadArrondissement(event){
    this.arrondissements=[]
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.arrondissements=res.data
    })
  }

  loadLocality(event){

    this.localities=[]
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      res.data.forEach((l) => {
        if(this.selected_locs_id.includes(l.id)){
          l.check=true
        }
        this.localities.push(l)
      })
      this._temp2 = this.localities
      this.collectionSize2 = this.localities.length
    })
  }

  reset(){
    this.localities=[]
    this._temp2=[]
    this.localities=this.all_localities
    this._temp2 = this.localities
    this.collectionSize2 = this.localities.length

    this.departements=[]
    this.communes=[]
      this.arrondissements=[]
    this.localityService.getDepartement().subscribe((res: any) => {
      this.departements=res.data
    })
  }

  setLocality(value) {
    let locs = []
    this.selectedItems.forEach((l) => { locs.push(l.id) })
    this.localityService.joinTeamAndLocality(locs, this.data_team_current.id).subscribe((res: any) => {
      if (res.code == 200) {
        this.ngOnInit()
        this.modalService.dismissAll()
        AlertNotif.finishConfirmWithReload("Localités ajoutées", "Localités ajoutées à la nouvelle team", 'success')
      }
    })
  }
  selected_locs=[]
  selected_locs_id=[]
  onCheck(event){
    this.localities.forEach((l) => {
      if (l.check == true) {
        if(!this.selected_locs_id.includes(l.id)){
          this.selected_locs.push(l)
          this.selected_locs_id.push(l.id)
        }
      } else{
        if(this.selected_locs_id.includes(l.id)){
          let inx=this.selected_locs_id.indexOf(l.id)
          this.selected_locs.splice(inx,1)
          this.selected_locs_id.splice(inx,1)
        }

      }
   })
  }

  setLocalityWithCheck(value) {

    //let locs = []
    //this.localities.forEach((l) => { if (l.check == true) { locs.push(l.id) } })
    this.localityService.joinTeamAndLocality(this.selected_locs_id, this.data_team_current.id).subscribe((res: any) => {
      if (res.code == 200) {
        this.ngOnInit()
        this.modalService.dismissAll()
        //window.location.reload()
        AlertNotif.finishConfirmWithReload("Localités ajoutées", "Localités ajoutées à la nouvelle team", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }

  setTeam(event, user_id) {

  }
  makeAnalyse(user_id) {
    this.userService.getAnalyse(user_id).subscribe((res: any) => {
      if (res.code == 200)
        if (res.data.includes("NO_LOCALITY_IN_TEAM")) {
          AlertNotif.finish("Analyse", "L'equipe de cet utilisateur n'a pas de localité. Associé une localité a cette equipe pour valider ce compte", 'info')
        }
      if (res.data.includes("NO_TEAM")) {
        AlertNotif.finish("Analyse", "Ce compte n'est pas associé a une equipe", 'info')
      }
      if (res.data.includes("NO_ORGANIZATION")) {
        AlertNotif.finish("Analyse", "Ce compte n'est pas associé a une organisation", 'info')
      }

      // TODO AlertNotif finishConfirmWithReload alternative without reload
    })
  }
  setRole(event, user_id) {
    this.roleService.joinUserToRole({ roleId: event.target.value, userId: user_id }).subscribe((res: any) => {
      this.ngOnInit()
    })
  }

  enable(id) {
    this.userService.update({ enabled: true }, id).subscribe((res: any) => {
      this.ngOnInit()

      AlertNotif.finish("Nouvelle modification", "Un element activer avec succès", 'success')
    }, (err) => {
      AlertNotif.finish("Nouvelle modifsication", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })

  }
  disable(id) {
    this.userService.update({ enabled: false }, id).subscribe((res) => {
      this.ngOnInit()

      AlertNotif.finish("Nouvelle modification", "Un element desactiver avec succès", 'success')
    }, (err) => {
      AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })
  }

  validateWithNewTeam() {
    this.teamService.addTeamMember([this.data_current.id], "null", this.user.organisation.id).subscribe((res: any) => {
      if (res.code == 200) {
        this.modalService.dismissAll();
        this.apiGetUserNotValidatedTreatmentRefresh();
        AlertNotif.finish("Validation", "validation éffectué avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }
  validate(team) {

    this.teamService.addTeamMember([this.data_current.id], team.id, this.user.organisation.id).subscribe((res: any) => {
      if (res.code == 200) {
        //window.location.reload()
        this.modalService.dismissAll()
        AlertNotif.finishConfirmWithReload("Validation", "validation éffectué avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }

  selectSingle(event, el) {
    this.check_all = false
    console.log(el.check)
    if (el.check == true) {
      this.show_group_validate = true
      this.members.splice(0, 0, el.id)
    } else {
      this.members.splice(this.members.findIndex((m) => (el.id == m)), 1)
    }
    if (this.members.length = 0) {
      this.show_group_validate = false
    } else {
      this.show_group_validate = true
    }
  }

  selectAll(event) {
    this.members = []
    let temp = []
    if (this.check_all == true) {
      this.show_group_validate = true
      this.members = []
      this.data.forEach((e: any) => {
        e.check = true
        temp.push(e)
      })
      this.data = temp
    } else {
      this.show_group_validate = false
      this.members = []
      this.data.forEach((e: any) => {
        e.check = false
        temp.push(e)
      })
      this.data = temp
    }

  }
  validateGroup(value) {
    this.members = []
    this.data.forEach((e: any) => {
      if (e.check) {
        this.members.push(e.id)
      }
    })
    let team = value.team == "null" ? null : value.team
    this.teamService.addTeamMember(this.members, team, this.user.organisation.id).subscribe((res: any) => {
      if (res.code == 200) {
        this.ngOnInit()
        AlertNotif.finish("Validation", "validation éffectué avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    })
  }



  refreshUserNotValidateList(){
  }


  apiGetUserNotValidatedTreatment(){
      this.data = [];
      this._temp = [];
      this.activatedRoute.snapshot.data.user_list.data.forEach((e) => {
        e.new = 0;
        e.check = false
        e.role = e.role==null ? '' : this.roleService.getRole(e.role)
        this.data.push(e);
      })
      this._temp = this.data;
      this.collectionSize = this.data.length;
  }




  apiGetUserNotValidatedTreatmentRefresh(){
      let localServerOut = this.userService.getAllMobileUser(this.user.organisation.id);

      localServerOut.subscribe(
        (success:any) => {
          if(success.code == 200){
            let oldUserIdSave = this.data.map( u => u.id);
            console.log("old size = " + oldUserIdSave.length);
            this.data = [];
            this._temp = [];
            console.log("new size = " + success.data.length);

            success.data.forEach((e) => {
              console.log("User id " + e.id + " nom " + e.surname + " is new = " + (oldUserIdSave.includes(e.id) ? 1 : -1))
                  e.new =  oldUserIdSave.includes(e.id) ? 1 : -1;
                  e.check = false
                  e.role = e.role==null ? '' : this.roleService.getRole(e.role)
                  this.data.push(e)
            })
            this._temp = this.data;
            this.collectionSize = this.data.length;
          }else{
            console.log("Server Error...")
          }
        },
        (failed) => {
          console.log("Internal Error...");
        }
      );
  }

}
