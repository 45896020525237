import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Config} from '../../app.config';
import {catchError, tap, map, delay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Record} from '../_models/interview.model';


@Injectable({
  providedIn: 'root'
})
export class InterviewService {


  //url=Config.toApiUrl("organizations");
  url = 'api/sync/records';

  constructor(private http: HttpClient) {
  }


  //getAll(){

  //return this.http.get<any[]>(`${this.url}`, Config.httpHeader(localStorage.getItem("userToken"),true));
  //}

  getAllComment(recordingRef): Observable<any[]> {

    return this.http.get<any[]>(this.url + `/${recordingRef}/comments`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }

  getAll(page, pageSize, idOrganization): Observable<Record[]> {
    console.log("getAll", page, pageSize, idOrganization, localStorage.getItem('filterParams'))
    return this.http.get<Record[]>(this.url + '/' + page + '/' + pageSize + '/by-org/' + idOrganization, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }
  getFilterAll(page, pageSize, idOrganization,city,team,commune,arr): Observable<Record[]> {
    console.log("getFilterAll", page, pageSize, idOrganization, city, team, commune, arr)
    if(team!=null && commune!=null && arr!=null && city!=null){
      return this.http.get<Record[]>(this.url + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}&arrondissements=${arr}&villes=${city}`, { //
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune!=null && arr!=null && city==null){
      return this.http.get<Record[]>(this.url + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}&arrondissements=${arr}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune!=null && arr==null && city==null){
      return this.http.get<Record[]>(this.url + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune==null && arr==null && city==null){
      return this.http.get<Record[]>(this.url + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else{
      return this.http.get<Record[]>(this.url + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+'', {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }


  }

  getAllReject(page, pageSize, idOrganization): Observable<Record[]> {

    return this.http.get<Record[]>('api/records/rejected' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }

  getAllFilterReject(page, pageSize, idOrganization,city,team,commune,arr): Observable<Record[]> {

    if(team!=null && commune!=null && arr!=null && city!=null){
      return this.http.get<Record[]>('api/records/rejected' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}&arrondissements=${arr}&villes=${city}`, { //
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune!=null && arr!=null && city==null){
      return this.http.get<Record[]>('api/records/rejected' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}&arrondissements=${arr}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune!=null && arr==null && city==null){
      return this.http.get<Record[]>('api/records/rejected' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune==null && arr==null && city==null){
      return this.http.get<Record[]>('api/records/rejected'+ '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else{
      return this.http.get<Record[]>('api/records/rejected' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+'', {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }


  }

  getAllFilterValidate(page, pageSize, idOrganization,city,team,commune,arr): Observable<Record[]> {

    if(team!=null && commune!=null && arr!=null && city!=null){
      return this.http.get<Record[]>('api/records/validated' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}&arrondissements=${arr}&villes=${city}`, { //
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune!=null && arr!=null && city==null){
      return this.http.get<Record[]>('api/records/validated' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}&arrondissements=${arr}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune!=null && arr==null && city==null){
      return this.http.get<Record[]>('api/records/validated' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune==null && arr==null && city==null){
      return this.http.get<Record[]>('api/records/validated'+ '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else{
      return this.http.get<Record[]>('api/records/validated' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+'', {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }


  }



  getAllValidate(page, pageSize, idOrganization): Observable<Record[]> {

    return this.http.get<Record[]>('api/records/validated' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }

  getAllPostValidation(page, pageSize, idOrganization): Observable<Record[]> {

    return this.http.get<Record[]>('api/sync/post-validation/records' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }

  getAllPostFilterValidation(page, pageSize, idOrganization,city,team,commune,arr): Observable<Record[]> {

    if(team!=null && commune!=null && arr!=null && city!=null){
      return this.http.get<Record[]>('api/sync/post-validation/records' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}&arrondissements=${arr}&villes=${city}`, { //
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune!=null && arr!=null && city==null){
      return this.http.get<Record[]>('api/sync/post-validation/records' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}&arrondissements=${arr}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune!=null && arr==null && city==null){
      return this.http.get<Record[]>('api/sync/post-validation/records' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}&communes=${commune}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else if(team!=null && commune==null && arr==null && city==null){
      return this.http.get<Record[]>('api/sync/post-validation/records'+ '/' + page + '/' + pageSize + '/by-org/' + idOrganization+
      `?teams=${team}`, { //&teams=${team}&communes=${commune}&arrondissements=${arr}
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }else{
      return this.http.get<Record[]>('api/sync/post-validation/records' + '/' + page + '/' + pageSize + '/by-org/' + idOrganization+'', {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      }).pipe(delay(1000));
    }


  }


  addComment(ressource): Observable<any[]> {

    return this.http.post<any>('api/sync/comments', ressource, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  deleteComment(comment_id): Observable<any[]> {

    return this.http.delete<any>('api/sync/comments/' + comment_id, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  markCommentAsRead(comment_id): Observable<any[]> {

    return this.http.put<any>('api/sync/comments/' + comment_id + '/by/bo-user', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  updateComment(ressource): Observable<any[]> {

    return this.http.put<any>('api/sync/comments/by/bo-user', ressource, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }


  get(recordingRef): Observable<any> {
    console.log('#### call ' + this.url + `/${recordingRef}/details`);
    return this.http.get<any>(this.url + `/${recordingRef}/details`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));
  }

  getByLong(id_socio): Observable<any> {
    console.log('#### call ' + this.url + `/${id_socio}/details`);
    return this.http.get<any>('api/records' + `/${id_socio}/details`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));
  }


  validatePostValidation(recordingRef) {

    return this.http.post<any[]>('api/records/post-validation/record' + `/${recordingRef}/validate`, {}, Config.httpHeader(localStorage.getItem('userToken'), true));
  }


  validateGroupPostValidation(recordingRefs) {

    return this.http.post<any[]>(`api/sync/post-validation/validate?recordingRefs=${recordingRefs}`, {}, Config.httpHeader(localStorage.getItem('userToken'), true));
  }


  rejectGroupValidation(recordingRefs) {
    return this.http.post<any[]>('api/records/post-validation/reject?recordRefs=' + recordingRefs, 'Rejet pour correction', Config.httpHeader(localStorage.getItem('userToken'), true));
  }


  rejectGroupPostValidation(recordingRefs) {
    return this.http.post<any[]>('api/sync/post-validation/records/reject?recordingRefs=' + recordingRefs, 'Rejet pour correction', Config.httpHeader(localStorage.getItem('userToken'), true));
  }

  rejectPostValidation(recordingRef) {

    return this.http.post<any[]>('api/records/post-validation/record' + `/${recordingRef}/reject`, 'Rejet pour correction', Config.httpHeader(localStorage.getItem('userToken'), true));
  }

  validate(recordingRefs) {

    return this.http.post<any[]>(this.url + `/validate?recordingRefs=${recordingRefs}`, {}, Config.httpHeader(localStorage.getItem('userToken'), true));
  }

  simpleValidatePostValidationInterface(recordingRefs) {

    return this.http.post<any[]>(`api/sync/post-validation/records/${recordingRefs}/validate`, {}, Config.httpHeader(localStorage.getItem('userToken'), true));
  }

  simpleRejectPostValidationInterface(recordingRefs) {

    return this.http.post<any[]>(`api/sync/post-validation/records/${recordingRefs}/reject`, {}, Config.httpHeader(localStorage.getItem('userToken'), true));
  }


  errorReport(session, status) {
    if (status == null) {
      return this.http.get<any[]>(`api/shapefiles/errors/report?session=${session}`, Config.httpHeader(localStorage.getItem('userToken'), true));
    } else {
      return this.http.get<any[]>(`api/shapefiles/errors/report?session=${session}&status=${status}`, Config.httpHeader(localStorage.getItem('userToken'), true));
    }
  }

  reject(recordingRefs) {

    return this.http.post<any[]>(this.url + `/reject?recordingRefs=${recordingRefs}`, {}, Config.httpHeader(localStorage.getItem('userToken'), true));
  }


  groupRejectValidationInterface(recordingRefs) {
    return this.http.post<any[]>(`api/records/post-validation/records/reject?recordRefs=${recordingRefs}`, {}, Config.httpHeader(localStorage.getItem('userToken'), true));
  }


  simpleRejectValidationInterface(recordingRefs) {

    return this.http.post<any[]>('api/records/post-validation/reject?recordRefs=' + recordingRefs, 'Rejet pour correction', Config.httpHeader(localStorage.getItem('userToken'), true));
    //return this.http.post<any[]>(`api/records/post-validation/record/${recordingRefs}/reject`, "Rejet pour correction" , Config.httpHeader(localStorage.getItem("userToken"),true));
  }

  correctRecord(ressource) {
    return this.http.put<any>(`${this.url}/correct`, ressource, Config.httpHeader(localStorage.getItem('userToken'), true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }

  create(ressource) {
    return this.http.post<any>(`${this.url}`, ressource,
      Config.httpHeader(localStorage.getItem('userToken'), true)).pipe(
      tap((ressource: any) => console.log(`added ressource ${ressource}`))
    );
  }

  update(ressource, id) {
    return this.http.put<any>(`${this.url}/${id}`, ressource, Config.httpHeader(localStorage.getItem('userToken'), true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }

  update_partial(ressource, id) {
    return this.http.patch<any>(`${this.url}/${id}`, ressource, Config.httpHeader(localStorage.getItem('userToken'), true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }

  delete(id: number) {
    return this.http.delete<any[]>(`${this.url}/${id}`, Config.httpHeader());
  }

  consolidationInit(ressource) {

    return this.http.post<Record[]>(`api/shapefiles/init?locality_id=${ressource.locality_id}&organization_id=${ressource.organization_id}&team_id=${ressource.team_id}`, {}, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }

  downloadList() {

    return this.http.get<Record[]>('api/shapefiles', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }

  consolidationManuel(session) {

    return this.http.get<any>(`api/shapefiles/${session}/consolidations`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  storageSessionData(session, parcel) {

    return this.http.get<any>('api/shapefiles/' + session + '/parcels/' + parcel + '/detail', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  consolidationUnitaire(session, id_parcel, id_socio) {

    return this.http.get<any>(`api/shapefiles/consolidate/parcels/one?session=${session}&id_parcel=${id_parcel}&id_socio=${id_socio}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  consolidationUnitaireSocio(id_socio) {

    return this.http.get<any>(`api/shapefiles/consolidate/socio-records/one?socio=${id_socio}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }


  echecsocio(team) {

    return this.http.get<any>(`api/records/unconsolidated/of/${team}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  shapefilesData(session) {

    return this.http.get<any>(`api/shapefiles/${session}/consolidations`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  consolidationDetails(session) {

    return this.http.get<Record[]>(`storage/files/consolidations?session=${session}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }


  download(session): any {

    return this.http.get(`api/shapefiles/download?session=${session}`, {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    }).pipe(delay(1000));

  }

  consolidationUpload(session, formData, forced) {
    return this.http.post<any>(`api/shapefiles/upload?forced=${forced}&session=${session}`, formData, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });
  }

  replaceConsolidationUpload(session, formData, forced) {
    return this.http.post<any>(`api/shapefiles/upload/replace?forced=${forced}&session=${session}`, formData, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });
  }


  // Record uncosolidated https://aigba-bo.owomi.com/api/records/unconsolidated

  recordunconsolidated() {
    return this.http.get<any>('api/records/unconsolidated', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });
  }


  getStat(organizationId, defaultFilter, localityId) {

    return this.http.get<any>(`/api/records/report/${localityId}/filtered-by/${defaultFilter}/about-org/${organizationId}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      });

  }

  getAllParcelle() {

    return this.http.get<any>(`api/shapefiles/parcelles/all`, {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  getAllParcelleFilterKey(key) {

    return this.http.get<any>(`api/shapefiles/parcelles/distinct/${key}`, {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  shapefilesDetails(session, state) {

    if (state == 'all') {
      return this.http.get<any>(`api/shapefiles/detail?session=${session}`, {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      });
    } else {
      return this.http.get<any>(`api/shapefiles/detail?session=${session}&state=${state}`, {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      });
    }

  }

  setParcelNumber(session, parcellenumber, no) {


    return this.http.get<any>(`api/shapefiles/parcelles/${session}/${parcellenumber}?no=${no}`, {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });
  }

  setParcelData(session, parcellenumber, no, familyname, firstname1, firstname2) {


    return this.http.post<any>(`api/shapefiles/parcelles/modify?familyname=${familyname}&firstname1=${firstname1}&firstname2=${firstname2}&newparcelleno=${no}&parcelleNumber=${parcellenumber}&session=${session}`, {}, {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });
  }


  getAllParcelleFilterKeyValue(key, value) {

    return this.http.get<any>(`api/shapefiles/parcelles/${key}?value=${value}`, {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  getAllFilterStatistic(team, locality, id_organization) {

    console.log('**********************************');
    console.log(team);
    console.log(locality);
    console.log(id_organization);
    console.log('**********************************');

    if (locality == null && team == null && id_organization == null) {
      return this.http.get<any>(`api/shapefiles/statistic`, {
        headers: new HttpHeaders({
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + localStorage.getItem('userToken')
        })
      });
    }
    if (id_organization != '' && id_organization != undefined && id_organization != null) {
      if (team != '' && team != undefined && team != null) {
        if (locality != '' && locality != undefined && locality != null) {
          return this.http.get<any>(`api/shapefiles/statistic?id_team=${team}&id_organization=${id_organization}&id_locality=${locality}`, {
            headers: new HttpHeaders({
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('userToken')
            })
          });
        } else {
          return this.http.get<any>(`api/shapefiles/statistic?id_team=${team}&id_organization=${id_organization}`, {
            headers: new HttpHeaders({
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('userToken')
            })
          });
        }

      } else {
        return this.http.get<any>(`api/shapefiles/statistic?id_organization=${id_organization}`, {
          headers: new HttpHeaders({
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + localStorage.getItem('userToken')
          })
        });
      }

    }


  }


  getParcelleInfoByParcelleNumber(parcelleNumber) {

    return this.http.get<any>(`api/shapefiles/parcelles?parcelleNumber=${parcelleNumber}`, {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }

  getParcelleNeighbourInfoByParcelleNumber(parcelleNumber) {

    return this.http.get<any>(`api/shapefiles/parcelles/neighbour/${parcelleNumber}`, {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });

  }


  consolidationFailed() {
    return this.http.get<any>('api/shapefiles/failed', {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('userToken')
      })
    });
  }

}
