import { Component, OnInit, Input } from '@angular/core';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { FormControl } from '@angular/forms';

import { Observable, BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import {Config} from './../app.config';
import {AlertNotif} from './../alert';
import {Team} from './../core/_models/team.model';
import {TeamService} from './../core/_services/team.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationService } from '../core/_services/organization.service';
import { UserService } from '../core/_services/user.service';
import { User } from '../core/_models/user.model';
import { Organization } from '../core/_models/organization.model';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
declare var $: any;

@Component({
  selector: 'app-team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.css']
})
export class TeamMembersComponent implements OnInit {


  page = 1;
  pageSize = 4;
  searchText=""
  closeResult = '';
  error=""
  data: any[]=[];
  _temp: any[]=[];


  selected = [
  ];
  collectionSize = 0;
  teams:any[]=[]
  team=new Team()
  usersSubject:BehaviorSubject <any[]> = new BehaviorSubject<any[]> ([])

  organisations:any[]=[]
  users:any[]=[]

  search(){
    this.data=this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.designation.toLowerCase().includes(term) ||
       r.cote.toString().includes(term)
    })
  }


  openUpdateModal(content,el) {
   // this.data_current=el
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openAddModal(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  id=""

  constructor(
    private TeamService: TeamService,
    private organisationService: OrganizationService,
    private usersService: UserService,
    private router:Router,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute) {}

    dropdownList = [];
    selectedItems = [];
    dropdownSettings:IDropdownSettings = {};

  ngOnInit() {
    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')

    this.id=this.activatedRoute.snapshot.paramMap.get('id');
    this.TeamService.get(this.id).subscribe((res:any)=>{
      this.team=new Team(res.data)
      this.data=res.data.members
      this._temp=this.data
      this.collectionSize=this.data.length
    })
    this.data=[]
    this._temp=[]

    this.dropdownSettings = {
      idField: 'id',
      singleSelection: false,
      textField: 'username',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };


  }

  loadUser(event){
    this.usersService.getAll().subscribe((res:any)=>{
      let temp=[]
      res.data.forEach((e)=>{
        if(event.target.value==e.organisation.id){
          e.username=e.forename+" "+e.surname
          temp.push(new User(e))
        }
      })
      this.usersSubject.next(temp)
    })

    this.usersSubject.asObservable().subscribe((data) => {
      this.users=data
    });
  }

  create(value){
   let members=[]
    this.selectedItems.forEach((data) => {
     members.push(data.id)
   })
   value.members=members
    this.TeamService.create(value).subscribe((res:any)=>{
     AlertNotif.finish("Nouvel ajout","Ajout éffectué avec succès" , 'success')
      this.ngOnInit()
    },(err)=>{

      if(err.error.detail!=null){
        AlertNotif.finish("Nouvel ajour", err.error.detail, 'error')
      }else{
        AlertNotif.finish("Nouvel ajout","Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }


  update(value){

  /*  this.TeamService.update_partial(value,this.data_current.id).subscribe((res:any)=>{
     AlertNotif.finish("Nouvelle modification","Un element modifié avec succès" , 'success')
      this.ngOnInit()
    },(err)=>{

      if(err.error.detail!=null){
        AlertNotif.finish("Nouvelle modification", err.error.detail, 'error')
      }else{
        AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })*/
  }
  enable(id,index) {
    this.TeamService.update_partial(id,{status:true}).subscribe((res:any)=>{
      AlertNotif.finish("Nouvelle modification", "Un eelement activer avec succès", 'success')
      this.ngOnInit()
    }, (err)=>{
      AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })

   }
  disable(id,index){
      this.TeamService.update_partial(id,{status:false}).subscribe((res)=>{
        AlertNotif.finish("Nouvelle modification", "Un element desactiver avec succès", 'success')
        this.ngOnInit()
      }, (err)=>{
        AlertNotif.finish("Nouvelle modification", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      })
   }
   delete(id,index){
    this.TeamService.delete(id).subscribe((res:any)=>{
      this.data.splice(index,1)
      AlertNotif.finish("Nouvelle suppression", "Suppression effectué avec succès", 'success')
      this.ngOnInit()
    }, (err)=>{
      AlertNotif.finish("Nouvelle suppression", "Une erreur est survenue, verifier votre connexion SVP", 'error')
    })
 }
  archive(id,index){
    AlertNotif.finishConfirm("Nouvelle suppression",
    "Voulez-vous continuer ?").then((result) => {
      if (result.value) {
      this.TeamService.delete(id).subscribe((res:any)=>{
        this.data.splice(index,1)
        AlertNotif.finish("Nouvelle suppression", "Suppression effectué avec succès", 'success')
        this.ngOnInit()
      }, (err)=>{
        AlertNotif.finish("Nouvelle suppression", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      })
    }
   })
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
