<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                <br>
            </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Consolidation - Liste des fichiers</h4>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-between">
                          
                            <a routerLink="/consolidation" class="btn btn-outline-primary">Liste des consolidations</a>
                         
                            <a routerLink="/consolidation-failed" class="btn btn-outline-primary">Echec topo</a>
                            <a routerLink="/unconsolidation-record" class="btn btn-outline-primary">Echec socio</a>


                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="page-title"></h1>
                    </div>
                    <div class="col-md-6 d">

                    </div>
                </div>
                <div class="row">

                    <div class="col-md-12 mx-auto">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>

                                            <th>Nombre de fichier</th>

                                            <th class="hidden-xs">Uploader</th>
                                            <th class="hidden-xs">Creer le</th>
                                            <th class="hidden-xs">Finaliser le</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">

                                            <td>
                                                {{el.fileCount}}
                                            </td>

                                            <td class="hidden-xs">
                                                {{el.uploaded ? 'Oui' : 'Non '}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{el.createdAt| date:'short'}}
                                            </td>
                                            <td>
                                                {{el.closedAt| date:'short'}}
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button *ngIf="current_role==Superviseur" ngbDropdownItem>Initialiser</button>
                                                        <button ngbDropdownItem>Voir fichiers</button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
            
        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>


    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->