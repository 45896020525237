import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import jsPDF from 'jspdf';
import * as html2pdf from 'html2pdf.js';
import { ActivatedRoute } from '@angular/router';
import { RepertoriesService } from '../core/_services/repertories.service';
import { LocalityService } from '../core/_services/locality.service';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.css']
})
export class PrintReportComponent implements OnInit {
  localityId: string;
  data: any;
  locality: any;

  constructor(private activatedRoute:ActivatedRoute,
    private reportService:RepertoriesService,private localityService: LocalityService,) { }

  ngOnInit(): void {
    this.localityId=this.activatedRoute.snapshot.paramMap.get('localityId');
    this.reportService.getReport(this.localityId).subscribe((res:any)=>{
      this.data = res.data.elements
      console.log("Rapport",res)
    })
    this.getLocality(this.localityId)
    //Imprimer après 5s
    setTimeout(function(){
      window.print();
    },10000)
  }
  getLocality(id){
    this.localityService.get(id).subscribe((res:any)=>{     
      this.locality=res.data
      console.log("Detail localité",res)
     })
  }
  @ViewChild('content') content:ElementRef;
  
  

  // public SavePDF(): void {  
  //   let content=this.content.nativeElement;  
  //   let doc = new jsPDF();  
  //   console.log(this.content);
  //   let _elementHandlers =  
  //   {  
  //     '#editor':function(element,renderer){  
  //       return true;  
  //     }  
  //   };  
  //   doc.fromHTML(content.innerHTML,15,15,{  
  
  //     'width':190,  
  //     'elementHandlers':_elementHandlers  
  //   });  
  
  //   doc.save('test.pdf');  
  // } 

  downloadPdf(){
    const options = {
      name: 'report.pdf',
      image : {type: 'jpeg'},
      html2canvas: {},
    }

    const element: Element = document.getElementById('content')
    
    html2pdf()
      .from(element)
      .set(options)
      .save()
  }

}
