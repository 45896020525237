import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private injector: Injector) { }

  intercept(req: HttpRequest<any>, next) {
    let content_type = "application/json"
    if ((req.url.toLowerCase().includes("auth/accesstoken")) && req.method.toLowerCase() === "post") {
      content_type = "application/x-www-form-urlencoded"
    }
    let headers: any;
    if ((req.url.toLowerCase().includes("api/shapefiles/upload") || (req.url.toLowerCase().includes("/api/shapefiles/upload"))) && req.method.toLowerCase() === "post") {
      headers = req.headers
        .set('Access-token', `${sessionStorage.getItem('token')}`)
    } else {
      headers = req.headers
        .set('Access-token', `${sessionStorage.getItem('token')}`)
        .set("Content-Type", content_type);
    }


    const cloneReq = req.clone({ headers });

    return next.handle(cloneReq).pipe(tap(() => { },
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this.router.navigate(['/home']);
        }
      }));;
  }

}
