import { Component, OnInit,Directive, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import * as html2pdf from 'html2pdf.js';
import { ActivatedRoute, Router } from '@angular/router';
import { RepertoriesService } from '../core/_services/repertories.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LocalityService } from '../core/_services/locality.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BehaviorSubject } from 'rxjs';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { Organization } from '../core/_models/organization.model';
import { OrganizationService } from '../core/_services/organization.service';
import { Alert } from 'selenium-webdriver';
import { AlertNotif } from '../alert';
declare var $: any;
import * as fileSaver from 'file-saver';

import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import {NgbdSortableHeader, SortEvent} from './../sortable.directive';

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;



@Component({
  selector: 'app-repertoire',
  templateUrl: './repertoire.component.html',
  styleUrls: ['./repertoire.component.css']
})
export class RepertoireComponent implements OnInit {


  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({column, direction}: SortEvent) {

    console.log(column,direction)
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    // sorting countries
    if (direction === '' || column === '') {
      this.data = this._temp;
    } else {
      this.data = [...this._temp].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }




  page = 0;
  pageSize = 10;
  page2 = 1;
  pageSize2 = 100;
  term: any
  p: any
  no_print=false
  localityId: any
  data: any = []
  localities: any
  closeResult = ''
  collectionSize = 0;
  collectionSize2 = 0;
  _temp2: any[] = [];
  _temp: any[] = [];
  searchText = ""
  selectedLocality: any
  locality: any;
  acquisitionMode: any
  dtOptions: any = {};
  selectedValue: any;
  loading: any = false
  dropdownList = [];
  selectedItems: any[] = [];
  selectedItems2: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings2: IDropdownSettings = {};
  localitiessSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  role = ""
  user: any
  local: void;
  acquisitionModeSelected: any=[]

  rigthTypes: Array<any> = []
  rigthTypesSelected: any=[]

  domains: Array<any> = [
    { id: "Domaine_public_naturel", libelle: "Domaines publics naturels" },
    { id: "Domaine_public_artificiel", libelle: "Domaines publics artificiels" },
    { id: "Domaine_prive", libelle: "Domaines privés" },
    { id: "Parcelle_privee", libelle: "Parcelle privée" },

  ]
  domainsSelected: any=[]

  finalize = {
    finalise: "finalise",
    nonFinalise: 'nonFinalise',
    all: "all"
  }
  finalized: any
  geojson="geojson"
  selected_old=""
  shapefiles="shp"

  filterBody = {
    "parcelContested": "",
    "parcelLocalities": "",
    "parcelStatues": "",
    "personAges": "",
    "personNationality": "",
    "personSex": "",
    "personTypes": "",
    "rightAcquisitionMethods": "",
    "rightTypes": ""
  }

  Null = null

  stat={
    totalNumberConsolidatedParcel: 0,
    totalNumberNonConsolidatedParcel: 0,
    totalNumberNonConsolidatedVillage: 0,
    totalNumberNonValidatedParcel: 0,
    totalNumberNonValidatedVillage: 0,
    totalNumberParcel: 0,
    totalNumberPersonRight: 0,
    totalNumberValidatedParcel: 0,
    totalNumberVillage: 0,
  }

  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.firstname.toLowerCase().includes(term) ||
      r.name.toLowerCase().includes(term) ||
      r.nationality.toLowerCase().includes(term) ||
      r.parcelNumber.toString().toLowerCase().includes(term)
      ||
      r.parcelNumber.toString().toLowerCase().includes(term)
      ||
      r.parcelCode.toString().toLowerCase().includes(term)
      ||
      r.acquisitionMethod.toString().toLowerCase().includes(term)
      ||
      r.surface.toString().toLowerCase().includes(term)
      ||
      r.address.toString().toLowerCase().includes(term)
      ||
      r.phone.toString().toLowerCase().includes(term)
      ||
     ( r.parcelType==null ?'' :r.parcelType.toString()).toLowerCase().includes(term)


    })
    this.collectionSize = this.data.length
  }

  openAddModal(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  orgs = []

  departements=[]
  methodAcquisition = [
    { id: "Achat", libelle: "Achat" },
    { id: "Don", libelle: "Don" },
    { id: "Heritage", libelle: "Héritage" },
    { id: "Legs", libelle: "Legs" },
    { id: "Testament", libelle: "Testament" },
    { id: "Libre_installation", libelle: "Libre installation" },
    { id: "Heritage_non_partager", libelle: "Héritage non partagé" },
    { id: "Attribution_coutumiere", libelle: "Attribution coutumière" },
    { id: "Echange", libelle: "Echange" },
    { id: "accession", libelle: "Accession" },
    { id: "incorporation", libelle: "Incorporation" },
    { id: "prescription", libelle: "Prescription" },
    { id: "other", libelle: "Autres" },
  ]

  showModeAcquisition(key){
    if(key=="" || key==null || key=="-") {
      return key
    }
    return this.methodAcquisition.find((e) => (e.id==key)).libelle
  }

  constructor(private activatedRoute: ActivatedRoute,
    private reportService: RepertoriesService,
    private localService: LocalService,private exportAsService: ExportAsService,
    private router:Router,private spinner: NgxSpinnerService,
    private organisationService: OrganizationService,
    private localityService: LocalityService,
    private modalService: NgbModal, ) { }

  ngOnInit(): void {
    $.getScript('assets/js/export.js')
    this.role = localStorage.getItem("userRole")
    this.user = this.localService.getJsonValue("userData")

    this.rigthTypes = [
      { id: "ownership", libelle: "Propriété(TF)" },
      { id: "ownershipPresumed", libelle: "Propriété présumée" },
      { id: "usufruct", libelle: "Usufruit" },
      { id: "rightOfUse", libelle: "Droit d’usage" },
      { id: "residence", libelle: "Droit d’habitation" },
      { id: "superficies", libelle: "Droit de superficie" },
      { id: "privileges", libelle: "Privilèges" },
      { id: "lease", libelle: "Baux emportant droits réels immobiliers" },
      { id: "tenancy", libelle: "Location" },
      { id: "cropshare", libelle: "Métayage" },
      { id: "loan", libelle: "Prêt" },
    ]
    //this.localityId=this.activatedRoute.snapshot.paramMap.get('localityId');
    /* this.reportService.getReport(this.localityId).subscribe((res:any)=>{
       this.data = res.data.elements
       console.log("Rapport",res)
     })
     this.getLocality(this.localityId)
   */

    this.localityService.getAll().subscribe((res: any) => {
      let temp = []
      res.data.forEach((e) => {
        e.check = false
        temp.push(e)
      })
      this.localitiessSubject.next(temp)
      console.log(res.data)
    })
    this.localitiessSubject.asObservable().subscribe((data) => {
      this.localities = []

      this.localities = data
      this._temp2 = this.localities
      this.collectionSize2 = this.localities.length

      console.log(this.localities)
    });

    this.dropdownSettings = {
      idField: 'id',
      singleSelection: false,
      textField: 'libelle',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      enableCheckAll: true
    };

    this.departements = []
    this.localityService.getDepartement().subscribe((res: any) => {
      this.departements = res.data
    })



    let ressource = {
      parcelContested: [],
      parcelLocalities: [],
      parcelStatues: null,
      personAges: [],
      personNationality: [],
      personSex: [],
      personTypes: [],
      domainTypes: [],
      rightAcquisitionMethods: [],
      rightTypes: [],
      wantedStats: [],
      withTF: null
    }


    if(this.user.organisation==null){
      this.orgs=[]
      this.organisationService.getAll().subscribe((res: any) => {
        res.data.forEach((e) => { this.orgs.push(new Organization(e)) })
        //this.users=res.data
        if(this.orgs.length>0){

          this.init(ressource,this.orgs[0].id)
        }
      })
    }else{
      this.init(ressource,this.user.organisation.id)
    }

  }
  selected_org=""
  init(ressource,org){
    this.selected_org=org
    this.data=[]
    this._temp=[]
    this.spinner.show()
    this.reportService.filtrer(
      org
      ,false,ressource).subscribe((res: any) => {
       this.spinner.hide()
        this.data = []

        if((res.code==404 && res.msg=="REPORT_ELEMENTS_NOT_FOUND") || res.data.elements.length==0 || res.data==null){
          AlertNotif.finish("Filtre repertoire", "Aucne donnée n'a été trouvée", 'success')
        }else{

          this.data = res.data.elements
          this._temp=this.data
          this.collectionSize=this.data.length
          this.stat = res.data.statsWs
          this.modalService.dismissAll()

        }
     })


  }

  communes = []
  loadCommunues(event) {
    this.communes = []
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.communes = res.data
    })
  }

  arrondissements = []

  loadArrondissement(event) {
    this.arrondissements = []
    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.arrondissements = res.data
    })
  }
  loadLocality(event) {

    this.localityService.getLocalitiesIn(+event.target.value).subscribe((res: any) => {
      this.localitiessSubject.next(res.data)
    })
  }
  getLocality(id) {
    this.localityService.get(id).subscribe((res: any) => {
      this.locality = res.data
      console.log("Detail localité", res)
    })
  }
  filtrer() {
    console.log("loc sel", this.selectedItems)
    console.log("meth sel", this.acquisitionModeSelected)
    console.log("finalized sel", this.finalized)
    let statOnly = false
    // 3e parametre , l'objet body de la requete
    this.reportService.filtrer(this.user.organisation.id, statOnly, 3).subscribe((res: any) => {
      this.locality = res.data
      console.log("Detail localité", res)
    })

  }

  filter(value) {
    let locs = []
    this.selectedItems.forEach((l) => {
      locs.push(l.id)
    })
    let types = []
    this.rigthTypesSelected.forEach((l) => {
      types.push(l.id)
    })
    let acquisitions = []
    this.acquisitionModeSelected.forEach((l) => {
      acquisitions.push(l.id)
    })



    let personTypes = []
    if (value.naturalParson == true) {
      personTypes.push("naturalParson")
    }
    if (value.group == true) {
      personTypes.push("group")
    }
    if (value.nonNaturalPerson == true) {
      personTypes.push("nonNaturalPerson")
    }

    if(this.selectedItems.length==0){
      if(value.arrondissement=="" || value.arrondissement==undefined || value.arrondissement==null){
        if(value.commune=="" || value.commune==undefined || value.commune==null){
          if(value.departement=="" || value.departement==undefined || value.departement==null){
            locs=[]
          }else{
            locs=[+value.departement]
          }
        }else{
          locs=[+value.commune]
        }
      }else{
        locs=[+value.arrondissement]
      }
    }

    let ressource = {
      parcelContested: [],
      parcelLocalities: locs,
      parcelStatues: null,
      personAges: [],
      personNationality: [],
      personSex: [],
      personTypes: personTypes,
      domainTypes: [],
      rightAcquisitionMethods: acquisitions,
      rightTypes: types,
      wantedStats: [],
      withTF: null
    }


    if (value.litige == "0") {
      ressource.parcelContested = [false]
    }
    if (value.litige == "1") {
      ressource.parcelContested = [true]
    }
    if (value.litige == "2") {
      ressource.parcelContested = []
    }

    if (value.sex == "0") {
      ressource.personSex = [0]
    }
    if (value.sex == "1") {
      ressource.personSex = [1]
    }
    if (value.sex == "2") {
      ressource.personSex = []
    }
    if (value.sex == "null") {
      ressource.personSex = [null]
    }

    ressource.domainTypes = []
    this.domainsSelected.forEach((d) => {
      ressource.domainTypes.push(d.id);
    })

    if (value.finalized == "0") {
      ressource.parcelStatues = [false]
    }
    if (value.finalized == "1") {
      ressource.parcelStatues = [true]
    }
    if (value.finalized == "2") {
      ressource.parcelStatues = []
    }

    if (value.nationality == "0") {
      ressource.personNationality = ["etranger"]
    }
    if (value.nationality == "1") {
      ressource.personNationality = ["benin"]
    }
    if (value.nationality == "2") {
      ressource.personNationality = []
    }
    this.stat ={
      totalNumberConsolidatedParcel: 0,
      totalNumberNonConsolidatedParcel: 0,
      totalNumberNonConsolidatedVillage: 0,
      totalNumberNonValidatedParcel: 0,
      totalNumberNonValidatedVillage: 0,
      totalNumberParcel: 0,
      totalNumberPersonRight: 0,
      totalNumberValidatedParcel: 0,
      totalNumberVillage: 0,
    }
    if(value.old=='1'){
      ressource.personAges=[+value.old_min, +value.old_max]
    }
    if(value.old=='null'){
      ressource.personAges=[null, null]
    }
    if(value.old=='2'){
      ressource.personAges=[]
    }
    this.data = []
    this._temp=this.data
    this.collectionSize=this.data.length

    this.data=[]
    this._temp=[]
     this.reportService.filtrer(
       this.user.organisation==null ? value.org :  this.user.organisation.id
       ,false,ressource).subscribe((res: any) => {
         this.data = []

         if((res.code==404 && res.msg=="REPORT_ELEMENTS_NOT_FOUND") || res.data.elements.length==0 || res.data==null){
           AlertNotif.finish("Filtre repertoire", "Aucne donnée respectant les criètres spécifier n'a été trouvée", 'success')
         }else{

           this.data = res.data.elements
           this._temp=this.data
           this.collectionSize=this.data.length
           this.stat = res.data.statsWs
           this.modalService.dismissAll()

         }
      })



  }

  selectLocality() {
    this.reportService.getReport(this.selectedValue).subscribe((res: any) => {
      this.data = res.data.elements
      this.getLocality(this.selectedValue)
      this.localityId = this.selectedValue
      console.log("l_id", this.localityId)
      this.loading = false
      this.modalService.dismissAll()
    })
  }


  setLocalityWithCheck(value) {
    // let locs=[]
    // this.localities.forEach((l)=>{if(l.check==true){locs.push(l.id)}})
    // this.localityService.joinTeamAndLocality(locs,this.data_current.id).subscribe((res:any)=>{
    //     this.ngOnInit()
    //     this.modalService.dismissAll()
    //     window.location.reload()
    //     //AlertNotif.finish("Localités ajoutées", "Localités ajoutées à la nouvelle team", 'success')
    //  })
    console.log(value)
  }
  search2() {
    this.localities = this._temp2.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.libelle.toLowerCase().includes(term)
    })
    this.collectionSize2 = this.localities.length
  }
  openSelectLocalities(content) {
    //this.data_current=new Team(el)
    this.loading = true
    this.localityService.getAll().subscribe((res: any) => {
      this.localities = res.data
      this._temp2 = res.data
      this.loading = false
      console.log("Liste localités", this.localities)
    },
      (err) => {
        this.loading = false
        alert("Une erreur est survenue")
      }
    )
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: "lg" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  downloadPdf() {
    const options = {
      name: 'repertoire.pdf',
      image: { type: 'jpeg' },
      html2canvas: {},
      jsPDF: { unit: 'in', format: 'A3', orientation: 'landscape' }
    }

    const element: Element = document.getElementById('repertory')

    html2pdf()
      .from(element)
      .set(options)
      .save()
  }
  generatePDF() {
    this.no_print=true
    this.pageSize=10000000000
    var data = document.getElementById('repertory');
    html2canvas(data,{
      ignoreElements: function (node) {
        return node.nodeName === 'IFRAME';
      }
    }).then(canvas => {
      this.no_print=false
      var imgWidth = 208;
      this.pageSize=10
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('repertoire.pdf');
    });
  }

  exportAsConfig: ExportAsConfig = {
    type: 'xls', // the type you want to download
    elementIdOrContent: 'repertory',
    options: { // html-docx-js document options
      orientation: 'landscape',
      margins: {
        top: '20'
      }
    }
  }
  excel(){
    this.no_print=true
    this.pageSize=10000000000
    this.exportAsService.save(this.exportAsConfig, 'repertoires').subscribe(() => {
      // save started
      this.no_print=false
      this.pageSize=10
    });
  }


  download(extension) {
    this.reportService.download(extension).subscribe(response => {
      let blob: any = new Blob([response], { type: 'application/zip; charset=utf-8' });
      const url = URL.createObjectURL(blob);
      //window.open(url);
      //window.location.href = response.url;
      if(extension=="geojson"){
        fileSaver.saveAs(blob, 'geojson.zip');
      }else{
        fileSaver.saveAs(blob, 'shapefiles.zip');
      }
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
    //'application/zip'
  }
  viewExtrait(no, ref){
    this.modalService.dismissAll()
    this.router.navigateByUrl('/extrait-cadastral/'+ref+"/"+no)

  }
  splitInt(key){
    return key.toString().substr(0, 6)
  }
}
