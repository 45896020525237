<!-- Begin page -->
<div id="wrapper">

  <!-- Top Bar Start -->
  <div class="topbar">

    <!-- LOGO -->
    <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
      <a href="index.html" class="logo">
        <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block"
             alt="">
        <br>
      </a>
    </div>


    <app-header></app-header>


  </div>
  <!-- Top Bar End -->

  <!-- ========== Left Sidebar Start ========== -->

  <app-aside></app-aside>
  <!-- Left Sidebar End -->

  <!-- ============================================================== -->
  <!-- Start right Content here -->
  <!-- ============================================================== -->
  <div class="content-page">
    <!-- Start content -->
    <div class="content">
      <div class="container-fluid">
        <div class="page-title-box">
          <div class="row d-flex flex-column align-items-end">
            <div class="col-6">
              <ol class="breadcrumb float-right">
                <li class="breadcrumb-item">INFOCAD</li>
                <li class="breadcrumb-item active"><a routerLink="/interviews">Liste enquêtes</a></li>
                <li class="breadcrumb-item active">Details enquête</li>
              </ol>
            </div>
          </div> <!-- end row -->
        </div>
        <!-- end page-title -->
        <div class="row my-2 mb-5">
          <div class="col-4 col-md-4 d-flex justify-content-start">
            <button type="button" class="btn btn-secondary mr-4" (click)="back()">Retour</button>
            <a routerLink="/interviews" class="btn btn-primary text-white" role="button">Liste
              enquêtes</a>
          </div>
          <div class="col-8 col-md-8 d-flex justify-content-end ">
            <button *ngIf="current_role == Superviseur " [disabled]="!data.validationStatus==finalized"
                    (click)="validateRecord(ref)" class="btn btn-success mr-4">Valider
            </button>
            <button *ngIf="current_role==Superviseur " [disabled]="!data.validationStatus==finalized"
                    (click)="rejectRecord(ref)" class="btn btn-danger mx-1"> Rejeter
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <section class="card">
              <div class="card-body">
                <!-- <div class="widget-top-overflow text-white">
                    <div class="height-250 overflow-hidden">
                        <img class="img-fluid" src="assets/img/cadastre.jpeg">
                    </div>
                </div> -->
                <!-- <br><br> -->
                <h3 style="text-align: center;font-weight: bold;">FICHE DE RENSEIGNEMENT D’UNE PARCELLE
                </h3><br>
                <h5 style="text-align: center;">N° PROVISOIRE : <b
                  class="font-weight-bold">{{ data.parcel.iup }}</b></h5>
                <h6 style="text-align: center;">Localisation : {{ localisation() }}</h6>
                <h6 style="text-align: center;">Type de parcelle : <b class="font-weight-bold">{{ parcelType(data.parcel.type, data.parcel.nature, data.parcel.specificNature) }}</b></h6>
                <br><br>
                <div class="row mt-2" *ngIf="data.right.personRights!=Null">
                  <div *ngIf="data.right.personRights.length!=Zero">
                    <div class="col-sm-12">
                      <h5 class="font-weight-bold">Ayants droits</h5>
                      <table class="table" border="1"
                             *ngFor="let droit of data.right.personRights">
                        <tbody>
                        <tr>
                          <td rowspan="7">
                            <div class="d-flex justify-content-center">
                              <img class="font-weight-bold" class="img-fluid w-50"
                                   src="{{imaga_path(droit.person.photo,1)}}" alt=".0.">
                            </div>
                          </td>
                          <td colspan="3" class="font-weight-bold text-center">
                            {{droit.person.firstName}}
                            {{droit.person.name}}
                          </td>
                        </tr>
                        <tr>
                          <td>Nom de la mère :
                            <span class="font-weight-bold">
                              {{(droit.person.motherName != null && droit.person.motherName.trim().length != 0) ? droit.person.motherName : "-"}}
                            </span>
                          </td>
                          <td colspan="2">Prénom de la mère :
                            <span class="font-weight-bold">
                              {{(droit.person.motherFirstname != null && droit.person.motherFirstname.trim().length != 0) ? droit.person.motherFirstname : "-"}}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Sexe :
                            <span *ngIf="droit.person.sex == null" class="font-weight-bold">-</span>
                            <span *ngIf="droit.person.sex === 0" class="font-weight-bold">-</span>
                            <span *ngIf="droit.person.sex === 1" class="font-weight-bold">Masculin</span>
                            <span *ngIf="droit.person.sex === 2" class="font-weight-bold">Féminin</span>
                            <span *ngIf="droit.person.sex === 9" class="font-weight-bold">Indefini</span>
                          </td>
                          <td>Situtation matrimoniale :
                            <span class="font-weight-bold">
                              {{(droit.person.etatCivil != null && droit.person.etatCivil.trim().length != 0) ? droit.person.etatCivil : "-"}}
                            </span>
                          </td>
                          <td>Date de naissance :
                            <span style="font-weight: bold;" *ngIf="droit.person.birthday==Null">{{"-"}}</span>
                            <span style="font-weight: bold;" *ngIf="droit.person.birthday!=Null">
                              {{ droit.person.birthday | date:'d/MM/y' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Profession :
                            <span class="font-weight-bold">
                              {{(droit.person.profession != null && droit.person.profession.trim().length != 0) ? droit.person.profession : "-"}}
                            </span>
                          </td>
                          <td>Domicile :
                            <span class="font-weight-bold">
                              {{droit.person.domicile != null ? droit.person.domicile : "-"}}
                            </span>
                          </td>
                          <td>Adresse personnelle :
                            <span class="font-weight-bold">
                              {{droit.person.address != null ? droit.person.address : "-"}}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">Téléphone :
                            <span style="font-weight: bold;">
                              {{droit.person.phone1 != null ? droit.person.phone1 : "-"}}
                            </span>
                          </td>
                          <td>Nationalité :
                            <span style="font-weight: bold;">
                              {{droit.person.nationality != null ? droit.person.nationality : "-"}}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>RAVIP :
                            <span class="font-weight-bold">
                              {{ droit.person.haveRavip != null ? droit.person.haveRavip : "-" }}
                            </span>
                          </td>
                          <td>Numéro RAVIP :
                            <span class="font-weight-bold">
                              {{(droit.person.ravip != null && droit.person.ravip.trim().length != 0) ? droit.person.ravip : "-"}}
                            </span>
                          </td>
                          <td>Numéro NIP :
                            <span class="font-weight-bold">
                              {{(droit.person.npi != null && droit.person.npi.trim().length != 0) ? droit.person.npi : "-"}}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3">Type identification :
                            <span
                              style="font-weight: bold;"> <a href="{{imaga_path(droit.person.identificationProof,1)}}">{{(droit.person.identificationType != null && droit.person.identificationType.trim().length != 0) ? droit.person.identificationType + "(" + droit.person.identification + ")" : "-"}}</a></span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">Type droit :
                            <span *ngIf="droit.person.sex == null" class="font-weight-bold">-</span>
                            <span *ngIf="droit.type == 'ownership'" class="font-weight-bold">Propriété(TF)</span>
                            <span *ngIf="droit.type == 'ownershipPresumed'" class="font-weight-bold">Propriété présumée</span>
                            <span *ngIf="droit.type != 'Propriété présumée' && droit.type != 'ownership'" class="font-weight-bold"> {{ droit.type }}</span>
                          </td>
                          <td colspan="2">Part :
                            <span style="font-weight: bold;">{{droit.part}}%</span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">Model d’acquisition :
                            <span style="font-weight: bold;" *ngFor="let s of droit.source; let ix=index">
                              {{showModeAcquisition(s.acquisitionMethod)}}
                              {{ ix == (droit.source.length - 1) ? '' : ','}}
                            </span>
                          </td>
                          <td colspan="2">Source de droit :
                            <span style="font-weight: bold;" *ngFor="let s of droit.source; let ix=index">
                              <a href="{{imaga_path(s.acquisitionProof,0)}}">
                                {{s.source.replace("_", " ")}}
                                {{ ix == (droit.source.length - 1) ? '' : ','}}
                              </a>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">Liste des témoins :
                            <span *ngIf="droit.witnesses==Null" style="font-weight: bold;">
                                                                -
                                                            </span>
                            <span *ngIf="droit.witnesses!=Null" style="font-weight: bold;">
                                                                <span La parcelle est‐elle contestée
                                                                      :*ngIf="droit.witnesses.length==Zero">
                                                                    -
                                                                </span>
                                                            </span>
                            <span style="font-weight: bold;"
                                  *ngIf="droit.witnesses!=Null && droit.witnesses.length!=Zero">
                                                                <span *ngFor="let m of droit.witnesses; let ix=index">
                                                                  <a href="{{imaga_path(m.photo,1)}}">
                                                                    {{m.firstName}} {{m.name}}
                                                                    {{ ix == (droit.witnesses.length - 1) ? '' : ','}}
                                                                  </a>
                                                                  
                                                                </span>

                                                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">Représenté par : <span
                            style="font-weight: bold;"><a href="{{imaga_path(droit.representative.identificationProof,1)}}">{{droit.representative
                              != null ? droit.representative.name : "-"}}</a>
                          
                        </span></td>
                        </tr>
                        <tr>
                          <td colspan="2">Date début propriété : <span style="font-weight: bold;">
                                                                {{droit.startedAt != null ? droit.startedAt : "-"}} </span>
                          </td>
                          <td colspan="2">Date fin propriété : <span style="font-weight: bold;">{{droit.endedAt
                          != null ? droit.person.startedAt : "-"}}</span></td>
                        </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
                <div class="row mt-2" *ngIf="data.right.contestations!=Null">
                  <div *ngIf="data.right.contestations.length!=Zero">
                    <div class="col-sm-12">
                      <h5>Contestataires</h5>
                      <table class="table" border="1"
                             *ngFor="let droit of data.right.contestations">
                        <tbody>
                        <tr>
                          <td rowspan="8">
                                                            <span class="thumb-xlg"
                                                                  *ngIf="droit.contestant.photo!=Null">

                                                                <span
                                                                  *ngIf="droit.contestant.photo==Null && droit.contestant.sex === '0'"
                                                                  style="font-weight: bold;">
                                                                    <img
                                                                      style="width: 200px; height: 200px;text-align: center; vertical-align: top;text-align: start;"
                                                                      src="assets/img/avatar.png" alt="...">
                                                                </span>
                                                                <span
                                                                  *ngIf="droit.contestant.photo==Null && droit.contestant.sex == '1'"
                                                                  style="font-weight: bold;">
                                                                    <img
                                                                      style="width: 200px; height: 200px;text-align: center; vertical-align: top;text-align: start;"
                                                                      src="assets/img/avatar.png" alt="...">
                                                                </span>
                                                                <span
                                                                  *ngIf="droit.contestant.photo==Null && droit.contestant.sex == '2'"
                                                                  style="font-weight: bold;">
                                                                    <img
                                                                      style="width: 200px; height: 200px;text-align: center; vertical-align: top;text-align: start;"
                                                                      src="assets/img/avatar-femme.jpg" alt="...">
                                                                </span>
                                                                <span
                                                                  *ngIf="droit.contestant.photo==Null && droit.contestant.sex == '9'"
                                                                  style="font-weight: bold;">
                                                                    <span *ngIf="droit.contestant.sex === 1"
                                                                          style="font-weight: bold;">
                                                                        <img
                                                                          style="width: 200px; height: 200px;text-align: center; vertical-align: top;text-align: start;"
                                                                          src="assets/img/avatar.png" alt="...">
                                                                    </span>
                                                                </span>
                                                                <span
                                                                  *ngIf="droit.contestant.photo==Null && droit.contestant.sex == Null"
                                                                  style="font-weight: bold;">
                                                                    <span style="font-weight: bold;">
                                                                        <img
                                                                          style="width: 200px; height: 200px;text-align: center; vertical-align: top;text-align: start;"
                                                                          src="assets/img/avatar.png" alt="...">
                                                                    </span>
                                                                </span>
                                                            </span>
                          </td>
                          <td colspan="3" style="text-align: center;font-weight: bold;">
                            {{droit.contestant.firstName}}
                            {{droit.contestant.name}}</td>
                        </tr>
                        <tr *ngIf="droit.contestant.type === 'naturalPerson'">
                          <td>Sexe : <span style="font-weight: bold;">
                                                                <span *ngIf="droit.contestant.sex === 0"
                                                                      style="font-weight: bold;">-</span>
                                                                <span *ngIf="droit.contestant.sex === 1"
                                                                      style="font-weight: bold;">Masculin</span>
                                                                <span *ngIf="droit.contestant.sex === 2"
                                                                      style="font-weight: bold;">Féminin</span>
                                                                <span *ngIf="droit.contestant.sex === 9"
                                                                      style="font-weight: bold;">Indefini</span>

                                                            </span>
                          </td>
                          <td>
                            Situtation matrimoniale :
                            <span style="font-weight: bold;">
                              {{droit.contestant.etatCivil != null ? droit.contestant.etatCivil : "-"}}
                            </span>
                          </td>
                          <td>Date de naissance : <span style="font-weight: bold;">
                                                                <span *ngIf="droit.contestant.birthday!=Null">
                                                                    {{droit.contestant.birthday | date:'dd/MM/yyyy'}}
                                                                </span>
                                                                <span *ngIf="droit.contestant.birthday==Null">
                                                                    -
                                                                </span>
                                                            </span></td>
                        </tr>
                        <tr>
                          <td [colSpan]="isUndefined(droit.contestant.identificationType) ? 3 : 2">Tel : <span style="font-weight: bold;"> {{droit.contestant.phone1
                          != null ? droit.contestant.phone1 : "-"}} </span></td>
                          <td *ngIf="!isUndefined(droit.contestant.identificationType)"> Type d'identification: <span style="font-weight: bold;">{{droit.contestant.identificationType.replace('_', ' ')}}</span> (<span
                            style="font-weight: bold;">{{droit.contestant.identification}}</span>)
                          </td>
                        </tr>
                        <!--
                        <tr>
                            <td>Données Biométriques : <span style="font-weight: bold;"> OUI
                                </span> </td>
                            <td>
                                <span *ngIf="droit.person.ladmPartyType === 'naturalPerson'"
                                    style="font-weight: bold;">Personne Physique</span>
                            </td>
                        </tr>
                        -->
                        <tr>
                          <td colspan="3">Memo : <span style="font-weight: bold;">
                                                                {{droit.memo}}</span></td>

                        </tr>
                        <tr>
                          <td>Specifique Type : <span style="font-weight: bold;">
                                                                {{droit.specificType}}</span></td>
                          <td colspan="2">Type : <span
                            style="font-weight: bold;">{{droit.limitChallenge}}</span>
                          </td>
                        </tr>


                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-sm-12">
                    <table class="table" border="1">
                      <tbody>
                      <tr>
                        <td>La parcelle est‐elle contestée :</td>
                        <td>
                                                        <span *ngIf="right.contested == null "
                                                              style="font-weight: bold;">-</span>
                          <span *ngIf="right.contested == true "
                                style="font-weight: bold;">OUI</span>
                          <span *ngIf="right.contested == false "
                                style="font-weight: bold;">NON</span>
                        </td>
                      </tr>
                      <tr>
                        <td>La parcelle est (en partie) située sur des terres domaniales :
                        </td>
                        <td>
                                                        <span *ngIf="right.stateLand == null "
                                                              style="font-weight: bold;">-</span>
                          <span *ngIf="right.stateLand == true "
                                style="font-weight: bold;">OUI</span>
                          <span *ngIf="right.stateLand == false "
                                style="font-weight: bold;">NON</span>
                        </td>
                      </tr>
                      <tr>
                        <td>La parcelle est (en partie) située dans une zone tampon :</td>
                        <td>
                                                        <span *ngIf="right.buffer == null "
                                                              style="font-weight: bold;">-</span>
                          <span *ngIf="right.buffer == true "
                                style="font-weight: bold;">OUI</span>
                          <span *ngIf="right.buffer == false "
                                style="font-weight: bold;">NON</span>
                        </td>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>
                <!--
<div class="row mt-3">
                    <div class="col-sm-12">
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td>Administration source : </td>
                                    <td>
                                        <span style="font-weight: bold;">Etude GNSS</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Enregistrement : </td>
                                    <td>
                                        <span style="font-weight: bold;">{{right.synchDate !=
                                            null?right.synchDate:"-"}} </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Enquêteur social : </td>
                                    <td>
                                        <span style="font-weight: bold;">{{ data.investigator.surname
                                            }} {{ data.investigator.forename }} </span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                -->


              </div>
            </section>
          </div>
          <div class="col-md-3">
            <section class="activities">
              <h3>Commentaires</h3>
              <section class="card card-body" [class.bgComment]="!com.read"
                       *ngFor="let com of comments | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
                <div class="d-flex mb-2">
                  <div class="ml-2">
                    <h5 class="fw-normal mt-1 my-0">{{com.writer.forename}} <span
                      class="fw-semi-bold">{{com.writer.surname}}</span></h5>
                    <p class="my-0">{{roleService.getRole(com.writer.role)}}</p>
                    <small class="fs-sm text-muted ">{{com.isuueDate | date: 'short'}}</small>

                  </div>
                  <div ngbDropdown container="body" class="float-right">
                    <button class="btn btn-sm bgComment " ngbDropdownToggle>
                      <i class="mdi mdi-menu mdi-18px"></i>
                    </button>
                    <div ngbDropdownMenu>
                      <a ngbDropdownItem (click)="markCommentAsRead(com.id)">Marquer comme lu</a>
                      <a ngbDropdownItem (click)="openUpdateModal(comentU,com)">Modifier</a>
                      <a ngbDropdownItem (click)="deleteComment(com.id,i)">Supprimer</a>
                    </div>
                  </div>
                </div>
                <p class="fs-mini">
                  <b>{{com.text}}</b>
                </p>

              </section>
              <div class="d-flex justify-content-center p-2" *ngIf="comments.length!=size">
                <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5"
                                [pageSize]="pageSize" [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
                </ngb-pagination>


              </div>


              <div *ngIf="current_role==Superviseur">
                <form #commentForm="ngForm" (ngSubmit)="addComment(commentForm.value)">
                  <div class="form-group">
                                        <textarea class="form-control input-sm" name="text" [(ngModel)]="content"
                                                  type="text" placeholder="Votre commentaire...">
                                            </textarea>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary float-right"
                            [disabled]="!commentForm.form.valid">
                      Envoyer
                    </button>
                  </div>
                </form>
              </div>

            </section>

          </div>
        </div>

      </div>
      <!-- container-fluid -->
      <ng-template #comentU let-modal>
        <form #updateForm="ngForm" (ngSubmit)="editComment(updateForm.value)">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Modification</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-remove font-size-h3"></i>
            </button>
          </div>
          <div class="modal-body">


            <div class="form-group">
                            <textarea class="form-control input-sm" name="text" [(ngModel)]="selected_comment.text"
                                      type="text" placeholder="Write your comment...">
                                </textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid"
                    type="submit">Sauvegarder
            </button>
          </div>
        </form>
      </ng-template>
    </div>
    <!-- content -->

    <app-footer>
    </app-footer>

  </div>
  <!-- ============================================================== -->
  <!-- End Right content here -->
  <!-- ============================================================== -->

</div>
<!-- END wrapper -->
