import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthRoleGuard implements CanActivate {
  
  constructor(private router: Router, private jwtHelper:JwtHelperService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(this.jwtHelper.isTokenExpired()){
      localStorage.removeItem('userToken');
      localStorage.removeItem('userData');
      localStorage.removeItem('userRole');
    }
    
    if (localStorage.getItem('userToken')!=null && !this.jwtHelper.isTokenExpired()) {
        // logged in so return true
        return true;
    }
    // If not login user the redirect to login page
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
}

}
