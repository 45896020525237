

<div class="left side-menu">
    <div class="slimscroll-menu" id="remove-scroll">

        <!--- Sidemenu -->
        <div id="sidebar-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu" id="side-menu">
                <li class="d-flex justify-content-center mb-3">
                    <img src="./assets/img/image__.png" class="mx-auto" alt=""> <br>
                </li>
                <li routerLinkActive="mm-active">

                    <a  class="waves-effect" routerLink="/dashboard" routerLinkActive="mm-active">
                        <i class="fa fa-desktop"></i>
                        <span>
                          Tableau de bord
                        </span>

                    </a>
                </li>


                <li routerLinkActive="mm-active" *ngIf="current_role==Admin">
                    <a class="waves-effect" routerLink="/organizations" routerLinkActive="mm-active">
                        <i class="fa fa-list"></i>
                        <span >
                                Prestataires
                        </span>

                    </a>
                </li>


                <li routerLinkActive="mm-active">
                    <a class="waves-effect" routerLink="/interviews" routerLinkActive="mm-active">
                        <i class="fa fa-list-ol"></i>
                        <span >
                                Liste enquêtes
                        </span>

                    </a>
                </li>

                <li routerLinkActive="mm-active" >
                    <a class="waves-effect" routerLink="/consolidation" routerLinkActive="mm-active">
                        <i class="fa fa-list-ol"></i>
                        <span>
                                Consolidations
                        </span>
                    </a>
                </li>

                <li routerLinkActive="mm-active">
                    <a class="waves-effect" routerLink="/repertories" routerLinkActive="mm-active">
                        <i class="fa fa-chart-bar"></i>
                        <span >
                            Répertoire

                        </span>
                    </a>
                </li>




              <li routerLinkActive="mm-active" *ngIf="accessUsersListMenu(0, current_role)">
                <a class="waves-effect" routerLink="/users" routerLinkActive="mm-active">
                  <i class="fa fa-users"></i>
                  <span >
                                Les utilisateurs
                        </span>

                </a>
              </li>
              <li routerLinkActive="mm-active" *ngIf="accessUsersListMenu(1, current_role) || accessUsersListMenu(2, current_role)">
                <a class="waves-effect" routerLink="/blocs" routerLinkActive="mm-active">
                  <i class="fa fa-object-group"></i>
                  <span >
                                Les blocs
                        </span>

                </a>
              </li>


              <li routerLinkActive="mm-active" *ngIf="accessUsersListMenu(1, current_role)">
                <a class="waves-effect" routerLink="/my-users-manage" routerLinkActive="mm-active">
                  <i class="fa fa-users"></i>
                  <span >
                                Les utilisateurs
                        </span>

                </a>
              </li>
                <!--
                <li routerLinkActive="mm-active" *ngIf="current_role==Responsable || current_role==GESTIONNAIREDEDATA">
                    <a class="waves-effect" routerLink="/my-users-manage" routerLinkActive="mm-active">
                        <i class="fa fa-users"></i>

                        <span >
                            Les utilisateurs
                        </span>
                    </a>
                </li>
                <li routerLinkActive="mm-active" *ngIf="current_role==Admin">
                    <a class="waves-effect" routerLink="/users" routerLinkActive="mm-active">
                        <i class="fa fa-users"></i>
                        <span >
                            Les utilisateurs

                        </span>
                    </a>
                </li>

                -->


            </ul>

        </div>
        <!-- Sidebar -->
        <div class="clearfix"></div>

    </div>
    <!-- Sidebar -left -->

</div>
