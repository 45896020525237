import { Component } from '@angular/core';
import {SlimLoadingBarService} from 'ng2-slim-loading-bar';
import { NavigationCancel,
        Event,
        NavigationEnd,
        NavigationError,
        NavigationStart,
        Router } from '@angular/router';
import { ConnectionService } from 'ng-connection-service';
import { AlertNotif } from './alert';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'aigba-backoffice';
  dtOptions:any

  isConnected = true;  
  noInternetConnection: boolean;  
  
  constructor(private _loadingBar: SlimLoadingBarService, private _router: Router,private connectionService: ConnectionService) {
    this.connectionService.monitor().subscribe(isConnected => {  
      this.isConnected = isConnected;  
      if (this.isConnected) {  
        this.noInternetConnection=false;  
      }  
      else {  
        this.noInternetConnection=true;  
        AlertNotif.finishConfirmWithReload("Erreur", "Vous n'etes pas connecter à internet", 'error')
      }  
    })  
    this._router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
    this.dtOptions = {
      "ajax": {
        url: 'http://localhost/country/country.php',
        type: 'POST',
          data: {'id': 1, 'name': 'sathish'}
      },
      columns: [
          {
            title: 'Short Name',
              data: 'short_name'
          },
          {
              title: 'Long Name',
              data: 'long_name'
          },
          {
              title: 'ISO 3',
              data: 'iso3'
          },
          {
              title: 'ISO 2',
              data: 'iso2'
          }
      ],
      dom: 'Bfrtip',
      buttons: [
          'copy', 'csv', 'excel', 'pdf', 'print'
      ]
  };
  }
  
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this._loadingBar.start();
    }
    if (event instanceof NavigationEnd) {
      this._loadingBar.complete();
    }
    if (event instanceof NavigationCancel) {
      this._loadingBar.stop();
    }
    if (event instanceof NavigationError) {
      this._loadingBar.stop();
    }
  }
  
}
