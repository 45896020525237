
import { Injectable } from "@angular/core";  
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";  
import { Observable } from "rxjs";  
import { Organization } from "./../_models/organization.model";  
import { OrganizationService } from "./../_services/organization.service";  

@Injectable()

export class OrganizationGetAllResolve implements Resolve<Organization[]>{

    constructor(private dataService: OrganizationService) {}  

    resolve(route: ActivatedRouteSnapshot): Observable<Organization[]> {  
        return this.dataService.getAll();  
      }      
}



