<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                <br>
            </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Consolidation</h4>
                        </div>
                        <div class="col-sm-6 ">
                            <ol class="breadcrumb float-right">
                                <li class="breadcrumb-item">INFOCAD</li>
                                <li class="breadcrumb-item active">Consolidations</li>
                            </ol>

                            <!--
                            <a routerLink="/consolidation-failed" class="btn btn-outline-primary">Echec topo</a>
                            <a routerLink="/unconsolidation-record" class="btn btn-outline-primary">Echec socio</a>
                            -->
                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
              <div class="row ">
                <div class="col-md-12 mt-3">
                  <div class="card">
                    <div class="card-header">
                      <h3>Liste des consolidations</h3>
                      <div class="row">
                        <div class="col-12 col-md-12 d-flex justify-content-end form-group">
                          <button (click)="refresh_page() " class="btn btn-secondary mx-2">Rafraichir</button>
                          <button *ngIf="current_role==Superviseur" (click)="openAddModal(initer)" class="btn btn-primary mx-2">Nouvelle consolidation</button>
                          <a *ngIf="current_role==Superviseur" routerLink="/consolidation-success" class="btn btn-success mx-2">Consolidations réussies </a>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-6 form-group form-inline d-flex justify-content-between">

                          <label for="" class="col-md-5  d-flex justify-content-start">​ Ville/Quartier : </label>
                          <select #locality="ngModel" (change)="loadTeam($event)" name="locality" [(ngModel)]="selected_locality"
                                  id="" class="form-control col-md-7">
                            <option selected disabled>Choisir une ville/quartier</option>
                            <option [value]="el.id" *ngFor="let el of filter_localities; let i=index">
                              {{el.libelle}} </option>
                          </select>
                        </div>
                        <div class="col-6 form-inline d-flex justify-content-between">
                          <label for="" class="col-md-5 d-flex justify-content-start">Equipe : </label>
                          <select #team="ngModel" name="team" (change)="filterByTeam($event)" ngModel id="" class="form-control col-md-7">
                            <option selected disabled>Choisir une équipe</option>
                            <option [value]="el.id" *ngFor="let el of filter_teams; let i=index">{{el.cote}}
                              (
                              <small *ngFor="let m of el.members; let ix=index">
                                {{m.forename}} {{m.surname}}
                                {{ ix==(el.members.length-1) ? '' :','}}

                              </small>
                              )
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-end">
                        <div class="col-6 form-inline d-flex justify-content-end">
                          <button (click)="resetFilter()" class=" float-right mx-1 btn btn-secondary"
                                  [disabled]="!show_reset">Réinitialiser
                            filtres</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">

                    <div class="col-md-12 mx-auto">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>

                                            <th class="font-weight-bolder text-wrap">Village</th>
                                            <th class="font-weight-bolder text-wrap">Equipe</th>
                                            <th class="font-weight-bolder text-wrap">Creer le</th>
                                            <th class="font-weight-bolder text-wrap">Nombre parcelle dans le shapefile</th>
                                            <th class="font-weight-bolder text-wrap">Nombre parcelle echec</th>
                                            <th>Etat shapefile</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let el of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">

                                            <td>
                                                {{el.localityWs.libelle}}
                                            </td>
                                            <td>
                                                {{el.teamOutWs.cote}}
                                                (
                                                <small *ngFor="let m of el.teamOutWs.members; let ix=index">
                                                    {{m.forename}} {{m.surname}}
                                                    {{ ix==(el.teamOutWs.members.length-1) ? '' :','}}

                                                </small>
                                                )
                                            </td>
                                            <!--
                                            <td>
                                                <span *ngIf="el.orthers.consolidated==One" class="badge badge-green">
                                                    Consolider</span>
                                                <span *ngIf="el.orthers.consolidated==Zero" class="badge badge-dark">En
                                                    attente de consolidation</span>
                                                <span *ngIf="el.orthers.consolidated==underOne" class="badge badge-danger">Echec
                                                    de consolidation</span>

                                            </td>
                                            -->

                                            <td class="hidden-xs text-semi-muted">
                                                {{el.orthers.createdAt| date:'short'}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{el.orthers.parcelTotal}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{el.orthers.parcelConsolidatedFailed}}
                                            </td>
                                            <td class="hidden-xs">

                                                <span *ngIf="el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==Zero;else check_unpersisted"
                                                    class="badge badge-primary">
                                                    Persisté</span>
                                                <ng-template #check_unpersisted>
                                                    <span *ngIf="el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                    el.orthers.parcelConsolidatedFailed==el.orthers.parcelTotal;else check_failedconsolidation"
                                                        class="badge badge-danger">
                                                        Echec de consolidation</span>
                                                    <ng-template #check_failedconsolidation>
                                                        <span *ngIf="(el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                            el.orthers.parcelConsolidatedFailed!=el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==One &&
                                                            el.orthers.parcelConsolidatedFailed!=Zero);else check_consolidationpartiel"
                                                            class="badge badge-warning">
                                                            Consolidation partiel</span>

                                                        <ng-template #check_consolidationpartiel>
                                                            <span *ngIf="el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==One
                                                                &&  el.orthers.parcelConsolidatedFailed==Zero;else check_unconsolider"
                                                                class="badge badge-success">
                                                                Consolidé</span>

                                                            <ng-template #check_unconsolider>
                                                                <span *ngIf="(el.orthers.uploaded==One && el.orthers.persisted!=One) || (el.orthers.uploaded!=One);else check_unattente_upload"
                                                                    class="badge badge-dark">
                                                                    Attente de upload</span>
                                                                <ng-template #check_unattente_upload>Unknow</ng-template>

                                                            </ng-template>

                                                        </ng-template>


                                                    </ng-template>


                                                </ng-template>


                                            </td>

                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button *ngIf="el.orthers.persisted==One && current_role==Superviseur" (click)="download(el.orthers.session)"
                                                            ngbDropdownItem>Download</button>
                                                        <button *ngIf="el.orthers.persisted==One || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                        el.orthers.parcelConsolidatedFailed==el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                        el.orthers.parcelConsolidatedFailed!=el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==One &&
                                                        el.orthers.parcelConsolidatedFailed!=Zero) ||
                                                        (el.orthers.uploaded==One && el.orthers.persisted!=One) || (el.orthers.uploaded!=One) && current_role==Superviseur"
                                                            (click)="openReUploadModal(replacer,el.orthers.session)"
                                                            ngbDropdownItem>Resoumettre shapefiles</button>
                                                        <button *ngIf="el.orthers.persisted==One || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                        el.orthers.parcelConsolidatedFailed==el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                        el.orthers.parcelConsolidatedFailed!=el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==One &&
                                                        el.orthers.parcelConsolidatedFailed!=Zero) && current_role==Superviseur"
                                                            (click)="consolidationManuel(el.orthers.session)"
                                                            ngbDropdownItem>Consolider</button>

                                                        <a *ngIf="el.orthers.persisted==One || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                        el.orthers.parcelConsolidatedFailed==el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                        el.orthers.parcelConsolidatedFailed!=el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==One &&
                                                        el.orthers.parcelConsolidatedFailed!=Zero)"
                                                            [routerLink]="['/consolidation-failed',el.orthers.session]"
                                                            ngbDropdownItem>Liste parcelles</a>
                                                        <a *ngIf="el.orthers.persisted==One || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                        el.orthers.parcelConsolidatedFailed==el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==underOne &&
                                                        el.orthers.parcelConsolidatedFailed!=el.orthers.parcelTotal) || (el.orthers.uploaded==One && el.orthers.persisted==One && el.orthers.consolidated==One &&
                                                        el.orthers.parcelConsolidatedFailed!=Zero)"
                                                            [routerLink]="['/unconsolidation-record',el.orthers.teamId,el.orthers.session]"
                                                            ngbDropdownItem>Echec socio</a>

                                                        <button *ngIf="el.orthers.reported==One"
                                                            (click)="rapportErreur(el.orthers.session)" ngbDropdownItem>Rapport
                                                            d'erreurs</button>


                                                        <!--
                                                        <button (click)="openshowInfosModal(infos,el.session)" ngbDropdownItem>Voir details</button>

                                                            <a [routerLink]="['/consolidation-files',el.session]" ngbDropdownItem>Voir fichiers</a>
                                                        -->
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>


            <ng-template #initer let-modal>
                <form #initForm="ngForm" (ngSubmit)="init(initForm.value)">

                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Charger fichiers</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <i class="fa fa-remove font-size-h3"></i>
                        </button>
                    </div>
                    <div class="modal-body">

                        <!--
                        <div class=form-group>
                            <label>Utilisateur</label>
                            <select #user="ngModel" name="user" ngModel id="" class="form-control">
                                <option [value]="i" *ngFor="let el of users; let i=index">{{el.forename}}
                                    {{el.surname}} </option>
                            </select>
                            <span *ngIf="user.invalid && (user.dirty || user.touched)" class="text-danger">
                                <small *ngIf="user.errors?.required">
                                    Utilisateur sont requis
                                </small>
                            </span>
                        </div>
                            -->
                        <!--
                        <div class="form-group">
                            <label>Departement</label>
                            <select (change)="loadCommunues($event)" name="departement" ngModel class="form-control" id="">
                                <option [value]="el.id" *ngFor="let el of departements">{{el.libelle}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Commune</label>
                            <select (change)="loadArrondissement($event)" name="commune" ngModel class="form-control"
                                id="">
                                <option [value]="el.id" *ngFor="let el of communes">{{el.libelle}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Arrondissement</label>
                            <select (change)="loadLocality($event)" name="arrondissement" ngModel class="form-control"
                                id="">
                                <option [value]="el.id" *ngFor="let el of arrondissements">{{el.libelle}}</option>
                            </select>
                        </div>
                            -->

                        <div class=form-group *ngIf="user.organisation==Null">
                            <label>Organisation</label>
                            <select #org="ngModel" name="org" ngModel id="" class="form-control">
                                <option [value]="i" *ngFor="let el of orgs; let i=index">
                                    {{el.designation}} </option>
                            </select>
                            <span *ngIf="org.invalid && (org.dirty || org.touched)" class="text-danger">
                                <small *ngIf="org.errors?.required">
                                    Organisation sont requis
                                </small>
                            </span>
                        </div>
                        <div class=form-group>
                            <label>Localité</label>
                            <select #locality="ngModel" (change)="NewConsolidationloadTeam($event)" required name="locality" ngModel id=""
                                class="form-control">
                                <option [value]="el.id" *ngFor="let el of localities; let i=index">
                                    {{el.libelle}} </option>
                            </select>
                            <span *ngIf="locality.invalid && (locality.dirty || locality.touched)" class="text-danger">
                                <small *ngIf="locality.errors?.required">
                                    Localité sont requis
                                </small>
                            </span>
                        </div>
                        <div class=form-group>
                            <label>Equipes</label>
                            <select #team="ngModel" name="team" required ngModel id="" class="form-control">
                                <option [value]="el.id" *ngFor="let el of current_teams; let i=index">{{el.cote}}
                                    (
                                    <small *ngFor="let m of el.members; let ix=index">
                                        {{m.forename}} {{m.surname}}
                                        {{ ix==(el.members.length-1) ? '' :','}}

                                    </small>
                                    )
                                </option>
                            </select>
                            <span *ngIf="team.invalid && (team.dirty || team.touched)" class="text-danger">
                                <small *ngIf="team.errors?.required">
                                    Equipe sont requis
                                </small>
                            </span>
                        </div>


                        <div class=form-group>
                            <label>Fichiers</label>
                            <input class="form-control" #file="ngModel" type="file" multiple accept=".shp,.dbf,.prj,.qpj,.shx"
                                required (change)="onFileChange($event)" name="file" ngModel>
                            <span *ngIf="file.invalid && (file.dirty || file.touched)" class="text-danger">
                                <small *ngIf="file.errors?.required">
                                    fichiers sont requis
                                </small>
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="">
                                <input type="checkbox" ngModel name="forced">
                                Forcer la consolidation
                            </label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-dark align-self-center" [disabled]="!initForm.form.valid" type="submit">Sauvegarder
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="uploaded"></span>
                        </button>

                    </div>
                </form>

            </ng-template>
            <ng-template #replacer let-modal>
                <form #replaceForm="ngForm" (ngSubmit)="replace(replaceForm.value)">

                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Remplacer fichiers</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <i class="fa fa-remove font-size-h3"></i>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class=form-group>
                            <label>Fichiers</label>
                            <input class="form-control" #file="ngModel" type="file" multiple accept=".shp,.dbf,.prj,.qpj,.shx"
                                required (change)="onFileChange($event)" name="file" ngModel>
                            <span *ngIf="file.invalid && (file.dirty || file.touched)" class="text-danger">
                                <small *ngIf="file.errors?.required">
                                    fichiers sont requis
                                </small>
                            </span>
                        </div>
                        <div class="form-group">
                            <label for="">
                                <input type="checkbox" ngModel name="forced">
                                Forcer la consolidation
                            </label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-dark align-self-center" [disabled]="!replaceForm.form.valid"
                            type="submit">Sauvegarder
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="uploaded"></span>
                        </button>

                    </div>
                </form>

            </ng-template>
            <ng-template #listFile let-modal>
                <form #OnlyinitForm="ngForm" (ngSubmit)="initOnly(OnlyinitForm.value)">

                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Liste de fichiers</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <i class="fa fa-remove font-size-h3"></i>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class=form-group>
                            <label>Utilisateur</label>
                            <select #user="ngModel" name="user" ngModel id="" class="form-control">
                                <option [value]="i" *ngFor="let el of users; let i=index">{{el.forename}}
                                    {{el.surname}} </option>
                            </select>
                            <span *ngIf="user.invalid && (user.dirty || user.touched)" class="text-danger">
                                <small *ngIf="user.errors?.required">
                                    Utilisateur sont requis
                                </small>
                            </span>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-outline-dark align-self-center" [disabled]="!OnlyinitForm.form.valid"
                            type="submit">Sauvegarder</button>

                    </div>
                </form>

            </ng-template>

            <ng-template #infos let-modal>

                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Infos</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <i class="fa fa-remove font-size-h3"></i>
                    </button>
                </div>
                <div class="modal-body d-flex flex-column">
                    <p>Total : {{data_infos.total== underOne ? 'Sans parcelle' : data_infos.total}}</p>
                    <p>Echec : {{data_infos.failed}}</p>
                    <p>Status : {{data_infos.status}}</p>
                    <p>code : {{data_infos.code}}</p>
                </div>
                <div class="modal-footer">


                </div>

            </ng-template>


            <ng-template #topoContent let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Echec Topo</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <i class="fa fa-remove font-size-h3"></i>
                    </button>
                </div>
                <div class="modal-body">


                    <table class="table  table-striped ">
                        <thead>
                            <tr>

                                <th>N PARC</th>

                                <th>Village</th>

                                <th class="hidden-xs">Nom</th>
                                <th class="hidden-xs">Prenom</th>
                                <th class="hidden-xs">Nationnalite</th>
                                <th class="hidden-xs">Date Enrg</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let el of topo_data.parcels | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; let i=index">

                                <td>
                                    {{el.no}}
                                </td>
                                <td>
                                    {{el.villagenam}}
                                </td>
                                <td>
                                    {{el.familyname}}
                                </td>

                                <td>
                                    {{el.firstname1}}
                                </td>
                                <td>
                                    {{el.countyname}}
                                </td>
                                <td>
                                    {{el.date | date:'short'}}
                                </td>
                                <td>
                                    <button class="btn btn-outline-primary btn-sm" (click)="consolidationUnitaire(el.no,el.id_socio)">Consolidé</button>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination class="" [collectionSize]="collectionSize2" [(page)]="page2" [maxSize]="5"
                            [pageSize]="pageSize2" [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
                        </ngb-pagination>

                        <select class="custom-select custom-select-sm float-right" style="width: auto" [(ngModel)]="pageSize2">
                            <option selected disabled>Fitre</option>
                            <option [ngValue]="3">3 éléments par page</option>
                            <option [ngValue]="5">5 éléments par page</option>
                            <option [ngValue]="10">10 éléments par page</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </ng-template>
            <ng-template #socioContent let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title"> Echec Socio</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <i class="fa fa-remove font-size-h3"></i>
                    </button>
                </div>
                <div class="modal-body">


                    <table class="table  table-striped ">
                        <thead>
                            <tr>
                                <th>N° provisoire</th>
                                <th>Village</th>
                                <th class="hidden-xs">Date</th>
                                <th class="hidden-xs">Proprietaire</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let el of socio_data | slice: (page3-1) * pageSize3 : (page3-1) * pageSize3 + pageSize3; let i=index">

                                <td>
                                    {{el.nup}}
                                </td>
                                <td>
                                    {{el.locality.libelle}}
                                </td>
                                <td>
                                    {{el.issueDate | date:'short'}}
                                </td>

                                <td>
                                    {{el.owner}}
                                </td>

                                <td>
                                    <button class="btn btn-outline-primary btn-sm">Consolidé</button>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                    <div class="d-flex justify-content-between p-2">
                        <ngb-pagination class="" [collectionSize]="collectionSize3" [(page)]="page3" [maxSize]="5"
                            [pageSize]="pageSize3" [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
                        </ngb-pagination>

                        <select class="custom-select custom-select-sm float-right" style="width: auto" [(ngModel)]="pageSize3">
                            <option selected disabled>Fitre</option>
                            <option [ngValue]="3">3 éléments par page</option>
                            <option [ngValue]="5">5 éléments par page</option>
                            <option [ngValue]="10">10 éléments par page</option>
                        </select>
                    </div>

                </div>
                <div class="modal-footer">
                </div>
            </ng-template>
            <ng-template #errReport let-modal>
                <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Rapport d'erreurs</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <i class="fa fa-remove font-size-h3"></i>
                    </button>
                </div>
                <div class="modal-body">


                    <table class="table  table-striped ">
                        <thead>
                            <tr>
                                <th>Erreurs</th>
                                <th>Status</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr >

                                <td>
                                  Superpositions
                                </td>
                                <td>
                                    {{err_report.superpose}}
                                </td>

                            </tr>
                            <tr >

                                <td>
                                  Creux
                                </td>
                                <td>
                                    {{err_report.creux}}
                                </td>

                            </tr>
                            <tr >

                                <td>
                                  Doublons
                                </td>
                                <td>
                                    {{err_report.poly}}
                                </td>

                            </tr>
                        </tbody>

                    </table>


                </div>
                <div class="modal-footer">
                    <a [routerLink]="['/error-report',session]">Voir détails</a>
                </div>
            </ng-template>
        </div>
        <!-- content -->

        <app-footer>
        </app-footer>

    </div>


    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->
