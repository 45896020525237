

<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png"  classs="d-none d-sm-none d-md-block d-lg-block d-xl-block"  alt=""> <br>
            </a>
        </div>

        
        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->
    
    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <!-- end page-title -->
                <div class="row">
                    <div class="col-md-6">
                        <form #updateWithCheckForm="ngForm" (ngSubmit)="selectLocality()">
                            <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Selectionner une localité</h4>
                              </div>
                            <div class="modal-body">
                        
                              <div class="row col-md-12 d-flex justify-content-between">
                                  <div class="form-group form-inline">
                                      <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                                          [(ngModel)]="searchText" name="searchText" (keyup)="search2()" />
                                  </div>
                              </div>
                              <div class="row col-md-12 d-flex justify-content-end mb-2" >
                                  <button type="button" class="btn btn-outline-dark"  type="submit">Sélectionner</button>
                              </div>
                  
                              <table class="table  table-striped " id="example">
                                  <thead>
                                      <tr>
                                          <th></th>
                                          <th>Localité</th>
                                          <th>Infos</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let el of localities | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; let i=index">
                                          <td>
                                              <input type="radio" [(ngModel)]="selectedValue" name="locality" value="{{el.id}}">
                                          </td>
                                          <td>
                                              {{el.libelle}} 
                                          </td>
                                          <td class="hidden-xs">
                                              {{el.libelleUnique}} 
                                          </td>
                                      </tr>
                                  </tbody>
                  
                              </table>
                                <div *ngIf="loading" class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                        <div class="loader mb-2"></div>
                                    </div>
                                </div>
                            <ngb-pagination class="" [collectionSize]="collectionSize2" [(page)]="page2"
                                                [maxSize]="5" [pageSize]="pageSize2" [rotate]="true" [ellipses]="true"
                                                [boundaryLinks]="true">
                            </ngb-pagination>
                            </div>
                            <div class="modal-footer">
                            </div>
                        </form>
                    </div>
                </div>
                
            </div>
            <!-- container-fluid -->

        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>
    
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->

