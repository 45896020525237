<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block"
                    alt="">
                <br>
            </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Les utilisateurs</h4>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-right">
                                <li class="breadcrumb-item">INFOCAD</li>
                                <li class="breadcrumb-item active">Les utilisateurs</li>
                            </ol>

                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row my-2">
                    <div class="col-6 col-md-6 d-flex justify-content-start align-items-center">
                        <button (click)="openAddModal(content)" class="btn btn-primary">
                            <i class="fa fa-plus"></i>
                            Nouvel utilisateur
                        </button>
                    </div>
                    <div class="col-6  col-md-6 d-flex justify-content-end align-items-center">
                        <input placeholder="Rechercher" type="text"
                                    [(ngModel)]="searchText" name="searchText" (keyup)="search()" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 d-flex justify-content-end">

                    </div>
                    <div class="col-md-12">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>

                                            <th>Nom & prenoms</th>
                                            <th>Profil</th>
                                            <th class="hidden-xs">Organisation</th>
                                            <th class="hidden-xs">Contact</th>
                                            <th class="hidden-xs">Email</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let user of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">

                                            <td>
                                                {{user.forename}} {{user.surname}}
                                            </td>
                                            <td class="hidden-xs">
                                                <span class="label label-success"> {{ user.role }}</span>
                                            </td>
                                            <td class="hidden-xs">
                                                {{ user.organisation==null ? '' : user.organisation.designation}}
                                            </td>
                                            <td class="hidden-xs">
                                                {{user.telephone}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{user.email}}
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm"
                                                        ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>

                                                        <button (click)="openUpdateModal(contentU,user)"
                                                            ngbDropdownItem>Modifier</button>
                                                        <button (click)="openUpdateModal(infos,user)"
                                                            ngbDropdownItem>Voir
                                                            détails</button>
                                                            <button (click)="changeEnable(user)"
                                                            ngbDropdownItem> {{ user.enabled ? "Désactiver" : "Activer"}} </button>
                                                        <button *ngIf="isDeletable(user)" (click)="archiveOnTeam(user.id)"
                                                            ngbDropdownItem>Supprimer</button>


                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->

        </div>
        <!-- content -->

        <app-footer>
        </app-footer>

    </div>
    <ng-template #content let-modal>
        <form #newForm="ngForm" (ngSubmit)="create(newForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Nouvel utilisateur</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <h5>X</h5>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span
                            aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="form-group">
                    <label for="">Nom</label>
                    <input type="text" class="form-control" required ngModel name="surname">
                </div>
                <div class="form-group">
                    <label for="">Prenom</label>
                    <input type="text" class="form-control" required ngModel name="forename">
                </div>
                <div class="form-group">
                    <label for="">Email</label>
                    <input type="email" name="email" required ngModel class="form-control">
                </div>
                <div class="form-group">
                    <label for="">Contact</label>
                    <input type="number" name="telephone" required ngModel class="form-control">
                </div>
                <div class="form-group">
                    <label for="">Profil</label>
                    <select name="role" required [(ngModel)]="selected_role" id="" class="form-control">
                        <option value="GESTIONNAIREDEDATA" *ngIf="Admin==role">Gestionnaire de donées</option>
                        <option value="RESPONSABLE">Responsable</option>
                        <option value="INVITE" >Invité</option>
                        <!--
                        <option value="ENQUETEURTOPO">Enqueteur topo</option>
                        <option value="SUPERVISEUR">Superviseur</option>
                        -->
                    </select>
                </div>
                <div class="form-group" required *ngIf="INVITE!=selected_role && selected_role!=GESTIONNAIREDEDATA">
                    <label for="">Organisation</label>
                    <select name="organization" required ngModel id="" class="form-control">
                        <option [value]="org.id" *ngFor="let org of organisations">{{org.designation}}</option>
                    </select>
                </div>
                <!--
                <div class="form-group" *ngIf="ENQUETEURTOPO==selected_role">
                    <label for="">Team</label>
                    <select name="team" ngModel id="" class="form-control">
                        <option [value]="t.id" *ngFor="let t of teams">{{t.designation}}</option>
                    </select>
                </div>
                -->
                <div class="form-group">
                    <label for="">Mot de passe <small>(facultatif)</small></label>
                    <input type="password" name="password" ngModel class="form-control" id="pswd"
                        icono="icone-eye-hide"><span toggle="#pswd" id="icone-eye-hide"
                        class="fa fa-fw fa-eye field-icon toggle-password" (click)="hideOrShowPassword()"></span>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!newForm.form.valid"
                    type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <ng-template #contentU let-modal>
        <form #updateForm="ngForm" (ngSubmit)="update(updateForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Modification utilisateur</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-remove font-size-h3"></i>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span
                            aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="form-group">
                    <label for="">Nom</label>
                    <input type="text" class="form-control" required [(ngModel)]="data_current.surname" name="surname">
                </div>
                <div class="form-group">
                    <label for="">Prenom</label>
                    <input type="text" class="form-control" required [(ngModel)]="data_current.forename"
                        name="forename">
                </div>
                <div class="form-group">
                    <label for="">Email</label>
                    <input type="email" name="email" required  [(ngModel)]="data_current.email" class="form-control">
                </div>
                <div class="form-group">
                    <label for="">Contact</label>
                    <input type="number" name="telephone" required [(ngModel)]="data_current.telephone"
                        class="form-control">
                </div>
                <!--
                 <div class="form-group">
                    <label for="">Profil</label>
                    <select name="role" required [(ngModel)]="data_current.role" id="" class="form-control">
                        <option value="GESTIONNAIREDEDATA" *ngIf="Admin==role">Gestionnaire de donées</option>
                        <option value="RESPONSABLE">Responsable</option>
                        <option value="INVITE">Invité</option>
                        <option value="ENQUETEURTOPO">Enqueteur topo</option>
                        <option value="SUPERVISEUR">Superviseur</option>
                    </select>
                </div>
                 -->
                <div class="form-group" *ngIf="INVITE!=data_current.role && data_current.role!=GESTIONNAIREDEDATA">
                    <label for="">Organisation</label>
                    <select name="organization" required [(ngModel)]="data_current.organisation.id" id=""
                        class="form-control">
                        <option [value]="org.id" *ngFor="let org of organisations">{{org.designation}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="">Nouveau Mot de passe <small>(facultatif)</small></label>
                    <input type="password" name="password" ngModel class="form-control">
                </div>
                <!--
                
                <div class="form-group" *ngIf="ENQUETEURTOPO==data_current.role">
                    <label for="">Team</label>
                    <select name="team" [(ngModel)]="data_current.team.id" id="" class="form-control">
                        <option [value]="t.id" *ngFor="let t of teams">{{t.designation}}</option>
                    </select>
                </div>
                -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid"
                    type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <ng-template #infos let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Informations</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <i class="fa fa-remove font-size-h3"></i>
            </button>
        </div>
        <div class="modal-body">


            <table class="table  table-striped ">
                <tbody>
                    <tr>
                        <td><strong>Nom</strong></td>
                        <td>{{data_current.surname}}</td>
                    </tr>
                    <tr>
                        <td><strong>Prénom</strong></td>
                        <td>{{data_current.forename}}</td>
                    </tr>
                    <tr>
                        <td><strong>Email</strong></td>
                        <td>{{data_current.email}}</td>
                    </tr>
                    <tr>
                        <td><strong>Contact</strong></td>
                        <td>{{data_current.telephone}}</td>
                    </tr>
                    <tr>
                        <td><strong>Profil</strong></td>
                        <td>{{ data_current.role==null ? '' : roleService.getRole(data_current.role)}}</td>
                    </tr>
                    <tr>
                        <td><strong>Equipe</strong></td>
                        <td> {{ data_current.team==null ? '' : data_current.team}}</td>
                    </tr>
                    <tr>
                        <td><strong>Organisation</strong></td>
                        <td>{{ data_current.organisation==null ? '' : data_current.organisation.designation}}</td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="modal-footer">
        </div>
    </ng-template>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->