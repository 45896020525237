<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                <br>
            </a>
        </div>
        <!-- <button class="btn btn-secondary mt-3 ml-3" >Retour</button> -->


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <br>
                <div class="row">
                    <div class="col-sm-6">

                    </div>
                    <div class="col-sm-6 d-flex justify-content-end">
                        <button (click)="openAddModal(filterOpt)" class="btn-lg btn-secondary">Filtrer</button>
                        <!-- <button class="btn-lg btn-primary" (click)="downloadPdf()" routerLink="/print">Imprimer</button>&nbsp;<button class="btn-lg btn-success" (click)="openSelectLocalities(contentUL)">Charger</button> -->
                        <button class="btn-lg btn-primary mx-3" (click)="generatePDF()">Imprimer</button>&nbsp;
                        <div class="" ngbDropdown container="body">
                            <button class="btn-lg btn-info" ngbDropdownToggle>Export
                            </button>
                            <div ngbDropdownMenu>
                                <button (click)="download(geojson)" ngbDropdownItem>GeoJson</button>
                                <button (click)="download(shapefiles)" ngbDropdownItem>Shapefiles</button>
                                <button (click)="excel()" ngbDropdownItem>Excel</button>

                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <h5 style="text-align: center;" class="page-title mt-3">REPERTOIRE DES AYANTS DROIT DU
                    VILLAGE/QUARTIER <span style="font-weight: bold;">{{locality!=null?locality.libelle:"-"}}</span>
                </h5>
                <h5 style="text-align: center;" class="page-title mt-3"><span style="font-weight: bold;">{{locality!=null?locality.libelleUnique:"-"}}</span>
                </h5>

                <!-- end page-title -->
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="page-title"></h1>
                    </div>
                    <div class="col-md-6 d-flex justify-content-end">

                        <form>
                            <div class="form-group form-inline">
                                <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                                    [(ngModel)]="searchText" name="searchText" (keyup)="search()" />
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card shadow-none bg-white">
                            <div class="card-body">
                                <span class="mx-2"> <b class="bold">Nombre total de parcels : </b>
                                    {{stat.totalNumberParcel}} </span>
                                <span class="mx-2"> <b class="bold">Nombre d'ayant droits : </b>
                                    {{stat.totalNumberPersonRight}} </span>
                                <span class="mx-2"> <b class="bold">Nombre total de localitées : </b>
                                    {{stat.totalNumberVillage}} </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <section class="card shadow-none bg-white">

                            <div class="card-body" style="overflow-x: scroll">
                                <ngx-spinner></ngx-spinner>
                                <table datatable class="table table-striped my-3 " id="repertory">
                                    <thead>
                                        <tr>
                                            <th class="text-center" colspan="17">
                                                Repertoires
                                            </th>
                                        </tr>
                                        <tr>
                                            <th class="col-1" sortable="parcelNumber" (sort)="onSort($event)">N° PARC</th>
                                            <th width="5px" sortable="parcelCode" (sort)="onSort($event)">CODE PARC</th>
                                            <th >SUP_m²</th>
                                            <th sortable="name" (sort)="onSort($event)">NOM</th>
                                            <th sortable="firstname" (sort)="onSort($event)">PRENOMS</th>
                                            <th sortable="nationality" (sort)="onSort($event)">NATIONALITE</th>
                                            <th sortable="age" (sort)="onSort($event)">AGE</th>
                                            <th sortable="sex" (sort)="onSort($event)">SEXE</th>
                                            <th sortable="address" (sort)="onSort($event)">DOMICILE</th>
                                            <th sortable="phone" (sort)="onSort($event)">TELEPHONE</th>
                                            <th sortable="acquisitionMethod" (sort)="onSort($event)">MODE D'ACQUISITION</th>
                                            <th sortable="parcelType" (sort)="onSort($event)">TYPE</th>
                                            <th>DATE D'ENREGISTREMENT</th>
                                            <th>PHOTO</th>
                                            <th>OBSERVATIONS</th>
                                            <th *ngIf="!no_print">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let rep of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
                                            <td col-1>
                                                {{rep.parcelNumber}}
                                            </td>
                                            <td width="5px">
                                                {{rep.parcelCode}}
                                            </td>
                                            <td>
                                                {{splitInt(rep.surface)}}
                                            </td>

                                            <td>
                                                {{rep.name}}
                                            </td>
                                            <td>
                                                {{rep.firstname}}
                                            </td>
                                            <td>
                                                {{rep.nationality}}
                                            </td>
                                            <td>
                                                {{rep.age == null || rep.age === 0 ? "-" : rep.age}}
                                            </td>
                                            <td>
                                                <!--<span *ngIf="rep.sex === 0">Inconnu</span>-->
                                                <span *ngIf="rep.sex === 1" style="font-weight: bold;">Masculin</span>
                                                <span *ngIf="rep.sex === 2" style="font-weight: bold;">Féminin</span>
                                                <span *ngIf="rep.sex === 9" style="font-weight: bold;">Indefini</span>
                                            </td>
                                            <td>
                                                {{rep.address}}
                                            </td>
                                            <td>
                                                {{rep.phone}}
                                            </td>
                                            <td>
                                                {{showModeAcquisition(rep.acquisitionMethod)}}
                                            </td>
                                            <td>
                                                {{rep.parcelType}}
                                            </td>
                                            <td>
                                                {{rep.syncDate | date:'short'}}
                                            </td>
                                            <td>
                                                <img width="100px" height="100px" src="https://infocad.andf.bj/api/resources/{{rep.photoUri}}"
                                                    alt="">
                                            </td>
                                            <td>
                                                <span *ngFor="let o of rep.observations; let ix=index">
                                                    {{o}}
                                                    {{ ix==(rep.observations.length-1) ? '' :';'}}

                                                </span>
                                            </td>
                                            <td *ngIf="!no_print">
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button class="dropdown-item" ngbDropdownItem (click)="viewExtrait(rep.parcelNumber,rep.localRef)">Extrait
                                                            cadastral</button>

                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->
            <ng-template #filterOpt let-modal>
                <form #filterForm="ngForm" (ngSubmit)="filter(filterForm.value)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Options de filtre</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <i class="fa fa-remove font-size-h3"></i>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="form-group">
                            <label>Departement</label>
                            <select (change)="loadCommunues($event)" name="departement" ngModel class="form-control" id="">
                                <option [value]="el.id" *ngFor="let el of departements">{{el.libelle}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Commune</label>
                            <select (change)="loadArrondissement($event)" name="commune" ngModel class="form-control"
                                id="">
                                <option [value]="el.id" *ngFor="let el of communes">{{el.libelle}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Arrondissement</label>
                            <select (change)="loadLocality($event)" name="arrondissement" ngModel class="form-control"
                                id="">
                                <option [value]="el.id" *ngFor="let el of arrondissements">{{el.libelle}}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">​ Ville/Quartier</label>
                            <ng-multiselect-dropdown [placeholder]="'Choisir localité'" [settings]="dropdownSettings"
                                [data]="localities" name="locality" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>

                        <div class=form-group *ngIf="user.organisation==Null">
                            <label>Organisation</label>
                            <select #org="ngModel" name="org" [(ngModel)]="selected_org" id="" class="form-control">
                                <option [value]="el.id" *ngFor="let el of orgs; let i=index">
                                    {{el.designation}} </option>
                            </select>
                            <span *ngIf="org.invalid && (org.dirty || org.touched)" class="text-danger">
                                <small *ngIf="org.errors?.required">
                                    Organisation sont requis
                                </small>
                            </span>
                        </div>

                        <!--
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for="">Statut : </label>
                                </div>
                                <div class="col-md-10">
                                    <div class="form-check form-check-inline">
                                        Finalisées&nbsp;
                                        <input class="form-check-input" name="finalized" ngModel type="radio" value="1">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Non finalisées&nbsp;
                                        <input class="form-check-input" type="radio" name="finalized" ngModel value="0">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <span style="font-weight: bold;">Tout</span> &nbsp;
                                        <input class="form-check-input" type="radio" name="finalized" ngModel value="2">
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->


                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for=""> Parcelle litigieuse : </label>
                                </div>
                                <div class="col-md-10">
                                    <div class="form-check form-check-inline">
                                        Oui&nbsp;
                                        <input class="form-check-input" type="radio" value="1" name="litige">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Non &nbsp;
                                        <input class="form-check-input" type="radio" value="0" name="litige">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Tout&nbsp;
                                        <input class="form-check-input" type="radio" value="2" name="litige">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="">Type Domaine/Parcelle </label>

                            <ng-multiselect-dropdown [placeholder]="'Type Domaine/Parcelle'" [settings]="dropdownSettings"
                                [data]="domains" name="domain" [(ngModel)]="domainsSelected"
                                [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                            </ng-multiselect-dropdown>

                        </div>
                        <!--
                           <div class="form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for="">TF</label>
                                </div>
                                <div class="col-md-10">
                                    <div class="form-check form-check-inline">
                                        Avec TF&nbsp;
                                        <input class="form-check-input" ngModel type="radio" value="1" name="tf" >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Sans TF&nbsp;
                                        <input class="form-check-input" ngModel type="radio" value="0" name="tf" >
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <span style="font-weight: bold;">Tout</span> &nbsp;
                                        <input class="form-check-input" ngModel type="radio" value="2" name="tf" >
                                    </div>
                                </div>
                            </div>
                        </div>
                        -->

                        <div class="form-group">
                            <label for="">Mode d'acquisition</label>
                            <ng-multiselect-dropdown [placeholder]="'Mode d acquisition'" [settings]="dropdownSettings"
                                [data]="methodAcquisition" name="acquisitionMode" [(ngModel)]="acquisitionModeSelected"
                                [settings]="dropdownSettings" (onSelect)="onItemSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="form-group">
                            <label for="">Types droits</label>
                            <ng-multiselect-dropdown [placeholder]="'Types droits'" [settings]="dropdownSettings"
                                [data]="rigthTypes" name="rigthTypes" [(ngModel)]="rigthTypesSelected" [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for="">Ayants droits : </label>
                                </div>
                                <div class="col-md-10">
                                    <div class="form-check form-check-inline">
                                        Personnes physiques&nbsp;
                                        <input class="form-check-input" type="checkbox" ngModel name="naturalParson">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Personnes morales&nbsp;
                                        <input class="form-check-input" type="checkbox" ngModel name="nonNaturalPerson">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Groupe informel&nbsp;
                                        <input class="form-check-input" type="checkbox" ngModel name="group">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for="">Sexe : </label>
                                </div>
                                <div class="col-md-10">
                                    <div class="form-check form-check-inline">
                                        Masculin&nbsp;
                                        <input class="form-check-input" type="radio" name="sex" ngModel value="1">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Féminin&nbsp;
                                        <input class="form-check-input" type="radio" name="sex" ngModel value="0">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Non défini&nbsp;
                                        <input class="form-check-input" type="radio" name="sex" ngModel value="null">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Tout&nbsp;
                                        <input class="form-check-input" type="radio" name="sex" ngModel value="2">
                                    </div>
                                    <!--<div class="form-check form-check-inline">
                                        Inconnu&nbsp;
                                        <input class="form-check-input" type="radio" name="sex" ngModel value="2">
                                    </div>-->
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for="">Age : </label>
                                </div>
                                <div class="col-md-10">
                                    <div class="form-check form-check-inline">
                                        Non défini&nbsp;
                                        <input class="form-check-input" type="radio" name="old" [(ngModel)]="selected_old"
                                            value="null">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Tout&nbsp;
                                        <input class="form-check-input" type="radio" name="old" [(ngModel)]="selected_old"
                                            value="2">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Intervalle&nbsp;
                                        <input class="form-check-input" type="radio" name="old" [(ngModel)]="selected_old"
                                            value="1">
                                    </div>
                                    <div *ngIf="selected_old=='1'">
                                        <div class="form-check form-check-inline">
                                            <input type="number" class="form-control" name="old_min" ngModel
                                                placeholder="Age inférieur">
                                        </div> ET &nbsp;
                                        <div class="form-check form-check-inline">
                                            <input type="number" class="form-control" name="old_max" ngModel
                                                placeholder="Age supérieur">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-2">
                                    <label for="">Nationalité : </label>
                                </div>
                                <div class="col-md-10">
                                    <div class="form-check form-check-inline">
                                        Beninoise&nbsp;
                                        <input class="form-check-input" value="1" type="radio" name="nationality">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        Etrangère&nbsp;
                                        <input class="form-check-input" value="0" type="radio" name="nationality">
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <span style="font-weight: bold;">Tout</span> &nbsp;
                                        <input class="form-check-input" value="2" type="radio" name="nationality">
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div class="modal-footer">
                        <button class="btn-lg btn-success" type="submit">Valider</button>
                    </div>
                </form>
            </ng-template>
            <ng-template #contentUL let-modal>
                <form #updateWithCheckForm="ngForm" (ngSubmit)="setLocalityWithCheck(selectedLocality)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Selectionner une localité</h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <h3>X</h3>
                        </button>
                    </div>
                    <div class="modal-body">

                        <div class="row col-md-12 d-flex justify-content-between">
                            <div class="form-group form-inline">
                                <input class="form-control form-control-sm " placeholder="Rechercher" type="text"
                                    [(ngModel)]="searchText" name="searchText" (keyup)="search2()" />
                            </div>
                        </div>
                        <div class="row col-md-12 d-flex justify-content-end mb-2">
                            <button type="button" class="btn btn-outline-dark" type="submit" (click)="selectLocality()">Sélectionner</button>
                        </div>

                        <table class="table  table-striped " id="example">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Localité</th>
                                    <th>Infos</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let el of localities | slice: (page2-1) * pageSize2 : (page2-1) * pageSize2 + pageSize2; let i=index">
                                    <td>
                                        <input type="radio" [(ngModel)]="selectedValue" name="locality" value="{{el.id}}">
                                    </td>
                                    <td>
                                        {{el.libelle}}
                                    </td>
                                    <td class="hidden-xs">
                                        {{el.libelleUnique}}
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <div *ngIf="loading" class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <div class="loader mb-2"></div>
                            </div>
                        </div>
                        <ngb-pagination class="" [collectionSize]="collectionSize2" [(page)]="page2" [maxSize]="5"
                            [pageSize]="pageSize2" [rotate]="true" [ellipses]="true" [boundaryLinks]="true">
                        </ngb-pagination>
                    </div>
                    <div class="modal-footer">
                    </div>
                </form>
            </ng-template>

        </div>
        <!-- content -->

        <app-footer>
        </app-footer>

    </div>

    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->
