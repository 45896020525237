
import { Injectable } from "@angular/core";  
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";  
import { Observable } from "rxjs";  
import { Record } from "./../_models/interview.model";  
import { InterviewService } from "../_services/interview.service";
import { LocalService } from "../_services/browser-storages/local.service";

@Injectable()
export class InterviewsGetPageOneResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        
        return this.dataService.getAll(0,10,   
        this.localService.getJsonValue('userData').organisation!=null ? this.localService.getJsonValue('userData').organisation.id : null);  
      }      
}

@Injectable()
export class InterviewsRejectGetPageOneResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        
        return this.dataService.getAllReject(0,10,   
        this.localService.getJsonValue('userData').organisation!=null ? this.localService.getJsonValue('userData').organisation.id : null);  
      }      
}

@Injectable()
export class InterviewsValidateGetPageOneResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        
        return this.dataService.getAllValidate(0,10,    
        this.localService.getJsonValue('userData').organisation!=null ? this.localService.getJsonValue('userData').organisation.id : null);  
      }      
}

@Injectable()
export class InterviewsPostValidationGetPageOneResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
       
        return this.dataService.getAllPostValidation(0,10, 
            this.localService.getJsonValue('userData').organisation!=null ? this.localService.getJsonValue('userData').organisation.id : null);  
      }      
}

@Injectable()
export class InterviewGetResolve implements Resolve<any>{
    constructor(private dataService: InterviewService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<any> { 
         console.log(route.params.id.toString().length)
        if(route.params.id.toString().length==36){
            return this.dataService.get(route.params.id);  
        }else{
            return this.dataService.getByLong(route.params.id);  
        }
      }    
}



@Injectable()
export class ConsolidationDownloadListGetResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        
        return this.dataService.downloadList();  
      }      
}
@Injectable()
export class ConsolidationSuccessListGetResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        
        return this.dataService.downloadList();  
      }      
}

/*
@Injectable()
export class ConsolidationFilesListGetResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        console.log(route.params.session) 
        return this.dataService.shapeFileData(route.params.session);  
      }      
}
*/

@Injectable()
export class ConsolidationGetAllParcelleResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        
        return this.dataService.getAllParcelle();  
      }      
}

@Injectable()
export class RecordUnconsolidateGetAllResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        
        return this.dataService.recordunconsolidated();  
      }      
}


@Injectable()
export class ConsolidationFailedGetAllResolve implements Resolve<Record[]>{
    constructor(private dataService: InterviewService, private localService:LocalService) {}  
    resolve(route: ActivatedRouteSnapshot): Observable<Record[]> {  
        
        return this.dataService.consolidationFailed();  
      }      
}

