import { Component, OnInit } from '@angular/core';
import { InterviewService } from '../core/_services/interview.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Record } from '../core/_models/interview.model';
import { Location } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AlertNotif } from '../alert';
import { UserService } from '../core/_services/user.service';
import { RoleService } from '../core/_services/role.service';
declare var $: any;

@Component({
  selector: 'app-detail-interview',
  templateUrl: './detail-interview.component.html',
  styleUrls: ['./detail-interview.component.css'],
})
export class DetailInterviewComponent implements OnInit {
  constructor(
    private interviewService: InterviewService,
    private modalService: NgbModal,
    public roleService: RoleService,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private _location: Location
  ) {}

  data: any;
  right: any;
  comments: any[] = [];
  _temp: any[] = [];
  selected_comment: any;
  page = 1;
  pageSize = 4;
  ref = '';
  size = 0;
  content = '';
  collectionSize = 0;
  Null = null;
  Zero = 0;

  closeResult = '';
  finalized = 'finalize';
  current_role: any;
  Admin = 'ADMIN';
  Responsable = 'RESPONSABLE';
  Superviseur = 'SUPERVISEUR';
  INVITE = 'INVITE';
  GESTIONNAIREDEDATA = 'GESTIONNAIREDEDATA';
  ENQUETEURTOPO = 'ENQUETEURTOPO';

  users: any[] = [];

  methodAcquisition = [
    { id: 'Achat', libelle: 'Achat' },
    { id: 'Don', libelle: 'Don' },
    { id: 'Heritage', libelle: 'Héritage' },
    { id: 'Legs', libelle: 'Legs' },
    { id: 'Testament', libelle: 'Testament' },
    { id: 'Libre_installation', libelle: 'Libre installation' },
    { id: 'Heritage_non_partager', libelle: 'Héritage non partagé' },
    { id: 'Attribution_coutumiere', libelle: 'Attribution coutumière' },
    { id: 'Echange', libelle: 'Echange' },
    { id: 'accession', libelle: 'Accession' },
    { id: 'incorporation', libelle: 'Incorporation' },
    { id: 'prescription', libelle: 'Prescription' },
    { id: 'other', libelle: 'Autres' },
  ];
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openUpdateModal(content, el) {
    this.selected_comment = el;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  backClicked() {
    this._location.back();
  }

  checkStatus(status) {
    if (status == 'pending') {
      return 'En attente';
    }
    if (status == 'finalize') {
      return 'Finalisé';
    }
    if (status == 'validated') {
      return 'Validé';
    }
    if (status == 'rejected') {
      return 'Rejeté';
    }
  }

  showModeAcquisition(key) {
    if (key == '' || key == null || key == '-') {
      return key;
    }
    return this.methodAcquisition.find((e) => e.id == key).libelle;
  }

  ngOnInit(): void {
    $.getScript('assets/js/jquery.min.js');
    $.getScript('assets/js/bootstrap.bundle.min.js');
    $.getScript('assets/js/metismenu.min.js');
    $.getScript('assets/js/jquery.slimscroll.js');
    $.getScript('assets/js/waves.min.js');
    $.getScript('assets/js/app.js');

    if (localStorage.getItem('userRole') != null) {
      this.current_role = localStorage.getItem('userRole');
    }

    this.content = '';
    this.users = [];
    this.userService.getAll().subscribe((res: any) => {
      this.users = res.data;
    });
    this.ref = this.activatedRoute.snapshot.paramMap.get('id');
    const rest = (this.data = this.activatedRoute.snapshot.data.details);
    if (rest.code == 200) {
      this.data = rest.data;
      this.right = this.data.right;
    }
    if (rest.status == '500') {
      AlertNotif.finish('Erreur', 'Oups, un problème est survenu', 'success');
    }

    /*    this.interviewService.get(this.ref).subscribe((res: any) => {
      this.data = res.data
      this.right = this.data.right
      console.log("Detail interview", this.data)
      console.log("Right", this.right)
    })
*/
    this.aoldCommentaire();
  }
  init() {
    this.ref = this.activatedRoute.snapshot.paramMap.get('id');
    this.interviewService.get(this.ref).subscribe((rest: any) => {
      if (rest.code == 200) {
        this.data = rest.data;
        this.right = this.data.right;
      }
      if (rest.status == '500') {
        AlertNotif.finish('Erreur', 'Oups, un problème est survenu', 'success');
      }
    });
  }

  aoldCommentaire() {
    this.comments = [];
    this.interviewService.getAllComment(this.ref).subscribe((res: any) => {
      console.log('Commentaires', res);
      res.data.forEach((e) => {
        this.users.forEach((u) => {
          if (e.writerId == u.id) {
            e.writer = u;
            this.comments.push(e);
          }
        });
      });
      console.log(this.comments);
      this._temp = this.comments;
      this.collectionSize = this.comments.length;
    });
  }

  addComment(value) {
    value.rightId = this.ref;
    this.interviewService.addComment(value).subscribe((res: any) => {
      if (res.code == 200 || res.code == 201) {
        this.content = '';
        this.aoldCommentaire();
      } else {
        AlertNotif.finish(
          'Erreur',
          "Une erreur est survenue, veuillez contacter l'administrateur SVP",
          'error'
        );
      }
    });
  }

  localisation() {
    if (
      this.data == null ||
      this.data.parcel == null ||
      this.data.parcel.locality == null
    ) {
      return '-';
    }

    let currentLocality = this.data.parcel.locality;
    let out = '';

    while (true) {
      out = out + currentLocality.libelle + '/';
      if (
        currentLocality.idTypeLocalite == 1 ||
        currentLocality.localityMere == null
      ) {
        break;
      }
      currentLocality = currentLocality.localityMere;
    }
    out = out.slice(0, -1).split('/').reverse().join('/');

    return out;
  }

  isUndefined(variable): boolean {
    return typeof variable === 'undefined';
  }

  parcelType(type, nature, specificNature): string {
    let out = '';
    if (type != null && type.trim().length !== 0) {
      out = out + type.replace('_', ' ');
    }
    if (nature != null && nature.trim().length !== 0) {
      out =
        out + (out.trim().length !== 0 ? '/' : '') + nature.replace('_', ' ');
    }
    if (specificNature != null && specificNature.trim().length !== 0) {
      out = out + (out.trim().length !== 0 ? '/' : '');
      out = out + specificNature.replace('_', ' ');
    }
    if (out.trim().length === 0) {
      out = 'néant';
    }
    return out;
  }

  imageExists(photo_object) {
    if (
      photo_object == null ||
      photo_object.fileUri ||
      photo_object.fileUri.length == 0
    ) {
      return false;
    }

    const http = new XMLHttpRequest();

    http.open('HEAD', photo_object.fileUri, false);
    http.send();

    return http.status == 200;
  }

  imaga_path(photo_object,avatar) {
    console.log('--------------A122222222222--------99992');
    console.log(this.data);
    if(avatar==1){
      return photo_object != null
      ? 'https://infocad.andf.bj/api/resources/' + photo_object.fileUri
      : './assets/img/stick-man.png';
    }else{
      return photo_object != null
      ? 'https://infocad.andf.bj/api/resources/' + photo_object.fileUri
      : '#';
    }
    
  }

  back() {
    //     console.log(this.activatedRoute.parent)
    //     this.router.navigate({ relativeTo: this.activatedRoute.parent })
    this.router.navigate(['../'], { relativeTo: this.activatedRoute });
  }
  deleteComment(id, i) {
    AlertNotif.finishConfirm(
      'Suppression de commentaire',
      'Voulez-vous continuer ?'
    ).then((result) => {
      if (result.value) {
        this.interviewService.deleteComment(id).subscribe(
          (res: any) => {
            if (res.code == 200) {
              this.comments.splice(i, 1);
              AlertNotif.finishConfirmWithReload(
                'Suppression de commentaire',
                'Suppression effectué avec succès',
                'success'
              );
              // TODO: Desactiver le reload
            } else {
              AlertNotif.finish(
                'Erreur',
                "Une erreur est survenue, veuillez contacter l'administrateur SVP",
                'error'
              );
            }
          },
          (err) => {
            AlertNotif.finish(
              'Suppression de commentaire',
              'Une erreur est survenue, verifier votre connexion SVP',
              'error'
            );
          }
        );
      }
    });
  }
  markCommentAsRead(id) {
    this.interviewService.markCommentAsRead(id).subscribe((res: any) => {
      if (res.code == 200) {
        this.aoldCommentaire();
      } else {
        AlertNotif.finish(
          'Erreur',
          "Une erreur est survenue, veuillez contacter l'administrateur SVP",
          'error'
        );
      }
    });
  }
  editComment(value) {
    this.interviewService
      .updateComment(this.selected_comment)
      .subscribe((res: any) => {
        if (res.code == 200) {
          this.modalService.dismissAll();
          this.aoldCommentaire();
        } else {
          AlertNotif.finish(
            'Erreur',
            "Une erreur est survenue, veuillez contacter l'administrateur SVP",
            'error'
          );
        }
      });
  }

  validateRecord(id) {
    AlertNotif.finishConfirm(
      "Validation d'enregistrement",
      'Voulez-vous continuer ?'
    ).then((result) => {
      if (result.value) {
        this.interviewService.validate(id).subscribe(
          (res: any) => {
            if (res.code == 200) {
              // window.location.reload()
              this.modalService.dismissAll();
              this.router.navigate(['interviews']);
              AlertNotif.finish(
                "Validation d'enregistrement",
                'Validation effectué avec succès',
                'success'
              );
            } else {
              AlertNotif.finish(
                "Validation d'enregistrement",
                'Une erreur est survenue',
                'error'
              );
            }
            // TODO: Desactiver le reload
          },
          (err) => {
            AlertNotif.finish(
              "Validation d'enregistrement",
              'Une erreur est survenue, verifier votre connexion SVP',
              'error'
            );
          }
        );
      }
    });
  }

  rejectRecord(id) {
    AlertNotif.finishConfirm(
      "Rejet d'enregistrement",
      'Voulez-vous continuer ?'
    ).then((result) => {
      if (result.value) {
        // TODO: Desactiver le reload
        this.interviewService.reject(id).subscribe(
          (res: any) => {
            if (res.code == 200) {
              //  window.location.reload()
              this.modalService.dismissAll();
              this.router.navigate(['interviews']);
              AlertNotif.finish(
                "Rejet d'enregistrement",
                'Rejet effectué avec succès',
                'success'
              );
            } else {
              AlertNotif.finish(
                "Rejet d'enregistrement",
                'Une erreur est survenue',
                'error'
              );
            }
          },
          (err) => {
            AlertNotif.finish(
              "Rejet d'enregistrement",
              'Une erreur est survenue, verifier votre connexion SVP',
              'error'
            );
          }
        );
      }
    });
  }
}
