import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../app.config';
import { catchError, tap, map, delay } from 'rxjs/operators';
import { Observable } from "rxjs";
import { User } from '../_models/user.model';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = "api/users";
  constructor(private http: HttpClient) { }


  getAll(): Observable<User[]> {

    return this.http.get<User[]>(`${this.url}`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));

  }
  getOrgUsers(org_id): Observable<User[]> {
    return this.http.get<User[]>(`api/organizations/${org_id}/users`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));
  }
  getAllMobileUser(org_id): Observable<User[]> {

    return this.http.get<User[]>(`${this.url}/mobile`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));
  }

  getAnalyse(user_id) {

    return this.http.get<any[]>(`api/users/${user_id}/analyse`, Config.httpHeader(localStorage.getItem("userToken"), true));
  }


  validate(id) {

    return this.http.patch<any[]>(`api/users/${id}/mobile/validate`, {}, Config.httpHeader(localStorage.getItem("userToken"), true));
  }

  get(id) {

    return this.http.get<any[]>(`${this.url}/${id}`, Config.httpHeader(localStorage.getItem("userToken"), true));
  }
  getMe(): Observable<User> {

    return this.http.get<User>(`api/users/me`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("userToken")
      })
    }).pipe(delay(1000));
  }


  create(ressource) {
    return this.http.post<any>("api/users", ressource, Config.httpHeader(localStorage.getItem("userToken"), true)).pipe(
      tap((ressource: any) => console.log(`added ressource ${ressource}`))
    );
  }
  update(ressource, id) {
    return this.http.put<any>(`${this.url}/${id}`, ressource, Config.httpHeader(localStorage.getItem("userToken"), true)).pipe(
      tap((ressource: any) => console.log(`----------------------- updated ressource ${ressource}`))
    );
  }
  setStatus(id, status) {
    return this.http.patch<any>(`api/users/${id}/enable?state=${status}`, {}, Config.httpHeader(localStorage.getItem("userToken"), true)).pipe(
      tap((ressource: any) => console.log(`updated ressource ${ressource}`))
    );
  }



  delete(id: number) {
    return this.http.delete<any[]>(`api/users/${id}/delete`, Config.httpHeader());
  }
  deleteOnTeam(id: number) {
    return this.http.delete<any[]>(`api/users/${id}/delete/on/team`, Config.httpHeader());
  }
}
