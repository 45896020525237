<!-- Begin page -->
<div id="wrapper">
    <!-- Top Bar Start -->
    <div class="topbar">
        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="" />
                <br />
            </a>
        </div>

        <app-header></app-header>
    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Bienvenue sur INFOCAD</h4>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-right">
                                <li class="breadcrumb-item">INFOCAD</li>
                                <li class="breadcrumb-item active">Tableau de bord</li>
                            </ol>
                        </div>
                    </div>
                    <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row my-2" *ngIf="show_filter">
                    <div class="col-md-4">
                        <!--
                        <div class="" ngbDropdown container="body">
                            <button class="btn btn-outline-primary btn px-5 " ngbDropdownToggle>Afficher une carte</button>
                            <div ngbDropdownMenu>
                                <button (click)="openAddModal(village, False)" ngbDropdownItem>Filtre</button>
                                <button *ngIf="user.organisation==Null" (click)="openAddModal(village, True)" ngbDropdownItem>Par prestataire</button>


                            </div>
                        </div>
                            -->
                    </div>
                    <div class="col-md-4 d-flex justify-content-center">
                        <div *ngIf="selected_village != Vide">
                            <span>Village : {{ selected_locality.libelle }}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-xl-3">
                        <div class="card" style="height: 201px !important">
                            <div class="card-header h-50">
                                <div class="row">
                                    <div class="col-6">
                                        <h3 class="font-16 font-weight-bold">Socio</h3>
                                        <h5 class="font-14">Total Enregistrements</h5>
                                    </div>
                                    <div class="col-6 mini-stat-icon d-flex justify-content-center align-items-center">
                                        <i class="mdi mdi-cube-outline bg-primary text-white"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <h3 class="text-center">{{ stat.socioTotal }}</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-xl-3">
                        <div class="card" style="height: 201px !important">
                            <div class="card-header h-50">
                                <div class="row">
                                    <div class="col-6">
                                        <h3 class="font-16 font-weight-bold">Topo</h3>
                                        <h5 class="font-14">Total Parcelles</h5>
                                    </div>
                                    <div class="col-6 mini-stat-icon d-flex justify-content-center align-items-center">
                                        <i class="mdi mdi-briefcase-check bg-success text-white"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <h3 class="text-center">{{ stat.total }}</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-xl-3">
                        <div class="card" style="height: 201px !important">
                            <div class="card-header h-50">
                                <div class="row">
                                    <div class="col-6">
                                        <h3 class="font-16 font-weight-bold"></h3>
                                        <h5 class="font-14">Total Consolidées</h5>
                                    </div>
                                    <div class="col-6 mini-stat-icon d-flex justify-content-center align-items-center">
                                        <i class="mdi mdi-tag-text-outline bg-warning text-white"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <h3 class="text-center">{{ stat.consolidated }}</h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-xl-3">
                        <div class="card" style="height: 201px !important">
                            <div class="card-header h-50">
                                <div class="row">
                                    <div class="col-6">
                                        <h3 class="font-16 font-weight-bold"></h3>
                                        <h5 class="font-14">Échec de consolidation</h5>
                                    </div>
                                    <div class="col-6 mini-stat-icon d-flex justify-content-center align-items-center">
                                        <i class="mdi mdi-buffer bg-danger text-white"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <h3 class="text-center">
                                    {{ stat.total - stat.consolidated }}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <!-- minimal widget consist of .widget class. note bg-transparent - it can be any background like bg-gray,
                                        bg-primary, bg-white -->
                        <section class="card bg-transparent">
                            <!-- .widget-body is a mostly semantic class. may be a sibling to .widget>header or .widget>footer -->
                            <div class="card-body">
                                <div id="map" class="mapael">
                                    <form #villageForm="ngForm" (ngSubmit)="villageSubmit(villageForm.value)">
                                        <div class="col-md-12">
                                            <div class="row d-flex justify-content-end">
                                                <div class="col-md-4">
                                                  <div *ngIf="user.organisation == Null" class="form-group form-inline">
                                                    <label class="col-md-5">Organisation</label>
                                                    <select #org="ngModel" (change)="reinitOrg($event)" class="form-control col-md-7" id="" name="org"
                                                            ngModel required>
                                                      <option *ngFor="let el of orgs; let i = index" [value]="el.id">
                                                        {{ el.designation }}
                                                      </option>
                                                    </select>

                                                  </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group form-inline">
                                                        <label class="col-md-4">Equipes</label>
                                                        <select #team="ngModel" name="team" (change)="loadLocalities($event)"
                                                            ngModel id="" class="form-control col-md-8">
                                                            <option [value]="i" *ngFor="let el of teams; let i = index">
                                                                {{ el.cote }}
                                                                (
                                                                <small *ngFor="let m of el.members; let ix = index">
                                                                    {{ m.forename }} {{ m.surname }}
                                                                    {{ ix == el.members.length - 1 ? "" : "," }}
                                                                </small>
                                                                )
                                                            </option>
                                                        </select>
                                                        <span *ngIf="
                                                                    team.invalid && (team.dirty || team.touched)
                                                                "
                                                            class="text-danger">
                                                            <small *ngIf="team.errors?.required">
                                                                Equipe sont requis
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="col-md-3">
                                                    <div class="form-group form-inline">
                                                        <label class="col-md-4">Localité</label>
                                                        <select #locality="ngModel" name="locality" ngModel id="" class="form-control col-md-8">
                                                            <option [value]="i" *ngFor="
                                  let el of current_localities;
                                  let i = index
                                ">
                                                                {{ el.libelle }}
                                                            </option>
                                                        </select>
                                                        <span *ngIf="
                                locality.invalid &&
                                (locality.dirty || locality.touched)
                              "
                                                            class="text-danger">
                                                            <small *ngIf="locality.errors?.required">
                                                                Localité sont requis
                                                            </small>
                                                        </span>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <button class="btn btn-outline-dark align-self-center" [disabled]="!villageForm.form.valid"
                                                        type="submit">
                                                        Filtrer
                                                        <span class="spinner-border spinner-border-sm" role="status"
                                                            aria-hidden="true" *ngIf="mapVillageLoading"></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div id="chartdiv" *ngIf="!nodata" (click)="getCoord($event, infos)" style="width: 100%; height: 500px"></div>
                                    <div class="d-flex justify-content-center align-items-center text-muted" *ngIf="nodata" style="width: 100%; height: 200px">
                                        <h3 class="">Carte</h3>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col-md-4" style="display: none">
                        <section class="widget bg-transparent">
                            <header>
                                <h5>
                                    Map
                                    <span class="fw-semi-bold">Statistics</span>
                                </h5>
                                <div class="widget-controls widget-controls-hover">
                                    <a href="#"><i class="glyphicon glyphicon-cog"></i></a>
                                    <a href="#"><i class="fa fa-refresh"></i></a>
                                    <a href="#" data-widgster="close"><i class="glyphicon glyphicon-remove"></i></a>
                                </div>
                            </header>
                            <div class="widget-body">
                                <p>Statut: <strong>en live</strong></p>
                                <p>
                                    <span class="circle bg-warning"><i class="fa fa-map-marker"></i></span>
                                    146 Commune, 2759 Quartiers
                                </p>
                                <div class="row progress-stats">
                                    <div class="col-sm-9">
                                        <h6 class="name">Corrections</h6>
                                        <div class="progress progress-sm js-progress-animate bg-white">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="60"
                                                aria-valuemin="0" data-width="60%" aria-valuemax="100" style="width: 0">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 d-flex justify-content-start align-items-center text-align-center">
                                        <span class="status rounded rounded-lg bg-body-light">
                                            <small><span id="percent-1">75</span>%</small>
                                        </span>
                                    </div>
                                </div>
                                <div class="row progress-stats">
                                    <div class="col-sm-9">
                                        <h6 class="name">Validations</h6>
                                        <div class="progress progress-sm js-progress-animate bg-white">
                                            <div class="progress-bar progress-bar-danger" role="progressbar"
                                                aria-valuenow="39" data-width="39%" aria-valuemin="0" aria-valuemax="100"
                                                style="width: 0"></div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 d-flex justify-content-start align-items-center text-align-center">
                                        <span class="status rounded rounded-lg bg-body-light">
                                            <small><span id="percent-2">84</span>%</small>
                                        </span>
                                    </div>
                                </div>
                                <div class="row progress-stats">
                                    <div class="col-sm-9">
                                        <h6 class="name">Rejets</h6>
                                        <div class="progress progress-sm js-progress-animate bg-white">
                                            <div class="progress-bar progress-bar-success" role="progressbar"
                                                aria-valuenow="80" data-width="80%" aria-valuemin="0" aria-valuemax="100"
                                                style="width: 0"></div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 d-flex justify-content-start align-items-center text-align-center">
                                        <span class="status rounded rounded-lg bg-body-light">
                                            <small><span id="percent-3">92</span>%</small>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <!-- container-fluid -->
            <ng-template #infos let-modal id="infos">
                <form>
                    <div class="modal-header">
                        <h6 class="modal-title" id="modal-basic-title">
                            Informations sur la parcelle sélectionnée
                        </h6>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            X
                        </button>
                    </div>
                    <div class="modal-body">
                        <small *ngIf="current_feature_data.id_socio === null && current_feature_data.id_socio === 0"
                            class="text-center">
                            Cette parcelle n'est pas consolidée
                        </small>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-nup">N° provisoire</label>
                                    <input id="modal-bbox-nup" class="form-control" name="nup" [(ngModel)]="current_feature_data.nup"
                                        disabled />
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-numtf">N°TF</label>
                                    <input id="modal-bbox-numtf" class="form-control" name="ntf" [(ngModel)]="current_feature_data.nup"
                                        disabled />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-departement">Département</label>
                                    <input id="modal-bbox-departement" name="departement" [(ngModel)]="current_feature_data.districtna"
                                        class="form-control" disabled />
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-commune">Commune</label>
                                    <input id="modal-bbox-commune" name="commune" class="form-control" [(ngModel)]="current_feature_data.subcountyn"
                                        disabled />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-arrondissement">Arrondissement</label>
                                    <input id="modal-bbox-arrondissement" name="arrondissement" [(ngModel)]="current_feature_data.parishname"
                                        class="form-control" disabled />
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-quartier">Quartier</label>
                                    <input id="modal-bbox-quartier" class="form-control" name="quartier" [(ngModel)]="current_feature_data.villagenam"
                                        disabled />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-ilot">Ilot</label>
                                    <input id="modal-bbox-ilot" class="form-control" disabled />
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-parcelle">Parcelle</label>
                                    <input id="modal-bbox-parcelle" name="parcelle" [(ngModel)]="current_feature_data.parcelleno"
                                        class="form-control" disabled />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-surface">Surface estimée (m²)</label>
                                    <input id="modal-bbox-surface" name="surface" class="form-control" [(ngModel)]="current_feature_data.superficie"
                                        disabled />
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-datetf">Date TF</label>
                                    <input id="modal-bbox-datetf" class="form-control" disabled />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button *ngIf="
                                current_feature_data.id_socio != Null &&
                                current_feature_data.id_socio!;
                                zero
                            "
                                            (click)="
                                viewExtrait(
                                current_feature_data.parcelleno,
                                current_feature_data.id_socio
                                )
                            "
                            class="btn btn-white border-0 mx-1">
                            Voir Extrait cadastral
                        </button>
                                    <button *ngIf="
                            current_feature_data.id_socio != Null &&
                            current_feature_data.id_socio!;
                            zero
                        "
                            class="btn btn-white border-0 mx-1" (click)="viewRecord(current_feature_data.id_socio)">
                            Voir enregistrement socio
                        </button>
                    </div>
                </form>
            </ng-template>
            <ng-template #village let-modal>
                <form #villageForm="ngForm" (ngSubmit)="villageSubmit(villageForm.value)">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">
                            Afficher par village
                        </h4>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <i class="fa fa-remove font-size-h3"></i>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="form-group" *ngIf="user.organisation == Null">
                            <label>Organisation</label>
                            <select #org="ngModel" name="org" required ngModel id="" class="form-control">
                                <option [value]="i" *ngFor="let el of orgs; let i = index">
                                    {{ el.designation }}
                                </option>
                            </select>
                            <span *ngIf="org.invalid && (org.dirty || org.touched)" class="text-danger">
                                <small *ngIf="org.errors?.required">
                                    Organisation sont requis
                                </small>
                            </span>
                        </div>
                        <div class="form-group">
                            <label>Equipes</label>
                            <select #team="ngModel" name="team" (change)="loadLocalities($event)" ngModel id="" class="form-control">
                                <option [value]="i" *ngFor="let el of teams; let i = index">
                                    {{ el.cote }}
                                    (
                                    <small *ngFor="let m of el.members; let ix = index">
                                        {{ m.forename }} {{ m.surname }}
                                        {{ ix == el.members.length - 1 ? "" : "," }}
                                    </small>
                                    )
                                </option>
                            </select>
                            <span *ngIf="team.invalid && (team.dirty || team.touched)" class="text-danger">
                                <small *ngIf="team.errors?.required">
                                    Equipe sont requis
                                </small>
                            </span>
                        </div>
                        <div class="form-group">
                            <label>Localité</label>
                            <select #locality="ngModel" name="locality" ngModel id="" class="form-control">
                                <option [value]="i" *ngFor="let el of current_localities; let i = index">
                                    {{ el.libelle }}
                                </option>
                            </select>
                            <span *ngIf="locality.invalid && (locality.dirty || locality.touched)" class="text-danger">
                                <small *ngIf="locality.errors?.required">
                                    Localité sont requis
                                </small>
                            </span>
                        </div>
                    </div>
                    <!--
                    <div class="modal-body d-flex flex-column">
                        <div class="form-group">
                            <label for="">Villages</label>
                            <select name="village" [(ngModel)]="selected_village" required class="form-control" id="">
                                <option value="all">Tout</option>
                                <option [value]="el" *ngFor="let el of villages; let i=index">{{el}}</option>
                            </select>
                        </div>
                    </div>

                    -->
                    <div class="modal-footer">
                        <button class="btn btn-outline-dark align-self-center" [disabled]="!villageForm.form.valid"
                            type="submit">
                            Sauvegarder
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="mapVillageLoading"></span>
                        </button>
                    </div>
                </form>
            </ng-template>
        </div>
        <!-- content -->

        <app-footer> </app-footer>
    </div>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->
</div>
<!-- END wrapper -->
