<div>
  <div class="row ">
    <div class="col-md-12 mt-3">
      <div class="card">
        <div class="card-header">
          <h3>Liste des enquêtes rejetées</h3>
          <div class="row">
            <div class="col-6 col-md-6 col-sm-12 d-flex justify-content-start">
              <h6>Enquêtes trouvées : {{ pager.total }}</h6>
            </div>
            <div class="col-6 col-md-6 col-sm-12 d-flex justify-content-end form-group">
              <button (click)="refresh_page() " class="btn btn-secondary mx-2">Rafraichir page</button>
              <button (click)="refreshInterviewData() " class="btn btn-info mx-2">Rafraichir données</button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <form #filterForm="ngForm" (ngSubmit)="filter(filterForm.value,0,'1')">
            <div class="row">
              <div class="col-6 form-group form-inline d-flex justify-content-between" *ngIf="user.organisation == Null">

                <label class="col-md-5 d-flex justify-content-start">Organisation</label>
                <select #org="ngModel" name="org" (change)="reinitOrg($event)" required [(ngModel)]="selected_org" id=""
                  class="form-control col-md-7">
                  <option [value]="el.id" *ngFor="let el of orgs; let i = index">
                    {{ el.designation }}
                  </option>
                </select>

              </div>
              <div class="col-6 form-group form-inline d-flex justify-content-between">
                <label for="" class="col-md-5 d-flex justify-content-start">Equipe : </label>
                <select #team="ngModel" required name="team" (change)="getLocalitiesLev1($event)" [(ngModel)]="selected_team"
                  id="" class="form-control col-md-7">
                  <option selected disabled>Choisir une équipe</option>
                  <option [value]="el.id" *ngFor="let el of teams; let i=index">{{el.cote}}
                    (
                    <small *ngFor="let m of el.members; let ix=index">
                      {{m.forename}} {{m.surname}}
                      {{ ix==(el.members.length-1) ? '' :','}}

                    </small>
                    )
                  </option>
                </select>
              </div>
              <div class="col-6 form-inline d-flex justify-content-between">
                <label for="" class="col-md-5 d-flex justify-content-start">​ Département : </label>
                <select #departement="ngModel" [(ngModel)]="selected_dep" (change)="getLocalitiesLev2($event)" name="departement"
                  id="" class="form-control col-md-7">
                  <option selected disabled>Choisir un département</option>
                  <option [value]="i" *ngFor="let el of h_departements; let i=index">
                    {{el.libelle}} </option>
                </select>
              </div>
              <div class="col-6 form-group form-inline d-flex justify-content-between">
                <label for="" class="col-md-5 d-flex justify-content-start">​ Commune : </label>
                <select #commune="ngModel" [(ngModel)]="selected_commune" (change)="getLocalitiesLev3($event)" name="commune"
                  id="" class="form-control col-md-7">
                  <option selected disabled>Choisir une commune</option>
                  <option [value]="i" *ngFor="let el of h_communes; let i=index">
                    {{el.libelle}} </option>
                </select>
              </div>

              <div class="col-6 form-inline d-flex justify-content-between">
                <label for="" class="col-md-5 d-flex justify-content-start">​ Arrondissement : </label>
                <select #arrondissement="ngModel" [(ngModel)]="selected_arr" (change)="getLocalitiesLev4($event)" name="arrondissement"
                  id="" class="form-control col-md-7">
                  <option selected disabled>Choisir un arrondissement</option>
                  <option [value]="i" *ngFor="let el of h_arrondissements; let i=index">
                    {{el.libelle}} </option>
                </select>
              </div>
              <div class="col-6 form-group form-inline d-flex justify-content-between">
                <label for="" class="col-md-5 d-flex justify-content-start">​ Ville/Quartier : </label>
                <select #locality="ngModel" (change)="filterLocalitiesLev4($event)" name="locality" [(ngModel)]="selected_locality"
                  id="" class="form-control col-md-7">
                  <option selected disabled>Choisir une ville/quartier</option>
                  <option [value]="el.id" *ngFor="let el of h_localities; let i=index">
                    {{el.libelle}} </option>
                </select>
              </div>

              <div class="col-6 form-inline d-flex justify-content-end" *ngIf="user.organisation != Null">
                <button class=" float-right mx-1 btn btn-primary" type="submit" [disabled]="filterForm.invalid">Filtrer</button>
                <button (click)="resetFilter()" class=" float-right mx-1 btn btn-secondary">Réinitialiser
                  filtres</button>
              </div>
              <div class="col-12 form-inline d-flex justify-content-end" *ngIf="user.organisation == Null">
                <button class=" float-right mx-1 btn btn-primary" type="submit" [disabled]="filterForm.invalid">Filtrer</button>
                <button (click)="resetFilter()" class=" float-right mx-1 btn btn-secondary">Réinitialiser
                  filtres</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <section class="card shadow-none bg-white">
            <ngx-spinner></ngx-spinner>
            <div class="card-body" style="overflow-x: scroll">
              <table class="table  table-striped ">
                <thead>
                  <tr>
                    <th>N° provisoire</th>
                    <th>Enqueteur</th>
                    <th>Présumé/Propriétaire</th>

                    <th class="hidden-xs">Localite</th>
                    <th width="5px">N° PARC</th>

                    <th>NATIONALITE</th>
                    <th>AGE</th>
                    <th>SEXE</th>
                    <th>DOMICILE</th>
                    <th>TELEPHONE</th>
                    <th>MODE D'ACQUISITION</th>
                    <th>TYPE</th>
                    <th>DATE D'ENREGISTREMENT</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="!page_loading">
                    <tr *ngFor="let interview of data; let i=index" class="{{ interview.new === -1 ? 'bg-success' : '' }}">

                      <td>
                        {{ interview.nup }}
                      </td>
                      <td>
                        {{ interview.investigator[1] }} {{ interview.investigator[0] }}
                      </td>
                      <td class="hidden-xs d-flex flex-column">
                        {{ interview.owner }}
                        <span *ngIf="interview.contested">
                          <small class="text-danger">PARCELLE LITIGIEUSE
                          </small>
                        </span>
                      </td>


                      <td class="hidden-xs text-semi-muted">
                        {{ interview.locality!=null ? interview.locality.libelle : '' }}
                      </td>
                      <td width="5px" class="flex-column">
                        {{interview.parcelNumber}}

                      </td>


                      <td>
                        {{interview.nationality}}
                      </td>
                      <td>
                        {{ (interview.age == NULL || interview.age === 0) ? "-" : interview.age }}
                      </td>
                      <td>
                        <span *ngIf="interview.sex === 0">-</span>
                        <span *ngIf="interview.sex === 1" style="font-weight: bold;">Masculin</span>
                        <span *ngIf="interview.sex === 2" style="font-weight: bold;">Féminin</span>
                        <span *ngIf="interview.sex === 9" style="font-weight: bold;">Indefini</span>
                      </td>
                      <td>
                        {{interview.address}}
                      </td>
                      <td>
                        {{interview.phone}}
                      </td>
                      <td>
                        {{showModeAcquisition(interview.acquisitionMethod)}}
                      </td>
                      <td>
                        {{ interview.parcelType==null ? '' : interview.parcelType.replace("_", " ") }}
                      </td>
                      <td>
                        <span *ngIf="interview.syncDateinterview!=Null">
                          {{interview.syncDateinterview.interview | date:'dd/MM/yyyy'}}
                        </span>
                      </td>
                      <td>

                        <div ngbDropdown container="body">
                          <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                          <div ngbDropdownMenu>
                            <a [routerLink]="['/detail-interviews',interview.localRef]" ngbDropdownItem>Voir
                              detail</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>

                  <tr *ngIf="page_loading">
                    <td class="text-center" colspan="20">
                      <img class="img-fluid mx-auto" src="assets/img/loading-icon-transparent-background-12.jpg" style="width: 50px; height: 50px;"
                        alt="">
                    </td>
                  </tr>
                  <tr *ngIf="!page_loading && data.length==0">
                    <td class="text-center" colspan="20">
                      Aucun élément
                    </td>
                  </tr>
                </tbody>

              </table>
              <div class="d-flex justify-content-end p-2">

                <div *ngIf="pager!=Null">
                  <ul *ngIf="pager.pages && pager.pages.length" class="pagination">
                    <li [ngClass]="{disabled:pager.current_page === 1}" class="page-item first-item">
                      <a routerLink="/interviews" [queryParams]="{ page: 1 }" class="page-link">Début</a>
                    </li>

                    <li [ngClass]="{disabled:pager.current_page === 1}" class="page-item previous-item">
                      <a routerLink="/interviews" [queryParams]="{ page: pager.current_page - 1 }" class="page-link">Précédent</a>
                    </li>

                    <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.current_page === page}" class="page-item number-item">
                      <a routerLink="/interviews" [queryParams]="{ page: page }" class="page-link">{{page}}</a>
                    </li>

                    <li [ngClass]="{disabled:pager.current_page === pager.last_page}" class="page-item next-item">
                      <a routerLink="/interviews" [queryParams]="{ page: pager.current_page + 1 }" class="page-link">Suivant</a>
                    </li>
                    <li [ngClass]="{disabled:pager.current_page === pager.last_page}" class="page-item next-item">
                      <a routerLink="/interviews" [queryParams]="{ page: pager.last_page }" class="page-link">Fin</a>
                    </li>
                    <li class="page-item last-item disabled">
                      <a class="page-link">{{pager.last_page}} page(s)</a>
                    </li>
                  </ul>
                </div>

              </div>

            </div>
          </section>
        </div>
      </div>
      <ng-template #comentU let-modal>
        <form #comForm="ngForm" (ngSubmit)="addComment(comForm.value)">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Commentaire</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <i class="fa fa-remove font-size-h3"></i>
            </button>
          </div>
          <div class="modal-body">


            <div class="form-group">
              <textarea class="form-control input-sm" name="text" ngModel type="text" placeholder="Votre commentaire...">
                                    </textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" [disabled]="!comForm.form.valid" type="submit">Sauvegarder</button>
          </div>
        </form>
      </ng-template>
    </div>