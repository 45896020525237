import { Component, OnInit } from '@angular/core';
import { InterviewService } from '../core/_services/interview.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { TeamService } from '../core/_services/team.service';
import { UserService } from '../core/_services/user.service';
import { AlertNotif } from '../alert';
import { Team } from '../core/_models/team.model';
declare var $: any;



@Component({
  selector: 'app-consolidation-topo-failed',
  templateUrl: './consolidation-topo-failed.component.html',
  styleUrls: ['./consolidation-topo-failed.component.css']
})
export class ConsolidationTopoFailedComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute,
    private inverviewService: InterviewService,
    private modalService: NgbModal, private teamService: TeamService,
    private localService: LocalService, ) { }
  files: File
  session = ""
  page = 1
  pageSize = 25
  Admin = "ADMIN"
  Responsable = "RESPONSABLE"
  Superviseur = "SUPERVISEUR"
  INVITE = "INVITE"
  GESTIONNAIREDEDATA = "GESTIONNAIREDEDATA"
  ENQUETEURTOPO = "ENQUETEURTOPO"
  ENQUETEURSOCIO = "ENQUETEURSOCIO"
  closeResult = ""
  current_role = ""
  data_current: any
  user: any
  data: any[] = []
  users: any[] = []
  teams: any[] = []
  _temp: any[] = []
  _temp2: any[] = []
  collectionSize = 0
  socio_data = []
  page2 = 1
  pageSize2 = 7
  collectionSize2 = 0
  One=1   
  searchText=""
  selected_data:any
  current_parcel_data

  search() {
    this.data = this._temp.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.villagenam.toLowerCase().includes(term) || 
      r.no.toString().toLowerCase().includes(term) ||
      r.firstname1.toString().toLowerCase().includes(term) ||
      r.familyname.toString().toLowerCase().includes(term) ||
      r.countyname.toString().toLowerCase().includes(term)
      
    })
    this.collectionSize = this.data.length
  }


  search2() {
    this.socio_data = this._temp2.filter(r => {
      const term = this.searchText.toLowerCase();
      return r.locality.libelle.toLowerCase().includes(term) || 
      r.nup.toString().toLowerCase().includes(term) ||
      r.owner.toString().toLowerCase().includes(term)
    })
    this.collectionSize2 = this.socio_data.length
  }
  openUpdateModal(content, el) {
    this.selected_data = el
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openDetailModal(content, el) {
    this.selected_data=el
    this.inverviewService.storageSessionData(this.session,+el.no).subscribe((res: any) => {
      this.current_parcel_data = res.data
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
   
  }



  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
      
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {

    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')


    if (localStorage.getItem('userRole') != null) {
      this.current_role = localStorage.getItem('userRole')
    }
    this.user = this.localService.getJsonValue("userData")
   
    this.session = this.activatedRoute.snapshot.paramMap.get('session');
    /*this.activatedRoute.snapshot.data.data_list.data.forEach((e)=>{
     if(e.consolidated == -1){
        this.data.push(e)
     }
    })*/
    this.specificShape("all")
   

  }

  Filter(event){
    this.specificShape(event.target.value)
  }
  specificShape(state){
    this.data = []
    this._temp = []
    this.collectionSize=0
    this.inverviewService.shapefilesDetails(this.session,state).subscribe((res: any) => {
      this.data = res.data.parcels
      this._temp = this.data
      this.collectionSize = this.data.length
    })
  }

  parcel = ""
  team_data=new Team()
  echecSocio(content, parcel, team) {
    this.parcel = parcel
    this.teamService.getAll().subscribe((res: any) => {
      res.data.forEach((e) => { if (e.id == team) { this.team_data = e } })
      //this.users=res.data
    })
    this.inverviewService.echecsocio(team).subscribe((res: any) => {
      this.socio_data = res.data
      this._temp2 = this.socio_data
      this.collectionSize2 = this.socio_data.length
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',size:'lg' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    })
  }
  setNup(content) {
  
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  
  saveNup(value){
    this.inverviewService.setParcelData(this.session,this.selected_data.nup,
      value.nup==undefined || value.nup==null ? this.selected_data.no : value.nup
      ,value.lastname,
      value.firstname1,value.firstname2).subscribe((res: any) => {
        if(res.code==200){
          this.modalService.dismissAll()
                // window.location.reload()
          AlertNotif.finishConfirmWithReload("Mise à Jour Infos parcelle", "Terminer", 'success')

        }else{
          AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
        }
      
    })
  }
  consolidationUnitaire(id_socio) {
    this.inverviewService.consolidationUnitaireSocio(id_socio).subscribe((res: any) => {
      this.modalService.dismissAll()
      if (res.code == 200) {
       // window.location.reload()
        AlertNotif.finishConfirmWithReload("Consolidation unitaire", "Terminer", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }

    })
  }

}
