<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left d-none d-sm-none d-md-block d-lg-block d-xl-block">
            <a href="index.html" class="logo">
                <img src="./assets/img/petcare-logo.png" classs="d-none d-sm-none d-md-block d-lg-block d-xl-block" alt="">
                <br>
            </a>
        </div>



        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Rapports d'erreur</h4>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-right">
                                <li class="breadcrumb-item">INFOCAD</li>
                                <li class="breadcrumb-item">Consolidation</li>
                                <li class="breadcrumb-item active">Rapports d'erreur</li>
                            </ol>
                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row  my-2" *ngIf="show_filter">
                    <div class="col-md-4">


                    </div>
                    <div class="col-md-4 d-flex justify-content-center">
                        <div *ngIf="selected_village!=Vide">
                            <span>Village : {{selected_locality.libelle}}</span>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <!-- minimal widget consist of .widget class. note bg-transparent - it can be any background like bg-gray,
                                        bg-primary, bg-white -->
                        <section class="card bg-transparent">
                            <!-- .widget-body is a mostly semantic class. may be a sibling to .widget>header or .widget>footer -->
                            <div class="card-body">
                                <div id="map" class="mapael">
                                    <div class="row mb-4">
                                      <div class="col-5 col-md-5">
                                        <p>{{ parcelleDisplay }}</p>
                                      </div>
                                      <div class="col-7 col-md-7 d-flex justify-content-end">
                                        <div class="form-inline" >
                                          <div class="form-group">
                                            <button class="btn btn-secondary mr-5">Tous les parcelles</button>
                                          </div>
                                          <div class="form-group">
                                            <label class="col-md-4">Type d'erreur</label>
                                            <select #org="ngModel" (change)="filter($event)" name="org" required ngModel id="" class="form-control col-md-8">
                                              <option value="error-c">Creux</option>
                                              <option value="error-s">Superposition</option>
                                              <option value="error-e">Occurence dans les sauvegarde</option>
                                              <option value="error-d">Doublon dans le fichier</option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>


                                    <div id="chartdiv" (click)="getCoord($event,infos)" style="width: 100%; height: 500px">

                                    </div>
                                    <div class="d-flex justify-content-center align-items-center" *ngIf="nodata" style="width: 100%; height: 200px">
                                        <h3 class="">Votre requête n'affiche aucune carte</h3>
                                    </div>




                                </div>
                            </div>
                        </section>
                    </div>

                </div>

            </div>
            <!-- container-fluid -->
            <ng-template #infos let-modal id="infos">
                <form>
                    <div class="modal-header">
                        <h6 class="modal-title" id="modal-basic-title"> Informations sur la parcelle sélectionnée
                        </h6>
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            X
                        </button>
                    </div>
                    <div class="modal-body">
                        <small *ngIf="current_feature_data.consolidated <= 0" class="text-center">
                            Cette parcelle n'est pas consolidée
                        </small>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-nup">N° provisoire</label>
                                    <input id="modal-bbox-nup" class="form-control" name="nup" [(ngModel)]="current_feature_data.nup"
                                        disabled>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-numtf">N°TF</label>
                                    <input id="modal-bbox-numtf" class="form-control" name="ntf" [(ngModel)]="current_feature_data.nup"
                                        disabled>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-departement">Département</label>
                                    <input id="modal-bbox-departement" name="departement" [(ngModel)]="current_feature_data.districtna"
                                        class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-commune">Commune</label>
                                    <input id="modal-bbox-commune" name="commune" class="form-control" [(ngModel)]="current_feature_data.subcountyn"
                                        disabled>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-arrondissement">Arrondissement</label>
                                    <input id="modal-bbox-arrondissement" name="arrondissement" [(ngModel)]="current_feature_data.parishname"
                                        class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-quartier">Quartier</label>
                                    <input id="modal-bbox-quartier" class="form-control" name="quartier" [(ngModel)]="current_feature_data.villagenam"
                                        disabled>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-ilot">Ilot</label>
                                    <input id="modal-bbox-ilot" class="form-control" disabled>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="form-group">
                                    <label for="modal-bbox-parcelle">Parcelle</label>
                                    <input id="modal-bbox-parcelle" name="parcelle" [(ngModel)]="current_feature_data.parcelleno"
                                        class="form-control" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="form-group">
                                    <label for="modal-bbox-surface">Surface estimée (m²)</label>
                                    <input id="modal-bbox-surface" name="surface" class="form-control" [(ngModel)]="current_feature_data.superficie"
                                        disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button *ngIf="current_feature_data.id_socio!=Null && current_feature_data.id_socio!Zero"
                            (click)="viewExtrait(current_feature_data.parcelleno,current_feature_data.id_socio)" class="btn btn-white border-0 mx-1">Voir
                            Extrait cadastral</button>
                        <button *ngIf="current_feature_data.id_socio!=Null && current_feature_data.id_socio!Zero" class="btn btn-white border-0 mx-1"
                            (click)="viewRecord(current_feature_data.id_socio)">Voir
                            enregistrement socio</button>
                    </div>
                </form>

            </ng-template>

        </div>
        <!-- content -->

        <app-footer>
        </app-footer>

    </div>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->
