import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { InterviewService } from '../core/_services/interview.service';
import { ActivatedRoute } from '@angular/router';
import { Record } from '../core/_models/interview.model';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AlertNotif } from '../alert';
import { UserService } from '../core/_services/user.service';
import { RoleService } from '../core/_services/role.service';
import * as html2pdf from 'html2pdf.js';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';

declare var $: any;

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';


import Map from 'ol/Map';
import View from 'ol/View';
import VectorLayer from 'ol/layer/Vector';
import Circle from 'ol/geom/Circle';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import OSM from 'ol/source/OSM';
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import * as olProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import Point from 'ol/geom/Point';
import XYZ from 'ol/source/XYZ';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Text,
} from 'ol/style';



@Component({
  selector: 'app-extrait-cadastral',
  templateUrl: './extrait-cadastral.component.html',
  styleUrls: ['./extrait-cadastral.component.css']
})
export class ExtraitCadastralComponent implements OnInit {


  data: any={
    NEIGHBORS: "",
    area: 0,
    code_session: "0",
    consolidated: 0,
    countyname: null,
    date: "",
    districtna: null,
    familyname: null,
    firstname1: null,
    firstname2: null,
    id: "",
    id_locality: 7735,
    id_socio: 0,
    id_team: "",
    nup: null,
    nup_provisoir: null,
    parcelleno: "",
    parishname: "",
    subcountyn: "",
    superficie: null,
    villagenam: ""
  }
  right: any
  comments: any[] = []
  _temp: any[] = []
  selected_comment: any
  page = 1
  pageSize = 4
  ref = ""
  size = 0
  content = ""
  collectionSize = 0
  closeResult = ""
  finalized = "finalize"
  current_role: any
  Admin = "ADMIN"
  Responsable = "RESPONSABLE"
  Superviseur = "SUPERVISEUR"
  INVITE = "INVITE"
  GESTIONNAIREDEDATA = "GESTIONNAIREDEDATA"
  ENQUETEURTOPO = "ENQUETEURTOPO"
  users: any[] = []
  loader=false


  map: Map<any>;
  current_feature_data: any
  mapVillageLoading = false
  selected_village = ""
  Vide = ""
  Null = null
  Zero=0

  constructor(private interviewService: InterviewService,
    private modalService: NgbModal,
    public roleService: RoleService,
    private exportAsService: ExportAsService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute) { }


  checkStatus(status) {
    if (status == "pending") {
      return "En attente"
    }
    if (status == "finalize") {
      return "Finalisé"
    }
    if (status == "validated") {
      return "Validé"
    }
    if (status == "rejected") {
      return "Rejeté"
    }
  }
  initMap(geoJson, current_lat, current_long,no) {

    var polygonStyleFunction = new Style({
      stroke: new Stroke({
        color: "red",
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(122, 122, 122, 0.1)',
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'middle',
        font: '11px Verdana',
        // text: feature.get('parcelleno'),
      }),
    })
    var polygonStyleFunction2 = new Style({
      stroke: new Stroke({
        color: "blue",
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(122, 122, 122, 0.1)',
      }),
      text: new Text({
        textAlign: 'center',
        textBaseline: 'middle',
        font: '11px Verdana',
        // text: feature.get('parcelleno'),
      }),
    })
 
    var vectorSource = new VectorSource({
      // features: [marker],
      format: new GeoJSON(),
      loader: function (extent, resolution, projection) {
        vectorSource.addFeatures(
          vectorSource.getFormat().readFeatures(geoJson));
      },
    });
    // vectorSource.removeLoadedExtent(extent);


    var vectorLayer = new VectorLayer({
      source: vectorSource,
      style: function (feature) {
        if(feature.get('parcelleno')==no){
          polygonStyleFunction.getText().setText(feature.get('parcelleno'));
          return polygonStyleFunction;
        }
        polygonStyleFunction2.getText().setText(feature.get('parcelleno'));
        return polygonStyleFunction2;
       
      },
    });

    // View and map
    var view = new View({
      center: [current_lat, current_long],
      zoom: 17.5,
      projection: 'EPSG:32631'

    });

    this.map = new Map({
      target: 'chartdiv',
      layers: [
        new TileLayer({
          source: null
        }), vectorLayer],
      view: view
    })
  }
  geojsonObject: any

  ngOnInit(): void {

    $.getScript('assets/js/jquery.min.js')
    $.getScript('assets/js/bootstrap.bundle.min.js')
    $.getScript('assets/js/metismenu.min.js')
    $.getScript('assets/js/jquery.slimscroll.js')
    $.getScript('assets/js/waves.min.js')
    $.getScript('assets/js/app.js')
    
    if (localStorage.getItem('userRole') != null) {
      this.current_role = localStorage.getItem('userRole')
    }

    this.interviewService.getByLong(this.activatedRoute.snapshot.paramMap.get('ref'))
    .subscribe((res: any) => {
      if(res.code==200){
        this.data =res.data
      }
      if(res.status=="500"){
        AlertNotif.finish("Erreur", "Oups, un problème est survenu", 'success')
      }
    })
    this.interviewService.getParcelleInfoByParcelleNumber(this.activatedRoute.snapshot.paramMap.get('no'))
    .subscribe((res: any) => {
     // this.data=res.data.features[0].properties
      this.interviewService.getParcelleNeighbourInfoByParcelleNumber(this.activatedRoute.snapshot.paramMap.get('no'))
      .subscribe((rest: any) => {
        rest.data.features.push(res.data.features[0])
        this.initMap(rest.data,res.data.features[0].geometry.coordinates[0][0][0], res.data.features[0].geometry.coordinates[0][0][1],res.data.features[0].properties.parcelleno)
      })
   
    })
 
   
   /* this.content = ""
    this.users = []
    this.userService.getAll().subscribe((res: any) => {
      this.users = res.data
    })
    this.ref = this.activatedRoute.snapshot.paramMap.get('id');
    this.data = this.activatedRoute.snapshot.data.details.data
     this.right = this.data.right
    */
  }

  @ViewChild('extraitContent') extraitContent:ElementRef;  
  downloadPdf(){
    this.loader=true
    const options = {
      name: 'extrait.pdf',
      image : {type: 'jpeg'},
      html2canvas: {},
      jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' }
    }

    //const extraitContent: Element =  document.getElementById('extraitContent')
    
     html2pdf()
      .from(this.extraitContent.nativeElement)
      .set(options)
      .save()
      .then(done => {
        this.loader = false;
        console.log(done)
      });
  }
  //@ViewChild('extrait') extrait:ElementRef;  

  generatePDF() {
    var data = document.getElementById('extraitContent');
    html2canvas(data,{
      ignoreElements: function (node) {
        return node.nodeName === 'IFRAME';
      }
    }).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('extrait-cadastral.pdf');
    });
  }


  exportAsConfig: ExportAsConfig = {
    type: 'pdf', // the type you want to download
    elementIdOrContent: 'extraitContent',
    options: { // html-docx-js document options
      orientation: 'landscape',
      margins: {
        top: '20'
      }
    } 
  }
  
  exportPDF() {
    // download the file using old school javascript method
    this.exportAsService.save(this.exportAsConfig, 'extrait').subscribe(() => {
        // save started
    });
    // get the data as base64 or json object for json type - this will be helpful in ionic or SSR
    //this.exportAsService.get(this.config).subscribe(content => {
      //console.log(content);
   // });
  }

}
