<!-- Begin page -->
<div id="wrapper">

    <!-- Top Bar Start -->
    <div class="topbar">

        <!-- LOGO -->
        <div class="topbar-left">
            <a href="index.html" class="logo">
                <img src="./assets/img/official_logo_finance.png" width="220"alt=""> <br>
            </a>
        </div>


        <app-header></app-header>


    </div>
    <!-- Top Bar End -->

    <!-- ========== Left Sidebar Start ========== -->

    <app-aside></app-aside>
    <!-- Left Sidebar End -->

    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div class="content-page">
        <!-- Start content -->
        <div class="content">
            <div class="container-fluid">
                <div class="page-title-box">
                    <div class="row align-items-center">
                        <div class="col-sm-6">
                            <h4 class="page-title">Equipe : {{team.cote}}</h4>
                        </div>
                        <div class="col-sm-6">
                            <p style="text-align: right;">
                                <button (click)="openAddModal(content)" class="btn btn-primary"><i class="fa fa-plus"></i>
                                    Ajouter un membre</button>
                            </p>
                        </div>
                    </div> <!-- end row -->
                </div>
                <!-- end page-title -->
                <div class="row">
                    <div class="col-md-6">
                        <h1 class="page-title"></h1>
                    </div>
                    <div class="col-md-6">


                        <!-- Modal -->
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                            aria-hidden="true">
                            <div class="modal-dialog modal-lg">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel"><b>Nouvel utilisateur</b></h5>
                                    </div>
                                    <div class="modal-body">
                                        <label for="">Periode</label>
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label for="">Nom</label>
                                                    <input type="date" class="form-control" placeholder="Nom">
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <label for="">Prenom</label>
                                                <input type="date" class="form-control">
                                            </div>
                                            <div class="col-md-4">
                                                <label for="">Profil</label>
                                                <select name="" id="" class="form-control">
                                                    <option value="">Administrateur</option>
                                                    <option value="">Superviseur</option>
                                                    <option value="">Agent enqueteur</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                                        <button type="button" class="btn btn-primary">Filtrer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <section class="card shadow-none bg-white">

                            <div class="card-body">
                                <table class="table  table-striped ">
                                    <thead>
                                        <tr>
                                            <th>Nom & Prenoms</th>
                                            <th>Email</th>
                                            <th>Organisation</th>
                                            <th>Profil</th>

                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of data;let index as i">
                                            <td>
                                                {{user.forename}} {{user.surname}}
                                            </td>
                                            <td class="hidden-xs text-semi-muted">
                                                {{user.email}}
                                            </td>
                                        
                                            <td class="hidden-xs">
                                                <span class="label label-success"> {{ user.role==null ? '' :
                                                    user.role}}</span>
                                            </td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Actions</button>
                                                    <div ngbDropdownMenu>
                                                        <button (click)="delete(user.id,i)" ngbDropdownItem>Supprimer</button>

                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="d-flex justify-content-between p-2">
                                    <ngb-pagination class="" [collectionSize]="collectionSize" [(page)]="page"
                                        [maxSize]="5" [pageSize]="pageSize" [rotate]="true" [ellipses]="true"
                                        [boundaryLinks]="true">
                                    </ngb-pagination>

                                    <select class="custom-select custom-select-sm float-right" style="width: auto"
                                        [(ngModel)]="pageSize">
                                        <option selected disabled>Fitre</option>
                                        <option [ngValue]="3">3 éléments par page</option>
                                        <option [ngValue]="5">5 éléments par page</option>
                                        <option [ngValue]="10">10 éléments par page</option>
                                    </select>
                                </div>

                            </div>
                        </section>
                    </div>
                </div>

            </div>
            <!-- container-fluid -->

        </div>
        <!-- content -->

        <app-footer>
       </app-footer>

    </div>
    <ng-template #content let-modal>
        <form #newForm="ngForm" (ngSubmit)="create(newForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Nouvel ajout</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <h5>X</h5>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="form-group">
                    <label for="">Désignation</label>
                    <input type="text" class="form-control" ngModel name="designation">
                </div>
                <div class="form-group">
                    <ng-multiselect-dropdown
                        [placeholder]="'custom placeholder'"
                        [data]="users"
                        name="user"
                        [(ngModel)]="selectedItems"
                        [settings]="dropdownSettings"
                        (onSelect)="onItemSelect($event)"
                        (onSelectAll)="onSelectAll($event)"
                    >
                    </ng-multiselect-dropdown>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!newForm.form.valid" type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <ng-template #contentU let-modal>
        <form #updateForm="ngForm" (ngSubmit)="update(updateForm.value)">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Nouvel modification</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <i class="fa fa-remove font-size-h3"></i>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="error" class="alert alert-danger my-1 alert-rounded">
                    {{error}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="form-group">
                    <label for="">Désignation</label>
                    <input type="text" class="form-control" [(ngModel)]="data_current.designation" name="designation">
                </div>

                <div class="form-group">
                    <label for="">Cote</label>
                    <input type="text" name="cote" [(ngModel)]="data_current.cote" class="form-control">
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" [disabled]="!updateForm.form.valid" type="submit">Sauvegarder</button>
            </div>
        </form>
    </ng-template>
    <!-- ============================================================== -->
    <!-- End Right content here -->
    <!-- ============================================================== -->

</div>
<!-- END wrapper -->