export class Team {
    id: number;
    designation: string;
    cote: string;
    members:any[]
    localities:any[]
   
    

    constructor(attrs: any = null) {
        if (attrs) {
          this.build(attrs);
        }
      }

      build(attrs: any): void {
        this.id = attrs.id;
        this.cote = attrs.cote;
        this.designation = attrs.designation;
        this.members = attrs.members;
        this.localities = attrs.localities;
      }
    toJson() {
        return {
            id: this.id,
            cote: this.cote,
            designation: this.designation,
            members:this.members,
            localities:this.localities

          };
    }

}
