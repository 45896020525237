import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../core/_services/auth/auth.service';
import { LocalService } from './../core/_services/browser-storages/local.service';
import { AlertNotif } from './../alert';
declare var $: any; 
@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {



	error = ""
	returnUrl = ""
	constructor(private localStorageService: LocalService, private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			this.returnUrl = params.returnUrl || '/dashboard';
		});

		$(".toggle-password").click(function() {

			$(this).toggleClass("fa-eye fa-eye-slash text-success");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
			  input.attr("type", "text");
			} else {
				input.attr("type", "password");
			  }
			});
	}


	loading = false

	submit(value) {
		this.loading = true;
		this.authService
			.signIn(value.email, value.password)
			.subscribe((res: any) => {
				console.log(res)
				this.loading = false;
				if (res.code == 200) {
					localStorage.setItem('userToken', res.data.token);
					localStorage.setItem('userRole', res.data.user.role);
					this.localStorageService.setJsonValue('userData', res.data.user)
					this.router.navigateByUrl("/dashboard");
				}
				if (res.code == 404 && res.msg == "NOT_FOUND_USER") {
					this.error = "Email ou mot de passe incorrect"
				}
				if (res.code == 401 && res.msg == "NOT_AUTHENTICATE") {
					this.error = "Email ou mot de passe incorrect"
				}

			}, err => {
				console.log(err)
				this.loading = false;
				if (err.error.non_field_errors != null) {
					this.error = err.error.non_field_errors[0]
				}

			});
	}



	notifyUser() {
		AlertNotif.finish("Reinitialisation du mot de passe", "Veuillez contacter votre administrateur pour la reinitialisation de votre mot de passe.", 'error');
	}


	

}
