import { Component, OnInit } from '@angular/core';
import { User } from '../core/_models/user.model';
import { UserService } from '../core/_services/user.service';
import { AlertNotif } from '../alert';
import { LocalService } from '../core/_services/browser-storages/local.service';
import { ActivatedRoute } from '@angular/router';
declare var $: any; 

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {

  data=new User()
  current_user:any
  constructor(private userService:UserService,private localService:LocalService, private activatedRoute:ActivatedRoute) { }

  org_name=""
  ngOnInit(): void {

    this.current_user=this.localService.getJsonValue('userData')
    this.data=this.activatedRoute.snapshot.data.user_one.data

    
    this.org_name =  this.data.organisation==null ? 'ANDF' :  this.data.organisation.designation
    

    if(typeof this.data.organisation != "undefined" && this.data.organisation !== null && typeof this.data.organisation.designation != "undefined" && this.data.organisation.designation !== null) {
      this.org_name = this.data.organisation.designation;
      console.log("In profil Interface")
    }else{
      this.org_name = this.data.role === "ADMIN" ? "Systeme" : "ANDF";
    }



		$(".toggle-password").click(function() {

			$(this).toggleClass("fa-eye fa-eye-slash text-success");
			var input = $($(this).attr("toggle"));
			if (input.attr("type") == "password") {
			  input.attr("type", "text");
			} else {
				input.attr("type", "password");
			  }
			});
  }

  update(value){
    value.validate=this.data.validate
    value.enabled=this.data.enabled
   // delete value.email
    delete value.org
    
    if(value.password=="" || value.password==null){
      delete value.password
    }
    this.userService.update(value,this.data.id).subscribe((res:any)=>{
      if(res.code==200){
       this.ngOnInit() 
        AlertNotif.finish("Mise à jour profil", "Mise à jour éffectué avec succès", 'success')
      }else{
        AlertNotif.finish("Erreur", "Une erreur est survenue, veuillez contacter l'administrateur SVP", 'error')
      }
    },(err)=>{
      
      if(err.error.detail!=null){    
        AlertNotif.finish("Mise à jour profil", err.error.detail, 'error')
      }else{
        AlertNotif.finish("Mise à jour profil", "Une erreur est survenue, verifier votre connexion SVP", 'error')
      }
    })
  }

} 
